import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  isOpen: false,
  id: '', // only for update
  description: '',
  solutionTitle: '',
  solutionDescription: '',
  servicecaseId: ''
};

export default handleActions(
  {
    [types.OPEN_MODAL]: (state, { payload }) => {
      // create
      if (!payload) return { ...state, isOpen: true };

      // update
      const {
        id,
        description,
        solutionTitle,
        solutionDescription,
        servicecaseId
      } = payload;
      return {
        ...state,
        isOpen: true,
        id: id || '',
        description: description || '',
        solutionTitle: solutionTitle || '',
        solutionDescription: solutionDescription || '',
        servicecaseId: servicecaseId || ''
      };
    },
    [types.CLOSE_MODAL]: (state, { payload }) => {
      return initialState;
    },
    [types.EDIT_INCIDENT]: (state, { payload }) => {
      const { scope, value } = payload;

      return { ...state, [scope]: value };
    },
    [types.CREATE_REQUESTED]: (state, { payload }) => {
      const { itemName } = payload;
      if (itemName === 'Incidents') {
        return initialState;
      }
      return state;
    }
  },
  initialState
);
