import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RadarChart from '..';
import { selectData } from '../../../../../selectors/TileDataSelector';

const mapStateToProps = (state, ownProps) => {
  let data = selectData(state, ownProps);
  let performance = data[0];
  if (data[0] >= 100) {
    performance = 100;
  }
  data = [
    { name: 'Instandhaltung', value: 50 },
    { name: 'Rüstzeit', value: 90 },
    { name: 'Fehlervarianz', value: 80 },
    { name: 'Verschleiß', value: 85 },
    { name: 'Leistung', value: performance }
  ];
  return {
    data
  };
};

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RadarChart)
);
