import * as React from 'react';
import PrivateRoute from '../../../components/PrivateRoute';
import { Dashboard } from '../globalTypes/dashboard';
import Scenes from '../../../scenes';

export type Scene = {
  displayName: string;
  name: string;
  tabs: Tab[];
};

export type Tab = {
  title: string;
  id: string;
};

/**
 * Returns one tab for each asset which is configured in the dashboard
 */
const getAssetTabs = (dashboard): Tab[] => {
  let assetTabs = [];

  dashboard.assets.forEach(
    ({ name, assetId }) =>
      (assetTabs = [...assetTabs, { title: name, id: assetId }])
  );

  if (assetTabs.length === 0) {
    // return empty array to prevent redirect to "all" tab before other tabs are loaded
    // !at least one asset is required in the config
    return [];
  }

  return [{ id: 'all', title: 'All' }, ...assetTabs];
};

/**
 * Returns a tab for a specific dashboard
 */
const getDashboardTab = ({ id, title }): Tab => ({ id, title });

/**
 * Returns all scenes with tabs provided in the config
 */
const getScenes = (config: Dashboard[]): Scene[] => {
  const scenes: Scene[] = [];

  config.forEach((dashboard: Dashboard) => {
    if (dashboard.id === 'settings') return;

    const duplicateScene = scenes.some(scene => scene.name === dashboard.scene);
    const newTabs = dashboard.assets
      ? getAssetTabs(dashboard)
      : [getDashboardTab(dashboard)];

    if (!duplicateScene) {
      scenes.push({
        displayName: dashboard.sceneDisplayName,
        name: dashboard.scene,
        tabs: newTabs
      });
    } else {
      let scene = scenes.find(scene => scene.name === dashboard.scene);
      scene.tabs = [...scene.tabs, ...newTabs];
    }
  });

  return scenes;
};

/**
 * Returns all scenes with tabs provided in the config
 */
export default config => {
  const layout = getScenes(config);
  const scenes = layout.map(({ name }) => {
    const Scene = Scenes[name];
    return (
      <PrivateRoute
        exact
        path={`/${name}/:tabId?`}
        key={`route.${name}`}
        component={() => <Scene layout={layout} key={`scene.${name}`} />}
      />
    );
  });

  return [
    ...scenes,
    <PrivateRoute
      key={'route.notFound'}
      component={() => (
        <Scenes.notFound key={'scene.notFound'} layout={layout} />
      )}
    />
  ];
};
