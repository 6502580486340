import * as React from 'react';
import styled from 'styled-components';

const TitleDiv = styled.div`
  margin-bottom: 30px;

  @media (max-width: 991.92px) {
    margin-bottom: 0px;
  }
`;

const Title = styled.h5`
  color: ${props => props.theme.conterMain};
  font-family: Montserrat Bold;
  margin-bottom: 0px;

  @media (max-width: 991.92px) {
    display: none;
  }
`;

const BreadcrumRow = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 991.92px) {
    padding: 0px 20px;
    margin-bottom: 20px;
  }
`;

const BreadcrumDiv = styled.div`
  cursor: pointer;
  margin-bottom: 0px;
`;

const BreadcrumText = styled.p`
  color: ${props => props.theme.main};
  font-size: 14px;
  margin-bottom: 0px;
`;

const Breadcrum = ({ text, onClick }) => (
  <BreadcrumDiv onClick={onClick}>
    <BreadcrumText>{text}</BreadcrumText>
  </BreadcrumDiv>
);

const createBreadcrums = (breadcrums = []) =>
  breadcrums.map(({ text = '', onClick = () => {} }, idx) => (
    <Breadcrum key={text + idx} text={text} onClick={onClick} />
  ));

/**
 * The page title
 * @param {string} props.title The Title-text
 */
export default ({ breadcrums, title }) => (
  <TitleDiv>
    <Title className="scene-title">{title}</Title>
    <BreadcrumRow>{breadcrums && createBreadcrums(breadcrums)}</BreadcrumRow>
  </TitleDiv>
);
