export const GET_CONFIG_REQUESTED = 'GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCEEDED = 'GET_CONFIG_SUCCEEDED';
export const GET_CONFIG_FAILED = 'GET_CONFIG_FAILED';

export const CYCLES_5_SEC = 'CYCLES_5_SEC';
export const CYCLES_20_SEC = 'CYCLES_20_SEC';

export default {
  GET_CONFIG_REQUESTED,
  GET_CONFIG_SUCCEEDED,
  GET_CONFIG_FAILED,
  CYCLES_5_SEC,
  CYCLES_20_SEC
};
