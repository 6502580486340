//libraries

import * as React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/containers/LayoutContainer';

const Message = styled.div`
  width: 100%;
  height: 500px;

  color: #7f8c8d;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const NotFound = props => {
  return (
    <Layout name={'Not Found'} noRedirect layout={props.layout} {...props}>
      <Message>
        <h1>Page not found</h1>
        <h5>The page you requested was not found</h5>
        <Link to="/status">Back</Link>
      </Message>
    </Layout>
  );
};

export default withRouter(NotFound);
