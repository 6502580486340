import {
  EventsPagination,
  EventsFilter
} from '../../services/core/dataSources/sources/eventsMSP/types';

/**
 * Returns all events as array
 * @param  {Object} state redux state
 * @param  {string} assetId the assetId of the events
 * @returns  {Array.<Asset>} An array with all events
 */
export const getEvents = (state, assetId) => {
  try {
    if (!state.eventsMSP.events.data) return [];
    return assetId === 'all'
      ? state.eventsMSP.events.data
      : state.eventsMSP.events.data.filter(
          ({ entityId }) => assetId === entityId
        );
  } catch (e) {
    return [];
  }
};

/**
 * Returns true if events of a specific asset are currently loading
 * @param  {Object} state redux state
 * @param  {string} assetId the assetId of the events
 * @returns  {boolean} events are loading or not
 */
export const getEventsLoading = state => {
  try {
    return state.eventsMSP.events.reqRunning;
  } catch (e) {
    return true;
  }
};

/**
 * Returns the current events filter
 * @param  {Object} state redux state
 * @returns  {EventsFilter} filter
 */
export const getEventsFilter = (state): EventsFilter => {
  try {
    return state.eventsMSP.events.filter;
  } catch (e) {
    return undefined;
  }
};

/**
 * Returns the current events pagination
 * @param  {Object} state redux state
 * @returns  {EventsPagination} pagination
 */
export const getEventsPagination = (state): EventsPagination => {
  try {
    return state.eventsMSP.events.pagination;
  } catch (e) {
    return undefined;
  }
};
