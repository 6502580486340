
import * as React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faExpandAlt);

const IconDiv = styled.div`
  position: absolute;
  height: 25px;
  line-height: 25px;
  top: 30px;
  right: 30px;
  font-size: 20px;
  color: #b1b9ab;
  svg {
    cursor: pointer;
  }
`;

export default ({ onClick = () => {} }) => (
  <IconDiv onClick={onClick}>
    <FontAwesomeIcon icon={['far', 'expand-alt']} />
  </IconDiv>
);
