import moment from 'moment';

export type LineChartDataPoint = {
  time: string;
  value: number;
};
export type LineChartMarkArea = {};
export type LineChartSeries = LineChartDataPoint[] | LineChartMarkArea[];
export type LineChartData = LineChartSeries[];

export type DataPoint = {
  properties: {
    displayName: string;
    unit: string;
    displayType: string;
  };
};

const defaultOptions = theme => ({
  animationDuration: 0,
  grid: {
    left: 40,
    right: 40,
    top: 30,
    bottom: 50
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: '#FFFFFF',
    textStyle: {
      color: '#4c555b'
    },
    formatter: function (params) {
      const { data = {} } = params[0];
      return `${moment(data.time).format('HH:mm DD.MM.YYYY')}: ${data.value}`;
    },
    axisPointer: {
      animation: false
    },
    extraCssText:
      'box-shadow: 0 0 3px rgba(0, 0, 1.0, 0.3); border-radius:5; padding-left: 10px; padding-right: 10px;'
  },
  title: {
    show: true,
    textStyle: {
      color: '#7F8F9D',
      fontFamily: 'Montserrat Light',
      fontSize: 15
    },
    text: 'Keine Daten vorhanden',
    left: 'center',
    top: 'center'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],

    axisLine: {
      lineStyle: {
        color: theme.conterLight
      }
    }
  },
  yAxis: [
    {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: theme.conterLight
        }
      }
    }
  ],
  series: []
});

export default (data: LineChartData, dataPoints: DataPoint[], theme) => {
  if (
    !data ||
    data.includes(undefined) ||
    data.length === 0 ||
    (data[0] && data[0].length === 0)
  ) {
    return defaultOptions(theme);
  }

  const labels = dataPoints.map(dp => dp.properties.displayName);
  const units = dataPoints.map(dp => dp.properties.unit);

  const indexLineSeries = dataPoints.findIndex(
    dp => !dp.properties.displayType || dp.properties.displayType === 'line'
  );
  const indexMarkArea = dataPoints.findIndex(
    dp => !dp.properties.displayType || dp.properties.displayType === 'markArea'
  );

  if (indexLineSeries === -1) return defaultOptions(theme);

  //@ts-ignore
  const timestamps = data[indexLineSeries].map(entry => entry.time);
  //@ts-ignore
  const values = data[indexLineSeries].map(entry => entry.value);

  const series = [
    {
      name: labels[indexLineSeries] || '-',
      data: data[indexLineSeries],
      type: 'line',
      markArea: {
        label: {
          color: theme.conterLight
        },
        data: []
      }
    }
  ];

  if (indexMarkArea !== -1) {
    series.push({
      name: '',
      data: [],
      type: 'line',
      markArea: {
        label: {
          color: theme.conterLight
        },
        data: data[indexMarkArea]
      }
    });
  }

  const min = Math.min(...values);
  const max = Math.max(...values);

  return {
    animationDuration: 0,
    title: { text: '' },
    color: theme.colorPalette,
    legend: {
      type: 'plain',
      data: labels,
      bottom: 0
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#FFFFFF',
      textStyle: {
        color: '#4c555b'
      },
      formatter: function (params) {
        const { data = {} } = params[0];
        return `${moment(data.time).format('DD.MM.YYYY HH:mm')} ${Math.round(
          data.value * 100
        ) / 100}${units[0]}`;
      },
      axisPointer: {
        animation: false
      },
      extraCssText:
        'box-shadow: 0 0 3px rgba(0, 0, 1.0, 0.3); border-radius:5; padding-left: 10px; padding-right: 10px;'
    },
    grid: {
      left: max.toString().length >= 2 ? 60 : 40,
      right: max.toString().length >= 2 ? 60 : 40,
      top: 30,
      bottom: 50
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: timestamps,
      axisLabel: {
        formatter: value => {
          const DAY_ENMUN = {
            Mon: "Mo",
            Tue: "Di",
            Wed: "Mi",
            Thu: "Do",
            Fri: "Fr",
            Sat: "Sa",
            Sun: "So"
          }
          const day = moment(value).format('ddd')
          const time = moment(value).format('HH:mm:ss')

          return `${DAY_ENMUN[day]} ${time}`;
        }
      },
      axisLine: {
        lineStyle: {
          color: theme.conterLight
        }
      }
    },
    yAxis: [
      {
        type: 'value',
        min,
        max,

        axisLabel: {
          formatter: value =>
            `${(Math.round(value * 10) / 10).toFixed(1)}${units[0]}`
        },
        axisLine: {
          lineStyle: {
            color: theme.conterLight
          }
        }
      }
    ],
    series
  };
};
