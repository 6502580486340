import ConfigAPI from '../api/dashboards/index';
import { selectDashboard } from '../../../selectors/Dashboards';
import { Dashboard } from '../globalTypes/dashboard';

/**
 * Processes dashboard configuration and handles the data query
 *
 * @export
 * @class DashboardManager
 */
export default class DashboardManager {
  api: ConfigAPI;
  constructor() {
    this.api = new ConfigAPI();
  }

  async getDashboards() {
    const dashboards: Array<Dashboard> = await this.api.getDashboards();

    return dashboards;
  }

  getDashboard(dashboards, tab, scene: string) {
    const dashboard: Dashboard = selectDashboard(dashboards, tab, scene);
    return dashboard;
  }
}
