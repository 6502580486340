import { connect } from 'react-redux';

import Chart from '..';
import { changeChartDataZoom } from '../actions';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  onDataZoom: (startValue, endValue) =>
    dispatch(changeChartDataZoom({ startValue, endValue }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
