import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Chart from '..';
import { showDetailedChartViewFor } from '../../../../Chart/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  showDetailedChartView: id => dispatch(showDetailedChartViewFor(id))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chart));
