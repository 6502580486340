import * as React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 0px 20px;

  div {
    padding: 0px 10px;

    p {
      font-family: Montserrat Bold;
      margin-bottom: 0px;
    }
  }

  @media (max-width: 991.92px) {
    display: none;
  }
`;

export default ({ children }) => <Header>{children}</Header>;
