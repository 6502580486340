import { connect } from 'react-redux';

import MobileMenu from '../../../../components/MobileMenu';
import { closeMenu, selectMenuItem } from '../../../../actions';

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.layout.mobileMenu.isOpen,
  selectedMenuItem: state.layout.selectedMenuItem,
  currentTabId: ownProps.match.params.tabId
});

const mapDispatchToProps = dispatch => ({
  closeMenu: () => dispatch(closeMenu()),
  selectMenuItem: item => dispatch(selectMenuItem(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileMenu);
