import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';

const NewButton = styled(Button)`
  border-color: ${props => props.theme.main};
  border-style: solid;
  border-width: 1px;
  background-color: ${props => props.theme.main};
  color: #ffffff;
  margin-bottom: -10px;

  &&:first-child {
    margin-left: auto;
  }

  &:hover {
    color: ${props => props.theme.main};
    background-color: ${props => props.theme.mainLight};

    border-color: ${props => props.theme.main};
  }
`;

export default ({ onClick }) => (
  <NewButton onClick={onClick}>Neuer Typ</NewButton>
);
