import Source from '../source';

//global types
import { SourceState, EventsResponse, EventData } from './types';
import { DashboardProperties, Action } from '../../../globalTypes/dashboard';
import { GeneralRequestParameter } from '../sourceTypes';
import { EventsFilter } from './types';

import { getEventsFilter } from '../../../../../selectors/eventsMSP';
import mindConnector from '../../../api/mindsphere';

type GlobalState = {
  core: {
    cycle: {
      tab: string;
    };
  };
};

class EventsMSP extends Source {
  getId(dashboardProps: DashboardProperties) {
    return `events`;
  }

  getRequests(
    sourceState: SourceState,
    dashboardProps: Array<DashboardProperties>
  ): Array<GeneralRequestParameter> {
    if (!sourceState.events) {
      const initialFilter: EventsFilter = {
        severity: 'all',
        acknowledged: false
      };

      return [
        {
          reqRunning: false,
          firstLoad: true,
          from: null,
          to: null,
          filter: initialFilter,
          ...dashboardProps[0]
        }
      ];
    } else {
      const { reqRunning, from, to, filter } = sourceState.events;
      return [
        { reqRunning, firstLoad: false, from, to, filter, ...dashboardProps[0] }
      ];
    }
  }

  async callMethodeForSaga(
    requestParams: GeneralRequestParameter,
    state: GlobalState
  ): Promise<EventData> {
    const { to, page, asset } = requestParams;

    let filter: EventsFilter = requestParams.filter || getEventsFilter(state);

    filter = {
      ...filter,
      entityId: asset
    };

    if (to) {
      filter = {
        ...filter,
        timestamp: {
          after: to
        }
      };
    }

    let validatedFilter: EventsFilter = {};
    Object.keys(filter).forEach((key: string) => {
      if (filter[key] !== 'all') {
        validatedFilter = {
          ...validatedFilter,
          [key]: filter[key]
        };
      }
    });

    const eventData: EventsResponse = await mindConnector.getEvents(
      validatedFilter,
      page
    );

    return {
      data: {
        data: eventData._embedded.events,
        pagination: eventData.page
      }
    };
  }

  //@ts-ignore
  getRequestedReducer(state: SourceState, action: Action): SourceState {
    const requests: Array<GeneralRequestParameter> = action.payload;
    let newState = state;

    requests.forEach((request: GeneralRequestParameter) => {
      const { firstLoad, filter } = request;
      newState = {
        ...state,
        events: {
          ...newState.events,
          data: Object.keys(state).length === 0 ? [] : state.events.data,
          reqRunning: true,
          firstLoad,
          filter: filter || newState.events.filter
        }
      };
    });

    return {
      ...newState
    };
  }

  //@ts-ignore
  getSucceededReducer(state: SourceState, action: Action): SourceState {
    const { data, pagination } = action.payload.data;
    let { from, to } = state.events;

    const sortedData = data.sort((itemA, itemB) => {
      if (itemA.timestamp > itemB.timestamp) return -1;
      if (itemA.timestamp > itemB.timestamp) return 1;

      return 0;
    });

    let firstEventTimestamp = null;
    let lastEventTimestamp = null;
    const length = sortedData.length;
    if (length > 0) {
      firstEventTimestamp = sortedData[0].timestamp;
      lastEventTimestamp = sortedData[sortedData.length - 1].timestamp;
    }

    if (
      firstEventTimestamp !== null &&
      from !== null && firstEventTimestamp < from
    ) {
      from = firstEventTimestamp;
    }

    if (lastEventTimestamp !== null && to !== null && lastEventTimestamp > to) {
      to = lastEventTimestamp;
    }

    return {
      ...state,
      events: {
        ...state.events,
        reqRunning: false,
        firstLoad: false,
        from,
        to,
        data: sortedData,
        pagination
      }
    };
  }

  async updateMethodeForSaga(action: GeneralRequestParameter) {
    const { payload } = action;
    const { data } = payload;

    const requests = data.map(id => {
      return mindConnector.updateEvent(id, true);
    });

    await Promise.all(requests);

    return;
  }

  updateSucceededReducer(state: SourceState, action: Action): SourceState {
    return state;
  }

  updateFailedReducer(state: SourceState, action: Action): SourceState {
    return state;
  }
}

const eventsMSP = new EventsMSP();
export default eventsMSP;
