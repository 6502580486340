/**
 * Change chart data zoom
 *
 * @param  {redux state} state redux state
 * @param  {string || null} payload action payload from CHANGE_CHART_DATAZOOM
 */
export default (state, { payload }) => ({
  ...state,
  startValue: payload.startValue,
  endValue: payload.endValue
});
