import * as React from 'react';
import 'echarts/lib/component/markArea';
import getOptions from './services/getChartOption';
import { withTheme } from 'styled-components';
import Chart from '../../../Chart/container';

class LineChartElement extends React.Component<any, any> {
  render() {
    const { data, config, theme, overrideOption, height } = this.props;

    let options = getOptions(data, config.data, theme);
    options = overrideOption ? overrideOption(options) : options;

    return (
      //@ts-ignore
      <Chart
        option={options}
        style={{ height: height ? height : '400px', width: '100%' }}
      />
    );
  }
}

export default withTheme(LineChartElement);
