import * as React from 'react';
import styled from 'styled-components';
import getOptions from './services/getChartOption';
import ReactEcharts from 'echarts-for-react';
import { withTheme } from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: row;

  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0px;
  padding-top: 0px;
  pointer-events: none;

  .layerStateChart {
    height: '130px';
    width: '100%';
  }
`;
class BarChartComponent extends React.Component<any, any> {
  render() {
    const { dataSeries, dataSetPoint, theme } = this.props;

    let options = getOptions(dataSeries, dataSetPoint, theme);

    return (
      //@ts-ignore
      <Content>
        <ReactEcharts
          option={options}
          style={{ height: '200px', width: '100%', bottom: '10px' }}
        />
      </Content>
    );
  }
}

export default withTheme(BarChartComponent);
