import * as React from 'react';
import styled from 'styled-components';

const Img = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;

  padding: 15px;
`;

const ImgContainer = styled.div`
  height 100%;
  display:flex;
  justify-content: center;
  align-items:center;
`;

export default ({ src, alt }) => (
  <ImgContainer>
    <Img src={src} alt={alt} />
  </ImgContainer>
);
