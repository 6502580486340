import * as React from 'react';
import styled from 'styled-components';

import Text from '../Text';

const LabeledDot = styled.div<any>`
  display: flex;
  flex-direction: row;
  height: 24px;
  ${({ size }) =>
    `
    padding-top: calc((24px - ${size}) / 2 - 1px);
  `}
`;

const Dot = styled.div<any>`
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 100%;

  ${({ size }) =>
    `
    height: ${size};
    width: ${size}; 
  `}

  ${({ color }) =>
    `
    background-color: ${color};
  `}
`;

export default ({ color = '#fb785d', textStyle, children, size = '15px' }) => (
  <LabeledDot size={size}>
    <Dot color={color} size={size} />
    {children && <Text textStyle={textStyle}>{children}</Text>}
  </LabeledDot>
);
