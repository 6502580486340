import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectDashboard } from '../../../selectors/Dashboards';

import StatusPage from '../';

const mapStateToProps = (state, ownProps) => {
  const dashboards = state.config;
  const { tabId } = ownProps.match.params;

  return {
    dashboards,
    dashboard: selectDashboard(dashboards, tabId, 'status')
  };
};

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StatusPage)
);
