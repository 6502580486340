import {
  CREATE_SUCCEEDED,
  CREATE_REQUESTED
} from '../../../../services/core/reducerFactory/types';

export default {
  OPEN_MODAL: 'OPEN_INCIDENT_MODAL',
  CLOSE_MODAL: 'CLOSE_INCIDENT_MODAL',
  EDIT_INCIDENT: 'EDIT_INCIDENT',
  CREATE_REQUESTED: CREATE_REQUESTED.replace('*', 'csServices'),
  CREATE_SUCCEEDED: CREATE_SUCCEEDED.replace('*', 'csServices')
};
