/**
 * Select dashboard by id
 *
 * @param {array} dashboards
 * @param {int} dashboardId
 * @returns {object} selected dashboard
 */
export const selectDashboard = (dashboards, dashboardId, scene) => {
  if (typeof dashboardId === 'undefined') {
    return dashboards.filter(dashboard => dashboard.scene === scene)[0];
  }

  if (scene === 'events' || scene === 'servicelog') {
    return dashboards.find(dashboard => dashboard.id === scene);
  }

  return dashboards.find(
    dashboard =>
      (dashboard.id === dashboardId || 'all' === dashboardId) &&
      dashboard.scene === scene
  );
};

export const getDetailChartTile = state => {
  try {
    const id = state.chart.showDetailedChartViewFor;
    if (id === null) return;

    const dashboardId = state.core.cycle.tab;
    const dashboard = state.config.find(
      dashbaord => dashbaord.id === dashboardId
    );
    const tile = dashboard.tiles.find(tile => tile.id === id);
    return tile;
  } catch (e) {
    return;
  }
};
