import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = 'log';

export default handleActions(
  {
    [types.DISPLAY_SCENE]: (state, { payload }) => {
      return payload;
    }
  },
  initialState
);
