import * as React from 'react';
import styled from 'styled-components';

const Indicator = styled.div`
  width: 100%;
  height: 300px;
  padding-top:150px;
  background-color: ${props => props.theme.background};
  display: flex;
  justify-content: center;
  overflow: auto
  align-items: center;
`;

const DotLoader = styled.div`
  @-webkit-keyframes grow {
    0%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes grow {
    0%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${props => props.theme.main};
  position: relative;
`;

const DotLoaderDot1 = styled(DotLoader)`
  -webkit-animation: 1.2s grow ease-in-out infinite 0.2s;
  animation: 1.2s grow ease-in-out infinite 0.2s;
`;

const DotLoaderDot2 = styled(DotLoader)`
  -webkit-animation: 1.2s grow ease-in-out infinite 0.35555s;
  animation: 1.2s grow ease-in-out infinite 0.35555s;
  margin: 0 20px 0 20px;
`;

const DotLoaderDot3 = styled(DotLoader)`
  -webkit-animation: 1.2s grow ease-in-out infinite 0.5s;
  animation: 1.2s grow ease-in-out infinite 0.5s;
`;

const PageLoadingIndicator = () => {
  return (
    <Indicator>
      <DotLoaderDot1 />
      <DotLoaderDot2 />
      <DotLoaderDot3 />
    </Indicator>
  );
};

export default PageLoadingIndicator;
