import Source from '../source';
import { FormattedData } from './types';

//global types
import { GeneralRequestParameter } from '../sourceTypes';
import { DashboardProperties, Action } from '../../../globalTypes/dashboard';

import mindConnector from '../../../api/mindsphere';
import { isUndefined } from 'lodash';

type SourceState = {
  [id: string]: SourceStateConfig;
};

type SourceStateConfig = {
  data: any;
  firstLoad: boolean;
  reqRunning: boolean;
};

class AssetsMSP extends Source {
  /**
   * Build an id to access from the global state to the data
   *
   * @param {DashboardProperties} dashboardProps
   * @returns {string}
   * @memberof AggregateMSP
   */
  getId(dashboardProps: DashboardProperties): string {
    const asset: string = `${dashboardProps.asset}.${dashboardProps.aspect}.${dashboardProps.property}`;
    return asset;
  }

  getRequests(
    sourceState: SourceState,
    dashboardProps: Array<DashboardProperties>
  ): Array<GeneralRequestParameter> {
    let requests: Array<GeneralRequestParameter> = [];
    if (Object.keys(sourceState).length === 0) {
      requests = this._buildRequests(dashboardProps, undefined);
    } else {
      requests = this._buildRequests(dashboardProps, sourceState);
    }

    const combinedRequests: Array<
      GeneralRequestParameter
    > = this._combineRequests(requests);

    return combinedRequests;
  }

  async callMethodeForSaga(
    requestParams: GeneralRequestParameter
  ): Promise<FormattedData> {
    const data = await mindConnector.getAssets(requestParams.asset);
    const formattedData: FormattedData = this._formatData(requestParams, data);

    return formattedData;
  }

  //@ts-ignore
  getRequestedReducer(state: SourceState, action: Action): SourceState {
    const requests: Array<GeneralRequestParameter> = action.payload;

    let newState: SourceState = { ...state };

    // If state is empty -> fill in initial structure
    if (Object.keys(newState).length === 0) {
      requests.forEach(request => {
        //@ts-ignore
        newState = { ...newState, ...this._formatData(request, {}) };
      });

      return newState;
    }

    requests.forEach((request: GeneralRequestParameter) => {
      request.ids.forEach(id => {
        newState[id] = {
          ...newState[id],
          reqRunning: true
        };
      });
    });

    return newState;
  }

  //@ts-ignore
  getSucceededReducer(state: SourceState, action: Action) {
    return {
      ...state,
      ...action.payload
    };
  }

  _formatData(requestParams: GeneralRequestParameter, data): FormattedData {
    const selectedData: any = this._selectCustomData(requestParams, data);

    let formattedRequest = {
      [requestParams.ids[0]]: {
        data: [{ value: selectedData }],
        firstLoad: false,
        reqRunning: false
      }
    };

    return formattedRequest;
  }
  _selectCustomData(requestParams, data) {
    let selectedData;
    if (Object.keys(data).length === 0) return;

    const staticData: Array<{ name: string; value: string }> =
      data.aspects[0].variables;

    staticData.forEach(dp => {
      if (dp.name === requestParams.property) {
        selectedData = dp.value;
      }
    });
    return selectedData;
  }

  _buildRequests(props: Array<DashboardProperties>, sourceState: SourceState) {
    let requests: Array<GeneralRequestParameter> = [];
    props.forEach(prop => {
      let id = `${prop.asset}.${prop.aspect}.${prop.property}`;
      let request: GeneralRequestParameter = {
        ids: [id],
        reqRunning: sourceState ? sourceState[id].reqRunning : false,
        firstLoad: sourceState ? sourceState[id].firstLoad : true,
        ...prop
      };
      requests.push(request);
    });

    return requests;
  }

  _combineRequests(
    requests: Array<GeneralRequestParameter>
  ): Array<GeneralRequestParameter> {
    let combinedRequests: Array<GeneralRequestParameter> = [];
    requests.forEach(request => {
      let found: GeneralRequestParameter | undefined = combinedRequests.find(
        combinedRequest => combinedRequest.ids[0] === request.ids[0]
      );

      if (isUndefined(found)) {
        combinedRequests.push(request);
      }
    });
    return combinedRequests;
  }
}

const assetsMSP = new AssetsMSP();
export default assetsMSP;
