import * as React from 'react';
import styled, { withTheme } from 'styled-components';

import { Responsive, WidthProvider } from 'react-grid-layout';
import { withRouter } from 'react-router-dom';

import '../../../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../../../node_modules/react-resizable/css/styles.css';
// import './style.css';

import TileBuilder from '../../../../../services/core/tileBuilder';

const ResponsiveGridLayout = WidthProvider(Responsive);

const LayoutDiv = styled.div<any>`
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: ${props => (props.show ? '1' : '0')};
`;

const Container = styled.div<any>`
  .react-grid-item.react-grid-placeholder {
    background-color: ${props => props.theme.main};
    border-radius: 5px;
  }
`;

const ContainerDiv = styled.div`
  touch-action: none;
  //background-color: #c0392b;

  width: 1700px;
  min-width: 300px;

  align-items: center;
  justify-content: center;

  @media (max-width: 1840px) {
    width: 1120px;

    //background-color: #d35400;
  }
  @media (max-width: 1300px) {
    width: 540px;

    //background-color: #f1c40f;
  }
  @media (max-width: 600px) {
    width: 300px;

    //background-color: #3498db;
  }
`;

let Tiles = [];

/** Page Content */
class Grid extends React.Component<any, any> {
  componentWillReceiveProps(nextProps) {
    const theme = this.props.theme;
    if (
      nextProps.dashboard !== this.props.dashboard &&
      nextProps.dashboard &&
      nextProps.dashboard.tiles
    ) {
      this.props.getGridLayout(nextProps.dashboard.id);
      Tiles = TileBuilder.build(
        nextProps.dashboard.tiles,
        nextProps.size,
        theme
      );
    }
  }

  componentWillMount() {
    const theme = this.props.theme;
    if (this.props.dashboard && this.props.dashboard.tiles) {
      this.props.getGridLayout(this.props.dashboard.id);
      Tiles = TileBuilder.build(
        this.props.dashboard.tiles,
        this.props.size,
        theme
      );
    }
  }

  render() {
    let { dragAndDropEnabled, dashboard, gridLayout } = this.props;

    if (!dashboard) return null;

    console.log('render dashboard', dashboard);

    let { width } = this.props.size;

    const theme = this.props.theme.status;

    return (
      <Container className="cs-grid-layout">
        <LayoutDiv show={true}>
          {/* <LayoutDiv show={this.props.show}> */}
          <ContainerDiv>
            <ResponsiveGridLayout
              className="layout"
              layouts={gridLayout}
              cols={{ lg: 6, md: 4, sm: 4, xs: 2 }}
              breakpoints={{
                lg: theme.lg.grid,
                md: theme.md.grid,
                sm: theme.sm.grid,
                xs: 0
              }}
              rowHeight={width <= 600 ? 133.33 : 240}
              margin={width <= 600 ? [20, 20] : [40, 40]}
              isResizable={false}
              isDraggable={dragAndDropEnabled}
              useCSSTransforms={false}
              onLayoutChange={(currentLayout, allLayouts) => {
                if (dragAndDropEnabled) {
                  this.props.setGridLayout({ id: dashboard.id, allLayouts });
                }
              }}
              containerPadding={[0, 0]}
              onDragStart={() => {
                // This disables default scrolls on touch screens when resizing or moving boxes
                document.ontouchmove = function(e) {
                  e.preventDefault();
                };
              }}
              onDragStop={() => {
                // This reactivates default scrolling behavior on touch screens after resizing or moving boxes
                document.ontouchmove = function() {
                  return true;
                };
              }}
            >
              {Tiles}
            </ResponsiveGridLayout>
          </ContainerDiv>
        </LayoutDiv>
      </Container>
    );
  }
}

export default withRouter(withTheme(Grid));
