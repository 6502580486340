import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Eventlist from '../index';
import { toggleSelectedEvent } from '../actions';
import {
  getEvents,
  getEventsLoading
} from '../../../../../selectors/eventsMSP';

const mapStateToProps = (state, ownProps) => ({
  eventsLoading: getEventsLoading(state),
  events: getEvents(state, ownProps.match.params.tabId),
  assetId: ownProps.match.params.tabId,
  selectedEvents: state.scenes.events.selectedEvents
});

const mapDispatchToProps = dispatch => ({
  toggleSelectedEvent: payload => dispatch(toggleSelectedEvent(payload))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Eventlist)
);
