import InfoIcons from './InfoIcons/containers';
import InfoImage from './InfoImage/containers';
import NumberProcess from './NumberProcess/container';
import Number from './Number/container';
import DonutChart from './DonutChart/container';
import BarChart from './BarChart/container';
import Table from './Table/containers';
import RadarChart from './RadarChart/container';
import LineChart from './LineChart/container';
import ApolloLineChart from './ApolloLineChart/container';

export default {
  infoIcons: InfoIcons,
  infoImage: InfoImage,
  numberProcess: NumberProcess,
  number: Number,
  donutChart: DonutChart,
  barChart: BarChart,
  table: Table,
  radarChart: RadarChart,
  lineChart: LineChart,
  apolloLineChart: ApolloLineChart
};
