const SET_STATUS_GRID_LAYOUT_REQUESTED = 'SET_STATUS_GRID_LAYOUT_REQUESTED';
const SET_STATUS_GRID_LAYOUT_SUCCEEDED = 'SET_STATUS_GRID_LAYOUT_SUCCEEDED';
const SET_STATUS_GRID_LAYOUT_FAILED = 'SET_STATUS_GRID_LAYOUT_FAILED';

const GET_STATUS_GRID_LAYOUT_REQUESTED = 'GET_STATUS_GRID_LAYOUT_REQUESTED';
const GET_STATUS_GRID_LAYOUT_SUCCEEDED = 'GET_STATUS_GRID_LAYOUT_SUCCEEDED';
const GET_STATUS_GRID_LAYOUT_FAILED = 'GET_STATUS_GRID_LAYOUT_FAILED';

const ON_STATUS_DRAG_CHANGE = 'ON_STATUS_DRAG_CHANGE';

export default {
  SET_STATUS_GRID_LAYOUT_REQUESTED,
  SET_STATUS_GRID_LAYOUT_SUCCEEDED,
  SET_STATUS_GRID_LAYOUT_FAILED,
  GET_STATUS_GRID_LAYOUT_REQUESTED,
  GET_STATUS_GRID_LAYOUT_SUCCEEDED,
  GET_STATUS_GRID_LAYOUT_FAILED,
  ON_STATUS_DRAG_CHANGE
};
