import * as React from 'react';
import styled from 'styled-components';

import EventType from '../EventType';
import EventName from '../EventName';
import Date from '../Date';
import Description from '../Description';
import Action from '../Action';

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 0px 20px;

  div {
    padding: 0px 10px;

    p {
      font-size: 14px;
      font-family: Montserrat Bold;
      margin-bottom: 0px;
    }
  }

  @media (max-width: 991.92px) {
    display: none;
  }
`;

export default () => (
  <Header>
    <EventType>
      <p>EVENT TYP</p>
    </EventType>
    <EventName>
      <p>EVENT NAME</p>
    </EventName>
    <Date>
      <p>DATUM</p>
    </Date>
    <Description>
      <p>BESCHREIBUNG</p>
    </Description>
    <Action>
      <p>AUSWÄHLEN</p>
    </Action>
  </Header>
);
