// import '@babel/polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import moment from 'moment';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
// import { split } from 'apollo-link';
// import { WebSocketLink } from 'apollo-link-ws';
import { createHttpLink } from 'apollo-link-http';
// import { setContext } from 'apollo-link-context';
// import { from } from 'apollo-client-preset';
import { ApolloProvider } from '@apollo/react-hooks';
// import { getMainDefinition } from 'apollo-utilities';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import ThemeProviderContainer from './container/ThemeProvider/index';

import { Auth0Provider, useAuth0 } from './services/auth0';
import auth0Config from './services/auth0/config/';

// Font Awesome module imports
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { history, configureStore } from './services/store';
import { getConfig } from './services/core/cycles/actions';
//import registerServiceWorker from './registerServiceWorker';

// css
// Don't import css files after scenes!
import './index.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './fonts/fonts.css';
import './services/themes/echartsCi';

// components
import Loader from './components/FullScreenLoader';

// scenes
import sceneBuilder from './services/core/sceneBuilder';

library.add(far, fas, fal, fad, fab);
const BelowMenuToaster = styled<any>(ToastContainer)`
  margin-top: 31px;
`;

const Body = styled.div`
  height: 100%;
  min-height: 100%;
`;

const Container = styled.div`
  #_mdspcontent {
    background-color: ${props => props.theme.background};
  }

  button {
    font-family: Montserrat;
  }
`;

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const store = configureStore({});
store.dispatch(getConfig());

let apolloClient = null;
const WithApollo = ({ children }) => {
  const { getTokenSilently, loading } = useAuth0();
  // const {  loading } = useAuth0();
  if (!loading && !apolloClient) {
    const url =
      'https://4uqxnkcqjvcbthidhcnbvnibpu.appsync-api.eu-central-1.amazonaws.com/graphql';
    const region = 'eu-central-1';
    const auth = {
      type: 'OPENID_CONNECT',
      jwtToken: async () => {
        const token = await getTokenSilently();
        return token;
      }
    };

    const httpLink = createHttpLink({ uri: url });

    const link = ApolloLink.from([
      //@ts-ignore
      createAuthLink({ url, region, auth }),
      createSubscriptionHandshakeLink(url, httpLink)
    ]);

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache()
    });

    return (
      <ApolloProvider client={client}>
        <ApolloInit>{children}</ApolloInit>
      </ApolloProvider>
    );
  }

  return <Loader />;
};

const ApolloInit = ({ children }) => {
  const DATA_QUERY = gql`
    query listTimeseriesStorages($day: AWSDate!) {
      listTimeseriesStorages(day: $day) {
        items {
          day
          time
          data
        }
      }
    }
  `;

  const DATA_SUBSCRIPTION = gql`
    subscription onCreateTimeseriesStorage {
      onCreateTimeseriesStorage {
        day
        time
        data
      }
    }
  `;

  const today = moment().format('YYYY-MM-DD');
  const result = useQuery(DATA_QUERY, {
    variables: {
      day: today
    }
  });

  const subscribeMore = () =>
    result.subscribeToMore({
      document: DATA_SUBSCRIPTION,
      variables: {},
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const newFeedItem = subscriptionData.data.onCreateTimeseriesStorage;

        return Object.assign({}, prev, {
          listTimeseriesStorages: {
            items: [...prev.listTimeseriesStorages.items, newFeedItem],
            __typename: 'TimeseriesStorageConnection'
          }
        });
      }
    });

  return (
    <ApolloSubscriber subscribeMore={subscribeMore}>
      {children}
    </ApolloSubscriber>
  );
};

class ApolloSubscriber extends React.Component<any, any> {
  componentDidMount() {
    if (this.props.subscribeMore) {
      this.props.subscribeMore();
    }
  }

  render() {
    return this.props.children;
  }
}

/**
 * Containing all app routes
 */
const Routes = ({ config }) => {
  return (
    <WithApollo>
      <ConnectedRouter history={history}>
        <ThemeProviderContainer>
          <Body>
            <BelowMenuToaster
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <Switch>{sceneBuilder(config)}</Switch>
          </Body>
        </ThemeProviderContainer>
      </ConnectedRouter>
    </WithApollo>
  );
};

const hideMspOsBar = () => {
  try {
    var styles = `
      #_mdspbar { 
          display: none;
      }

      .back-to-mindsphere {
        display: none !important;
      }
  `;

    var styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  } catch {}
};

/**
 * Waits until the config has loaded before rendering the app
 */
const AppContainer = () => {
  console.log('render app container');

  const config = useSelector(state => state.config);

  const settings = config.find(({ id }) => id === 'settings');
  if (settings && settings.showMspOsBar === false) {
    hideMspOsBar();
  }

  return config.length === 0 ? <Loader /> : <Routes config={config} />;
};

/**
 * The app
 */
class App extends React.Component<any, any> {
  render() {
    console.log('render app');

    return (
      <Auth0Provider
        domain={auth0Config.domain}
        client_id={auth0Config.clientId}
        audience={auth0Config.audience}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <Container>
          <Provider store={store}>
            <AppContainer />
          </Provider>
        </Container>
      </Auth0Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
