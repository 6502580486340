import ApiClient from '../ApiClient';
import { Solution } from '../../../dataSources/sources/csServices/types';

export default class Solutions extends ApiClient {
  static async list() {
    return await this.get('solutions');
  }

  static async create(solution: Solution) {
    //@ts-ignore
    return await this.post('solutions', solution);
  }

  static async update(solution: Solution) {
    //@ts-ignore
    return await this.put('solutions', solution.id, solution);
  }
}
