import * as React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

const CustomSelect = styled<any>(Select)`
  .exo-select__indicator-separator {
    display: none;
  }
`;

const customStyles = theme => {
  return {
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected ? theme.main : '#ffffff',
        color: state.isSelected ? '#ffffff' : '#212529',
        fontFamily: 'Montserrat'
      };
    },
    singleValue: provided => ({
      ...provided,
      color: '#212529',
      fontFamily: 'Montserrat Bold'
    }),
    input: provided => ({
      ...provided,
      fontFamily: 'Montserrat'
    })
  };
};

export default props => {
  const {
    value,
    onChange,
    options,
    placeholder,
    isSearchable = true,
    theme
  } = props;

  return (
    //@ts-ignore
    <CustomSelect
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={options}
      styles={customStyles(theme)}
      isSearchable={isSearchable}
      className="cs-select-container"
      classNamePrefix="cs-select"
    />
  );
};
