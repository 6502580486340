import * as React from 'react';
import styled from 'styled-components';
import { NavItem, NavLink } from 'reactstrap';

const MindsphereLink = styled(NavItem)`
  cursor: pointer;
  height: 50px;

  border-top-color: #e3e6e1;
  border-top-style: solid;
  border-top-width: 1px;

  &:hover {
    background-color: rgba(95, 150, 57, 0.1);
  }

  .nav-link {
    color: #b1b9ab !important;
    line-height: 34px;
    font-size: 16px;
    font-family: Montserrat Bold;
    vertical-align: middle;
  }

  ${props =>
    props.active &&
    `
    .nav-link {
      color: ${props.theme.main} !important;
    }
  `}
`;

/**
 * The mindsphere link to return to mindsphere
 */
export default () => (
  <MindsphereLink className="back-to-mindsphere">
    <NavLink href={`https://${process.env.REACT_APP_TENANT}.eu1.mindsphere.io`}>
      Mindsphere Launchpad
    </NavLink>
  </MindsphereLink>
);
