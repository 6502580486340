import * as React from 'react';
import { Component } from 'react';
import styled from 'styled-components';

import Tile from '../../../Tile';
import NumberWithUnit from '../../../Tile/components/NumberWithUnit';
import { isUndefined } from 'lodash';

const Content = styled.div`
  display: flex;
  flex-direction: row;

  height: 100%;
`;

/** Layer State - Displays actual and total layer count */
class Number extends Component<any, any> {
  render() {
    const { config, data } = this.props;

    return (
      <Tile header={`${config.title}`} {...this.props}>
        <Content>
          <NumberWithUnit
            number={isUndefined(data[0]) ? '-' : data[0]}
            unit={`${config.data[0].properties.unit || ''}`}
          ></NumberWithUnit>
        </Content>
      </Tile>
    );
  }
}

export default Number;
