import { connect } from 'react-redux';

import Scene from '../index';
import { openIncidentModal } from '../../../components/Service/IncidentModal/actions';
import { openServicecaseModal } from '../../../components/Service/ServicecaseModal/actions';
import { displayScene } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  displayScene: state.scenes.serviceLog.displayScene
});

const mapDispatchToProps = dispatch => ({
  openIncidentModal: () => dispatch(openIncidentModal()),
  openServicecaseModal: () => dispatch(openServicecaseModal()),
  changeDisplayScene: sceneName => dispatch(displayScene(sceneName))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scene);
