import * as React from 'react';
import styled from 'styled-components';

// const getTextSize = (n1, n2, maxSize) => {
//   let fontSize = maxSize;
//   const baseLength = 8;
//   const numberLength = n1.toString().length + n2.toString().length;
//   if (numberLength >= baseLength) {
//     const diff = numberLength - baseLength;
//     fontSize = maxSize - diff * 5;
//   }

//   return fontSize;
// };

const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-left: 5px;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FirstNumber = styled.p`
  font-family: Montserrat Medium;
  font-size: 60px;
  line-height: 60px;
  color: ${props => props.theme.main};

  @media (max-width: 600px) {
    font-size: 60px;
    line-height: 60px;
  }
`;
const SecondNumber = styled.p`
  font-family: Montserrat Medium;
  font-size: 20px;
  line-height: 20px;
  color: #acb5a6;
  margin-bottom: -10px;

  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 22px;
  }
`;

export default props => {
  return (
    <Text>
      <FirstNumber>{props.firstNumber}</FirstNumber>
      <SecondNumber>/{props.secondNumber}</SecondNumber>
    </Text>
  );
};
