import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ServicecaseModal from '../index';
import {
  createServicecase,
  updateServicecase,
  closeServicecaseModal,
  editServicecase
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.scenes.serviceLog.servicecaseModal.isOpen,
  id: state.scenes.serviceLog.servicecaseModal.id,
  title: state.scenes.serviceLog.servicecaseModal.title,
  servicecaseType: state.scenes.serviceLog.servicecaseModal.servicecaseType,
  servicecaseNumber: state.scenes.serviceLog.servicecaseModal.servicecaseNumber
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeServicecaseModal()),
  edit: (scope, value) => dispatch(editServicecase({ scope, value })),
  save: data => {
    data.id
      ? dispatch(
          updateServicecase({
            itemName: 'Servicecases',
            data
          })
        )
      : dispatch(
          createServicecase({
            itemName: 'Servicecases',
            data
          })
        );
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ServicecaseModal)
);
