import * as React from 'react';
import styled from 'styled-components';

import AckButton from './components/AckButton';
import Filter from './components/Filter';

const ActionsbarDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px;

  position: relative;
  top: -54px;
  width: 100%;

  @media (max-width: 991.92px) {
    top: 0px;
    margin-bottom: 30px;
  }
`;

export default ({ selectedEvents, filter, selectFilter, ackEvents }) => {
  if (!filter) return null;
  return (
    <ActionsbarDiv>
      <Filter
        description={'Status'}
        options={[
          { label: 'Aktiv', value: false },
          { label: 'Quittiert', value: true }
        ]}
        scope={'acknowledged'}
        onChange={(scope, value) => selectFilter(scope, value, filter)}
        filter={filter}
      />
      <Filter
        description={'Meldeklassen'}
        options={[
          { label: 'Alle', value: 'all' },
          { label: 'Fehler', value: 20 },
          { label: 'Warnung', value: 30 },
          { label: 'Info', value: 40 }
        ]}
        scope={'severity'}
        onChange={(scope, value) => selectFilter(scope, value, filter)}
        filter={filter}
      />
      <AckButton onClick={() => ackEvents(selectedEvents)} />
    </ActionsbarDiv>
  );
};
