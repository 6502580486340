import { connect } from "react-redux";

import VerticalNav from "../../../VerticalNav";
import { push } from "connected-react-router";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  push: link => dispatch(push(link))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalNav);
