import { combineReducers } from 'redux';
import displayScene from './displayScene';
import incidentDetailModal from '../../../components/Service/IncidentDetailModal/reducer';
import servicecaseModal from '../../../components/Service/ServicecaseModal/reducer';
import incidentModal from '../../../components/Service/IncidentModal/reducer';
import deleteModal from '../../../components/Service/DeleteModal/reducer';

export default combineReducers({
  displayScene,
  incidentDetailModal,
  incidentModal,
  servicecaseModal,
  deleteModal
});
