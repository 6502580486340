
import * as React from 'react';
import styled from 'styled-components';

const EventName = styled.div`
  width: 250px;
  padding: 10px;

  @media (max-width: 991.92px) {
    width: 100%;
  }
`;

export default ({ children }) => <EventName>{children}</EventName>;
