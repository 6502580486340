import { connect } from 'react-redux';
import DonutChart from '../';

import { selectData } from '../../../../../selectors/TileDataSelector';

const mapStateToProps = (state, ownProps) => {
  const data = selectData(state, ownProps);

  return {
    // Donutchart data has maximal one array element
    data: data[0] || {}
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonutChart);
