import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import LineChartElement from '../../../Tile/components/LineChartElement';
import {
  LineChartData,
  LineChartSeries,
  LineChartMarkArea
} from '../../../Tile/components/LineChartElement/services/getChartOption';
import Tile from '../../../Tile';
import getMarkAreaSeries from './utils/markArea';
import ExpandButton from '../../../Tile/components/ExpandButton';

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;

  padding-top: 20px;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

type ApolloDataPoint = {
  data: string;
  day: string;
  time: string;
};

type ApolloSeries = {
  listTimeseriesStorages: {
    items: ApolloDataPoint[];
  };
};

type Config = {
  title: string;
  data: [
    {
      properties: ConfigProperties;
    }
  ];
};

type ConfigProperties = {
  displayName: string;
  unit: string;
  query: string;
  property: string;
  displayType: string;
};

const getSeries = (
  items: ApolloDataPoint[],
  properties: ConfigProperties
): LineChartSeries => {
  const series = [];

  items.forEach(({ data, day, time }) => {
    const value = JSON.parse(data)[properties.property];
    if (typeof value !== 'undefined')
      series.push({
        time: new Date(`${day}T${time}`).toISOString(),
        value
      });
  });

  return series;
};

const getMarkArea = (
  items: ApolloDataPoint[],
  properties: ConfigProperties
): LineChartMarkArea[] => {
  const series = getSeries(items, properties);

  return getMarkAreaSeries(series);
};

const formatSeriesData = (
  apolloSeries: ApolloSeries,
  config: Config
): LineChartData => {
  try {
    return config.data.map(({ properties }) => {
      switch (properties.displayType) {
        case 'markArea':
          return getMarkArea(
            apolloSeries.listTimeseriesStorages.items,
            properties
          );
        case 'line':
        default:
          return getSeries(
            apolloSeries.listTimeseriesStorages.items,
            properties
          );
      }
    });
  } catch {
    return [[], []];
  }
};

// const sortResult = (data: ApolloSeries): ApolloSeries => {
//   try {
//     const dataByDay = {};

//     data.listTimeseriesStorages.items.forEach(datapoint => {
//       if (!dataByDay[datapoint.day]) dataByDay[datapoint.day] = [];
//       dataByDay[datapoint.day].push(datapoint);
//     });

//     const sortedDays = Object.keys(dataByDay).sort();
//     const sortedItems = [];
//     sortedDays.forEach(day => {
//       sortedItems.push(...dataByDay[day]);
//     });
//     return {
//       listTimeseriesStorages: {
//         items: sortedItems
//       }
//     };
//   } catch (e) {
//     return {
//       listTimeseriesStorages: {
//         items: []
//       }
//     };
//   }
// };

const getItems = r => {
  try {
    return r.data.listTimeseriesStorages.items;
  } catch (e) {
    return [];
  }
};

const mergeData = (r1, r2, r3, r4, r5, r6, r7): ApolloSeries => {
  try {
    return {
      listTimeseriesStorages: {
        items: [
          ...getItems(r7),
          ...getItems(r6),
          ...getItems(r5),
          ...getItems(r4),
          ...getItems(r3),
          ...getItems(r2),
          ...getItems(r1)
        ]
      }
    };
  } catch (e) {
    return {
      listTimeseriesStorages: {
        items: []
      }
    };
  }
};

export default props => {
  const { config } = props;

  const DATA_QUERY = gql`
    ${config.data[0].properties.query}
  `;

  const today = moment().format('YYYY-MM-DD');
  const todayM1 = moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD');
  const todayM2 = moment()
    .subtract(2, 'days')
    .format('YYYY-MM-DD');
  const todayM3 = moment()
    .subtract(3, 'days')
    .format('YYYY-MM-DD');
  const todayM4 = moment()
    .subtract(4, 'days')
    .format('YYYY-MM-DD');
  const todayM5 = moment()
    .subtract(5, 'days')
    .format('YYYY-MM-DD');
  const todayM6 = moment()
    .subtract(7, 'days')
    .format('YYYY-MM-DD');


  const result = useQuery(DATA_QUERY, {
    variables: {
      day: today
    }
  });

  const resultM1 = useQuery(DATA_QUERY, {
    variables: {
      day: todayM1
    }
  });
  const resultM2 = useQuery(DATA_QUERY, {
    variables: {
      day: todayM2
    }
  });
  const resultM3 = useQuery(DATA_QUERY, {
    variables: {
      day: todayM3
    }
  });
  const resultM4 = useQuery(DATA_QUERY, {
    variables: {
      day: todayM4
    }
  });
  const resultM5 = useQuery(DATA_QUERY, {
    variables: {
      day: todayM5
    }
  });
  const resultM6 = useQuery(DATA_QUERY, {
    variables: {
      day: todayM6
    }
  });

  const data = mergeData(
    result,
    resultM1,
    resultM2,
    resultM3,
    resultM4,
    resultM5,
    resultM6
  );

  const formattedData = formatSeriesData(data, config);

  if (!props.isDetailView) {
    const COUNT_DATAPOINTS_TILE_VIEW = 60 * 24;
    const arrayStart = formattedData[0].length - COUNT_DATAPOINTS_TILE_VIEW;
    const arrayEnd = formattedData[0].length;

    formattedData[0] = formattedData[0].slice(
      arrayStart < 0 ? 0 : arrayStart,
      arrayEnd
    );
  }

  return (
    <Tile
      header={config.title}
      action={
        !props.isDetailView && (
          <ExpandButton
            onClick={() => props.showDetailedChartView(config.id)}
          />
        )
      }
      forceHeight={props.height}
      {...props}
    >
      <Content className="tile-content">
        {/* Mouse Events are disabled by the CSS on the Content div  */}
        <LineChartElement data={formattedData} {...props} />
      </Content>
    </Tile>
  );
};
