import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Layout from '../../index';

const mapStateToProps = (state, ownProps) => ({
  dashboards: state.config,
  showDetailedChartViewFor: state.chart.showDetailedChartViewFor
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Layout)
);
