import { createAction } from 'redux-actions';
import types from '../types';

export const setStatusGridLayout = createAction(
  types.SET_STATUS_GRID_LAYOUT_REQUESTED
);
export const setStatusGridLayoutSucceeded = createAction(
  types.SET_STATUS_GRID_LAYOUT_SUCCEEDED
);
export const setStatusGridLayoutFailed = createAction(
  types.SET_STATUS_GRID_LAYOUT_FAILED
);

export const getStatusGridLayout = createAction(
  types.GET_STATUS_GRID_LAYOUT_REQUESTED
);
export const getStatusGridLayoutSucceeded = createAction(
  types.GET_STATUS_GRID_LAYOUT_SUCCEEDED
);
export const getStatusGridLayoutFailed = createAction(
  types.GET_STATUS_GRID_LAYOUT_FAILED
);

export const onStatusDragChange = createAction(types.ON_STATUS_DRAG_CHANGE);
