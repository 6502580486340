import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';

const AdminButton = styled(Button)`
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.main};
  background-color: ${props => props.theme.mainLight};
  color: ${props => props.theme.main};
  margin-bottom: -10px;
  margin-right: 20px;

  &&:first-child {
    margin-left: auto;
  }

  @media (max-width: 991.92px) {
    font-size: 14px;

    &&:first-child {
      margin-left: 0px;
    }
  }

  &:hover {
    color: ${props => props.theme.mainLight};
    background-color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }
`;

export default ({ onClick }) => (
  <AdminButton onClick={onClick}>Service Verwaltung</AdminButton>
);
