const defaultTheme = {
  status: {
    lg: { page: 1840, layout: 1120, grid: 1599 }, // grid might needs adjustment
    md: { page: 1300, layout: 540, grid: 1119 },
    sm: { page: 680, layout: 300, grid: 540 },
    menu: 101 // No used
  },
  job: {
    lg: { page: 1840, layout: 1120, grid: 1699 },
    md: { page: 1300, layout: 540, grid: 1119 },
    sm: { page: 680, layout: 300, grid: 540 },
    menu: 101 // Not used
  }
};

export default defaultTheme;
