import * as React from 'react';
import * as sizeMe from 'react-sizeme';
import Layout from '../../components/Layout/containers/LayoutContainer';
import DashboardManager from '../../services/core/dashboardManager';
// import PageLoadingIndicator from '../../components/PageLoadingIndicator';
// import LiveReload from '../../services/LiveReload';

//container
import GridLayout from './containers/GridLayoutContainer';

/** Page Content */
class StatusScene extends React.Component<any, any> {
  dashboard = null;
  liveMsReload = null;
  liveInfoReload = null;

  constructor(props) {
    super(props);
    this.dashboard = new DashboardManager();
    this.liveMsReload = null;
    this.liveInfoReload = null;
  }

  render() {
    return (
      <Layout
        name={'Status'}
        showHorizontalMenu
        showDragAndDrop
        dashboards={this.props.dashboards}
        layout={this.props.layout}
      >
        {/* {loading && <PageLoadingIndicator />} */}
        <GridLayout dashboard={this.props.dashboard} size={this.props.size} />
      </Layout>
    );
  }
}

// @ts-ignore
export default sizeMe({ monitorHeight: true, noPlaceholder: true })(
  StatusScene
);
