import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from '../../reducers';
import sagas from '../../sagas';

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();
export const configureStore = preloadedState => {
  let store;
  if (process.env.NODE_ENV === 'development') {
    store = createStore(
      createRootReducer(history),
      preloadedState,
      compose(
        applyMiddleware(sagaMiddleware, routerMiddleware(history)),
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          // @ts-ignore
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    );
  } else {
    store = createStore(
      createRootReducer(history),
      preloadedState,
      compose(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
    );
  }

  sagaMiddleware.run(sagas);

  return store;
};
