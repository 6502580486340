import { handleActions } from 'redux-actions';
import types from '../types';

const initialState: string[] = [];

export default handleActions(
  {
    [types.TOGGLE_SELECTED_EVENT]: (state, { payload }) => {
      const id: string = payload;

      if (state.some(_id => _id === id)) {
        return [...state.filter(_id => _id !== id)];
      } else {
        return [...state, id];
      }
    }
  },
  initialState
);
