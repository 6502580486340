import { connect } from 'react-redux';

import MobileNav from '../../../../components/MobileNav';
import { openMenu, toggleDragAndDrop } from '../../../../actions';

const mapStateToProps = state => ({
  dragAndDropEnabled: state.layout.dragAndDropEnabled
});

const mapDispatchToProps = dispatch => ({
  openMenu: () => dispatch(openMenu()),
  toggleDragAndDrop: () => dispatch(toggleDragAndDrop())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNav);
