import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  isOpen: false,
  id: '',
  itemName: '',
  displayItemName: ''
};

export default handleActions(
  {
    [types.OPEN_MODAL]: (state, { payload }) => {
      return {
        isOpen: true,
        ...payload
      };
    },
    [types.CANCEL]: (state, { payload }) => {
      return initialState;
    },
    [types.DELETE_REQUESTED]: (state, { payload }) => {
      return initialState;
    }
  },
  initialState
);
