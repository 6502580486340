import * as React from 'react';
import styled from 'styled-components';

const Text = styled.p<any>`
  margin-bottom: 0px;

  ${({ textStyle = {} }) => `
    font-family: ${textStyle.bold ? 'Montserrat Bold' : 'Montserrat'}        
  `}
`;

export default ({ children, textStyle }) => (
  <Text textStyle={textStyle}>{children}</Text>
);
