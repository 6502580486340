import * as React from 'react';
import styled from 'styled-components';

const Action = styled.div`
  width: 100px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991.92px) {
    display: none;
  }
`;

export default ({ children }) => (
  //@ts-ignore
  <Action>{children}</Action>
);
