import { isUndefined } from 'lodash';

export default (
  data: {
    sum: number;
    events: Array<{ name: string; value: number }>;
  },
  theme
) => {
  if (!isUndefined(data.events)) {
    if (data.events.length !== 0) {
      let labels: Array<string> = [];

      labels = data.events.map(entry => entry.name);

      return {
        animationDuration: 0,
        color: theme.colorPalette,
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'horizontal',
          y: 'bottom',
          data: labels,
          type: 'scroll'
        },

        series: [
          {
            name: 'Fehler:',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            tooltip: { show: true },
            label: {
              normal: {
                padding: 0,
                show: true,
                position: 'center',

                formatter: () => {
                  return `{a|${data.sum}}\n{b|Meldungen}`;
                },
                rich: {
                  a: {
                    color: '#2e3940',
                    fontSize: 34,
                    fontFamily: 'Montserrat',
                    padding: [0, 0, 5, 0]
                  },
                  b: {
                    color: theme.conterLight,
                    fontSize: 14,
                    fontFamily: 'Montserrat',
                    padding: [0, 0, 5, 0]
                  }
                }
              }
            },

            labelLine: {
              normal: {
                padding: 0,
                show: false
              }
            },
            data: data.events
          }
        ]
      };
    }
  }

  return {
    animationDuration: 0,
    color: ['#00bfbe', '#00a7cc', '#0076cf', '#9700d8', '#6457f2', '#2918e8'],
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'horizontal',
      y: 'bottom',
      data: [],
      type: 'scroll'
    },
    label: {},
    series: [
      {
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        tooltip: { show: false },
        cursor: 'pointer',
        label: {
          normal: {
            padding: 0,
            show: true,
            position: 'center',

            cursor: 'pointer',
            formatter: () => {
              return `{b|Zur Zeit sind keine Fehlerdaten vorhanden}`;
            },
            rich: {
              b: {
                color: theme.conterLight,
                fontSize: 14,
                fontFamily: 'Montserrat',
                padding: [0, 0, 5, 0]
              }
            }
          }
        },

        data: [{}]
      }
    ]
  };
};
