import * as React from 'react';
import styled from 'styled-components';

import Text from '../Text';
import Dot from '../LabeledDot';
import Button from '../Button';

const Cell = styled.div<any>`
  padding: 10px;
  display: flex;
  flex-direction: row;

  ${({ pushToRight }) => pushToRight && `margin-left: auto;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ hideOnMobile }) =>
    hideOnMobile &&
    `
  @media (max-width: 991.92px) {
   display: none;
  }
  `}

  @media (max-width: 991.92px) {
    margin-left: 0px;
  }
`;

const getElement = ({ children, textStyle, type, color, onClick, size }) => {
  switch (type) {
    case 'dot':
      return (
        <Dot textStyle={textStyle} color={color} size={size}>
          {children}
        </Dot>
      );
    case 'button':
      return (
        //@ts-ignore
        <Button textStyle={textStyle} onClick={onClick}>
          {children}
        </Button>
      );

    case 'text':
    default:
      return <Text textStyle={textStyle}>{children}</Text>;
  }
};

export default props => {
  const { pushToRight, width, hideOnMobile } = props;

  return (
    <Cell pushToRight={pushToRight} width={width} hideOnMobile={hideOnMobile}>
      {getElement(props)}
    </Cell>
  );
};
