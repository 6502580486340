import { connect } from 'react-redux';
import GridLayout from '../../components/GridLayout/component';
import {
  setStatusGridLayout,
  getStatusGridLayout,
  onStatusDragChange
} from '../../components/GridLayout/actions';

const mapStateToProps = state => {
  const { gridLayout } = state.scenes;

  return {
    gridLayout,
    dragAndDropEnabled: state.layout.dragAndDropEnabled
  };
};

const mapDispatchToProps = dispatch => ({
  setGridLayout: gridLayout => dispatch(setStatusGridLayout(gridLayout)),
  getGridLayout: id => dispatch(getStatusGridLayout(id)),
  onDragChange: dragState => dispatch(onStatusDragChange(dragState))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridLayout);
