import AggregateMSP from './sources/aggregateMSP';
import TimeseriesMSP from './sources/timeseriesMSP';
import EventsMSP from './sources/eventsMSP';
import EventAnalyticsMSP from './sources/eventAnalyticsMSP';
import CsServices from './sources/csServices';
import AssetsMSP from './sources/assetsMSP';

export const dataSources = {
  aggregateMSP: AggregateMSP,
  eventsMSP: EventsMSP,
  timeseriesMSP: TimeseriesMSP,
  eventAnalyticsMSP: EventAnalyticsMSP,
  csServices: CsServices,
  assetsMSP: AssetsMSP
};
