import { isUndefined } from 'lodash';
import { SourceState } from '../../services/core/globalTypes/state';
import { Tile } from '../../services/core/globalTypes/dashboard';
import { dataSources } from '../../services/core/dataSources/';
import VALUE_TYPE from '../../services/core/enums/dataValues';

export const selectData = (
  state: SourceState,
  ownProps: { config: Tile }
): Array<any> => {
  const data: Array<any> = [];

  ownProps.config.data.forEach(entry => {
    const source: string = entry.source.name;
    const id: string = dataSources[source].getId(entry.properties);

    if (!isUndefined(state[source][id])) {
      if (entry.properties.valueType === VALUE_TYPE.series) {
        data.push(state[source][id].data);
      } else {
        const length = state[source][id].data.length;
        if (length !== 0) {
          if (entry.properties.valueType === 0) {
            data.push(state[source][id].data[length - 1].value);
          } else {
            data.push(state[source][id].data[length - 1]);
          }
        } else {
          // Push undefined to keep position in the array
          data.push(undefined);
        }
      }
    } else {
      // Push undefined to keep position in the array
      data.push(undefined);
    }
  });
  return data;
};
