import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  isOpen: false,
  id: '',
  title: '',
  servicecaseType: 'Elektrisch',
  servicecaseNumber: ''
};

export default handleActions(
  {
    [types.OPEN_MODAL]: (state, { payload }) => {
      // create
      if (!payload) return { ...state, isOpen: true };

      // update
      const { id, title, servicecaseType, servicecaseNumber } = payload;
      return {
        ...state,
        isOpen: true,
        id: id || '',
        title: title || '',
        servicecaseType: servicecaseType || '',
        servicecaseNumber: servicecaseNumber || ''
      };
    },
    [types.CLOSE_MODAL]: (state, { payload }) => {
      return initialState;
    },
    [types.EDIT_SERVICECASE]: (state, { payload }) => {
      const { scope, value } = payload;

      return { ...state, [scope]: value };
    },
    [types.CREATE_REQUESTED]: (state, { payload }) => {
      const { itemName } = payload;
      if (itemName === 'Servicecases') {
        return initialState;
      }

      return state;
    }
  },
  initialState
);
