import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import EventType from '../EventType';
import LabeledDot from '../EventType/components/LabeledDot';
import eventTypeEnum from '../../../../../../services/eventTypeEnum';
import EventName from '../EventName';
import Date from '../Date';
import Description from '../Description';
import Action from '../Action';
import Checkbox from '../Action/components/Checkbox';

const Event = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 20px;
  cursor: pointer;

  div {
    p {
      font-size: 18px;
      line-height: 28px;
      font-family: Montserrat Bold;
      margin-bottom: 0px;
    }
  }

  @media (max-width: 991.92px) {
    flex-direction: column;
  }
`;

const EventTypeDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 28px;

  .Action-Checkbox {
    margin-left: auto;

    @media (min-width: 991.92px) {
      display: none;
    }
  }
`;

const getEventType = event => eventTypeEnum[event.severity] || {};

export default ({ event, onSelectChange, isSelected }) => (
  <Event onClick={() => onSelectChange()}>
    <EventType>
      <EventTypeDiv>
        <LabeledDot
          color={getEventType(event).color}
          label={getEventType(event).label}
        />
        <Checkbox checked={isSelected} id={event.id} />
      </EventTypeDiv>
    </EventType>
    <EventName>
      <p>{event.description.split('//')[0]}</p>
    </EventName>
    <Date>
      <p>{moment(event.timestamp).format('DD.MM.YYYY, HH:mm')}</p>
    </Date>
    <Description>
      <p className="multiline">{event.description.split('//')[1]}</p>
    </Description>
    <Action>
      <Checkbox checked={isSelected} id={event.id} />
    </Action>
  </Event>
);
