import { handleActions } from 'redux-actions';
import { dataSources } from '../dataSources/index';
import types from './types';
import { Action } from '../globalTypes/dashboard';
import { SourceState } from '../globalTypes/state';

type SourceReducer = (state: SourceState, action: Action) => void;
type SourceReducerMeta = { prefix: string; reducer: SourceReducer };

class ReducerFactory {
  build(reducerMeta: { prefix: string; initialState: object }): SourceReducer {
    const { prefix, initialState } = reducerMeta;
    return handleActions(
      {
        '@@router/LOCATION_CHANGE': state => ({}),
        // Get Reducer
        [types.GET_REQUESTED.replace('*', prefix)]: dataSources[
          prefix
        ].getRequestedReducer.bind(dataSources[prefix]),
        [types.GET_SUCCEEDED.replace('*', prefix)]: dataSources[
          prefix
        ].getSucceededReducer.bind(dataSources[prefix]),
        [types.GET_FAILED.replace('*', prefix)]: dataSources[
          prefix
        ].getFailedReducer.bind(dataSources[prefix]),
        // Update Reducer
        [types.UPDATE_REQUESTED.replace('*', prefix)]: dataSources[
          prefix
        ].updateRequestedReducer.bind(dataSources[prefix]),
        [types.UPDATE_SUCCEEDED.replace('*', prefix)]: dataSources[
          prefix
        ].updateSucceededReducer.bind(dataSources[prefix]),
        [types.UPDATE_FAILED.replace('*', prefix)]: dataSources[
          prefix
        ].updateFailedReducer.bind(dataSources[prefix]),
        [types.DELETE_REQUESTED.replace('*', prefix)]: dataSources[
          prefix
          // Delete Reducer
        ].deleteRequestedReducer.bind(dataSources[prefix]),
        [types.DELETE_SUCCEEDED.replace('*', prefix)]: dataSources[
          prefix
        ].deleteSucceededReducer.bind(dataSources[prefix]),
        [types.DELETE_FAILED.replace('*', prefix)]: dataSources[
          prefix
        ].deleteFailedReducer.bind(dataSources[prefix]),
        // Create Reducer
        [types.CREATE_REQUESTED.replace('*', prefix)]: dataSources[
          prefix
        ].createRequestedReducer.bind(dataSources[prefix]),
        [types.CREATE_SUCCEEDED.replace('*', prefix)]: dataSources[
          prefix
        ].createSucceededReducer.bind(dataSources[prefix]),
        [types.CREATE_FAILED.replace('*', prefix)]: dataSources[
          prefix
        ].createFailedReducer.bind(dataSources[prefix])
      },
      initialState
    );
  }

  getReducers(): Array<SourceReducerMeta> {
    const initialState: object = {};
    const builtReducers: Array<SourceReducerMeta> = Object.keys(
      dataSources
    ).map(source => {
      return {
        prefix: source,
        reducer: this.build({ prefix: source, initialState })
      };
    });

    return builtReducers;
  }
}

const reducerFactory = new ReducerFactory();
export default reducerFactory;
