import * as React from 'react';
import styled from 'styled-components';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import Loading from '../Loading';

const StyledNavItem = styled(NavItem)`
  cursor: pointer;
  height: 50px;

  border-top-color: #e3e6e1;
  border-top-style: solid;
  border-top-width: 1px;

  &:hover {
    background-color: rgba(95, 150, 57, 0.1);
  }

  .nav-link {
    height: 50px;
    color: #b1b9ab !important;
    line-height: 34px;
    font-size: 16px;
    font-family: Montserrat Bold;
    vertical-align: middle;
  }

  ${props =>
    props.active &&
    `
    .nav-link {
      color: ${props.theme.main} !important;
    }
  `}
`;

const SubMenu = styled.div`
  max-height: 0px;
  overflow: hidden;

  transition-property: max-height;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;

  // @ts-ignore
  ${({ expand, itemCount }) =>
    expand &&
    `
    //50 = height of one item
    max-height: ${itemCount * 50}px; 
  `}
`;

const StyledMenuItem = styled(StyledNavItem)`
  ${props =>
    //@ts-ignore
    props.active &&
    `
    border-left-style: solid;
    border-left-color: ${props.theme.main};
    border-left-width: 3px;
    background-color: rgba(95, 150, 57, 0.1);
  `}
`;

const StyledSubMenuItem = styled(StyledNavItem)`
  padding-left: 10px;
`;

const SubMenuItem = props => (
  <StyledSubMenuItem
    active={
      props.currentTabId === props.tabId && props.path === props.currentPath
    }
  >
    <NavLink
      tag={Link}
      to={`${props.path}/${props.tabId}`}
      onClick={props.close}
    >
      {props.text}
    </NavLink>
  </StyledSubMenuItem>
);

/**
 * The nav item that expanse on click, to show more items
 * @param  {Array<Assets>} props.item Array of all items to render in dropdown
 * @param  {void} props.close function to close menu
 * @param  {string} props.selectedMenuItem render items for selected item
 * @param  {void} props.selectMenuItem function to select a menu item
 * @param  {string} props.currentPath currenr path
 * @param  {string} props.path path to link to
 * @param  {string} props.text text for the parent item
 * @param  {string} props.currentTabId currently selected asset
 */
const ExoNavItem = props => {
  const { items = [] } = props;

  const active = props.currentPath === props.path;
  const expand = props.selectedMenuItem === props.path;
  const showLoading = items.length === 0;
  return (
    <div>
      <StyledMenuItem active={active}>
        <NavLink onClick={() => props.selectMenuItem(props.path)}>
          {props.text}
        </NavLink>
      </StyledMenuItem>
      {true && (
        //@ts-ignore
        <SubMenu expand={expand} itemCount={items.length + 1 || 1}>
          {!showLoading &&
            props.items.map(item => {
              return (
                <SubMenuItem
                  currentTabId={props.currentTabId}
                  tabId={item.id}
                  text={item.title}
                  close={props.close}
                  path={props.path}
                  currentPath={props.currentPath}
                  key={item.id}
                />
              );
            })}
          {showLoading && <Loading />}
        </SubMenu>
      )}
    </div>
  );
};

export default ExoNavItem;
