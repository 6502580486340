import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = [];

export default handleActions(
  {
    [types.GET_CONFIG_REQUESTED]: state => [...state],
    [types.GET_CONFIG_SUCCEEDED]: (state, action) => {
      return action.payload;
    },
    [types.GET_CONFIG_FAILED]: state => [...state]
  },
  initialState
);
