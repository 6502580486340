import * as React from 'react';
import styled from 'styled-components';

import ExoRouter from './components/CsRouter/container';
import VerticalNav from './components/VerticalNav/containers/VerticalNavContainer';
import MobileNav from './components/MobileNav/containers/MobileNavContainer';
import MobileMenu from './components/MobileMenu/containers/MobileMenuContainer';
import HorizontalMenu from './components/HorizontalMenu/containers/HorizontalMenuContainer';

import SceneTitle from './components/SceneTitle';
import DetailedChartView from './components/DetailedChartView/container';

import { Scene as SceneType } from '../../services/core/sceneBuilder';

// const FullPageDiv = styled(Div100Vh)`
const FullPageDiv = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;

  @media (min-width: 991.92px) {
    margin-top: -44px;
    padding-top: 44px;
  }
`;

const App = styled.div<any>`
  height:100%
  min-height: 100%;
  background-color: ${props => props.theme.background};

  // @ts-ignore
  ${({ show }) =>
    !show &&
    `
    height: 0px;
    min-height: 0px;
    overflow: hidden;
  `}
`;

const Body = styled.div`
  height: 100%
  min-height: 100%;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  overflow: hidden;

  @media (max-width: 991.92px) {
    height: calc(100% - 42px);
    min-height: calc(100% - 42px);
  }
`;

const Content = styled.div`
  order: 2;
  width: 100%
  min-width: 100%;
  height: 100%
  min-height: 100%;

  flex-direction: column;
`;

const Scene = styled.div<any>`
  order: 2;
  padding: 30px 0px;

  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  @media (min-width: 991.92px) {
    position: relative;
    top: 0px;
    padding: 30px;

    ${({ showHorizontalMenu }) =>
      showHorizontalMenu &&
      `
      height: calc(100% - 80px);
      margin-top: 80px;
    `}

    ${({ offsetleft = 100 }) =>
      `
      left: ${offsetleft}px;
      width: calc(100% - ${offsetleft}px);
      min-width: calc(100% - ${offsetleft}px);
    `}

    @media (max-width: 991.98px) {
      left: 100px;
      width: calc(100% - 100px);
      min-width: calc(100% - 100px);
    }
  }
`;

const getCurrentScene = (name: string, layout: SceneType[]): SceneType => {
  let currentScene: SceneType = layout.find(
    scene => name === scene.displayName
  );

  if (!currentScene)
    currentScene = {
      displayName: 'Not Found',
      name: 'notFound',
      tabs: []
    };

  return currentScene;
};

/**
 * The Layout component with vertical and horizontal navbars for mobil and desktop view
 * @param  {Object} props.location location from redux router
 * @param  {Object} props.match match from redux router
 * @param  {Object} props.history histrory from redux router
 * @param  {Array<Assets>} props.assets Array of all assets for the hozrizontal and mobile menu
 * @param  {string} props.name name of scene (to display on the mobile nav)
 * @param  {boolean} props.showHorizontalMenu render horizontal menu (to not render on "Not Found" scene)
 */
class Layout extends React.Component<any, any> {
  render() {
    const {
      name,
      showHorizontalMenu,
      showDetailedChartViewFor,
      children,
      breadcrums,
      layout
    } = this.props;

    const displayApp = showDetailedChartViewFor === null;

    const currentScene = getCurrentScene(name, layout);

    return (
      <FullPageDiv>
        <ExoRouter tabs={currentScene.tabs}>
          <App show={displayApp}>
            <MobileNav className="mobile-nav" {...this.props} />
            <MobileMenu
              className="mobile-menu"
              layout={layout}
              {...this.props}
            />
            <Body className="app-body">
              <VerticalNav
                className="vertical-nav"
                layout={layout}
                {...this.props}
              />
              <Content className="app-content">
                {showHorizontalMenu && (
                  <HorizontalMenu
                    tabs={currentScene.tabs}
                    className="horizontal-menu"
                    {...this.props}
                  />
                )}
                <Scene id={'SceneWrapper'} {...this.props}>
                  {true && (
                    //@ts-ignore
                    <SceneTitle title={name} breadcrums={breadcrums} />
                  )}
                  {children}
                </Scene>
              </Content>
            </Body>
          </App>
          {showDetailedChartViewFor && <DetailedChartView />}
        </ExoRouter>
      </FullPageDiv>
    );
  }
}

export default Layout;
