import store from 'store';

const localStorage = {
  /**
   * Get data from the local storage
   *
   * @param {string} key Storage key
   * @returns
   */
  getStore(key) {
    try {
      const data = store.get(key);
      return data;
    } catch (error) {}
  },

  /**
   * Set data to the local storage
   *
   * @param {string} key Storage key
   * @param {*} value Storage data
   * @returns
   */
  setStore(key, value) {
    try {
      store.set(key, value);

      return true;
    } catch (error) {}
  },

  /**
   * Remove data from the local storage
   *
   * @param {string} key Storage key
   * @returns
   */
  removeStore(key) {
    try {
      return store.remove(key);
    } catch (error) {}
  }
};

export default localStorage;
