/**
 * Shows the detailed chart view
 *
 * @param  {redux state} state redux state
 * @param  {string || null} payload action payload from SHOW_DETAILED_CHART_VIEW
 */
export default (state, { payload }) => ({
  ...state,
  showDetailedChartViewFor: payload,
  startValue: null,
  endValue: null
});
