import * as React from 'react';
import styled from 'styled-components';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { Nav } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faTimes);

const MenuWrapper = styled.div`
  z-index: 1060;
`;

const MenuBackground = styled.div<any>`
  z-index: 1080;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  @media (min-width: 991.92px) {
    display: none;
  }

  // @ts-ignore
  ${({ hide }) =>
    hide &&
    `
      display: none;
    `}
`;

const MobileMenu = styled.div<any>`
  width: 300px;
  overflow: hidden;
  z-index: 1081;
  background-color: #ffffff;
  max-width: 100%;

  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -300px;

  display: flex;
  flex-direction: column;

  box-shadow: 1px 0px 4px ${props => props.theme.shadow};

  @media (min-width: 991.92px) {
    display: none;
  }

  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;

  // @ts-ignore
  ${({ isOpen }) =>
    isOpen &&
    ` 
      left: 0px;

      #_mdspcontent {
        overflow: hidden;
      }
    `}
`;

const Header = styled.div`
  height: 42px;
  line-height: 42px;
  text-align: center;
`;

const IconDiv = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  font-size: 18px;
`;

const lockBodyScroll = isOpen => {
  const contentElement = document.querySelector('#SceneWrapper');
  if (isOpen) {
    disableBodyScroll(contentElement);
  } else {
    enableBodyScroll(contentElement);
  }
};

/**
 * The side menu for mobile
 * @param  {Object} props.children the nav items to render
 * @param  {void} props.close function to close menu
 * @param  {boolean} props.isOpen show menu or collapse it
 */
export default props => {
  const { isOpen, close } = props;
  lockBodyScroll(isOpen);
  return (
    <MenuWrapper>
      <MenuBackground
        className="mobile-menu-bg"
        hide={!isOpen}
        onClick={close}
      />
      <MobileMenu className="mobile-menu" isOpen={isOpen}>
        <Nav vertical>
          <Header>
            <IconDiv onClick={close}>
              <FontAwesomeIcon icon={['far', 'times']} />
            </IconDiv>
          </Header>
          {props.children}
        </Nav>
      </MobileMenu>
    </MenuWrapper>
  );
};
