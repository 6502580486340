// import { formatChartDate } from '../formatter';

const validateMarkAreas = (markAreas, timeSeries) => {
  let validatedMarkAreas = markAreas;

  validatedMarkAreas.forEach(markArea => {
    if (markArea.length === 1) {
      //@ts-ignore
      const xAxis = Object.keys(timeSeries[timeSeries.length - 1])[0].value;

      // const start = markArea[0].xAxis;

      //@ts-ignore
      // const duration = moment(xAxis).diff(moment(start));

      // markArea[0].name = formatChartDate(duration);
      markArea[1] = {
        xAxis
      };
    }
  });

  return validatedMarkAreas;
};

const addMarkAreaStart = (xAxis, markAreas) => {
  return [
    ...markAreas,
    [
      {
        xAxis,
        name: markAreas.length === 0 ? 'Steuerung Ein' : ''
      }
    ]
  ];
};

const addMarkAreaEnd = (xAxis, markAreas) => {
  const currentArea = markAreas[markAreas.length - 1];
  // const start = currentArea[0].xAxis;
  // const duration = moment(xAxis).diff(moment(start));

  // currentArea[0].name = formatChartDate(duration);
  currentArea[1] = {
    xAxis
  };

  return markAreas;
};

const getMarkArea = series => {
  let markAreas = [];
  let hasStarted = false;

  series.forEach(entry => {
    const value = parseInt(entry.value);
    // add startpoint to markArea
    if (value === 1 && !hasStarted) {
      markAreas = addMarkAreaStart(entry.time, markAreas);

      hasStarted = true;
    }

    // add endpoint and name to area
    if (value === 0 && hasStarted) {
      markAreas = addMarkAreaEnd(entry.time, markAreas);

      hasStarted = false;
    }
  });

  return validateMarkAreas(markAreas, series);
};

export default getMarkArea;
