import { isUndefined } from 'lodash';

export const selectConfig = state => {
  const activeDasboard = state.core.cycle.tab;
  const configs = state.config;
  const activeConfig = configs.find(config => config.id === activeDasboard);
  if (isUndefined(activeConfig)) {
    return false;
  } else {
    return activeConfig;
  }
};
