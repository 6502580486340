import * as React from 'react';
import styled from 'styled-components';

const ListItem = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #ffffff;
`;

export default ({ children }) => <ListItem>{children}</ListItem>;
