import Status from './Status/container';
import ServiceLog from './ServiceLog/container';
import Events from './Events/container';
import NotFound from './NotFound';

export default {
  status: Status,
  serviceLog: ServiceLog,
  events: Events,
  notFound: NotFound
};
