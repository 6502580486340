export default (data, theme) => {
  const values: Array<number> = data.map(entry => entry.value);

  return {
    animationDuration: 0,
    radar: {
      // shape: 'circle',

      name: {
        textStyle: {
          color: theme.conterLight,
          fontfamily: 'Montserrat'
        }
      },
      indicator: [
        { name: 'Instandhaltung', max: 100 },
        { name: 'Rüstzeit', max: 100 },
        { name: 'Fehlervarianz', max: 100 },
        { name: 'Verschleiß', max: 100 },
        { name: 'Leistung', max: 100 }
      ]
    },
    tooltip: {},
    color: theme.main,
    series: [
      {
        name: 'Radar',
        type: 'radar',
        // areaStyle: { normal: {} },
        data: [
          {
            value: values,
            name: 'Maschinenrating:',
            areaStyle: {
              normal: {
                opacity: 0.8
              }
            }
          }
        ]
      }
    ]
  };
};
