import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrows } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { Nav } from 'reactstrap';
import NavItem from './components/NavItem';
import Loading from './components/Loading';
import getPath from '../../../../services/getPath';

library.add(faBars, faArrows);

const StyledNav = styled(Nav)`
  position: fixed;
  left: 100px;
  right: 0px;
  overflow: hidden;

  order: 1;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 1px 0px 4px ${props => props.theme.shadow};
  padding: 0px 20px;

  z-index: 1020;

  ${({ offsetleft = 100 }) =>
    `
      left: ${offsetleft}px;
  `}

  @media (max-width: 991.98px) {
    left: 100px;
  }

  @media (max-width: 991.92px) {
    display: none !important;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
`;

const DragAndDropIcon = styled.div<any>`
  position: absolute;
  height: 80px;
  line-height: 80px;
  right: 30px;
  font-size: 24px;
  color: ${props => props.theme.conterLight};

  svg {
    cursor: pointer;
  }

  // @ts-ignore
  ${props =>
    //@ts-ignore
    props.active &&
    `
      color: ${props.theme.main};
  `}
`;

/**
 * The Layout component with vertical and horizontal navbars for mobil and desktop view
 * @param  {Object} props.location location from redux router
 * @param  {Object} props.match match from redux router
 * @param  {Object} props.history histrory from redux router
 * @param  {Array<Assets>} props.assets Array of all assets to render buttons
 * @param  {string} props.name name of scene (to show "all" button on events scene)
 * @param  {string} props.url currenr url
 * @param  {void} props.toggleDragAndDrop function to toggle drag and drop
 */
const HorizontalMenu = ({
  tabs = [],
  location,
  match,
  offsetleft,
  url,
  showDragAndDrop,
  toggleDragAndDrop,
  dragAndDropEnabled
}) => {
  const path = getPath(location);
  const showLoading = tabs.length === 0;
  const currentTabId = match.params.tabId;

  return (
    <StyledNav offsetleft={offsetleft}>
      <Tabs>
        {showLoading && <Loading />}
        {tabs.map(tab => {
          const urlTo = `${path}/${tab.id}`;
          return (
            <NavItem
              active={tab.id === currentTabId}
              to={urlTo}
              currentPath={path}
              currentUrl={url}
              name={tab.title}
              key={tab.id}
            />
          );
        })}
      </Tabs>
      {showDragAndDrop && (
        // @ts-ignore
        <DragAndDropIcon
          onClick={() => toggleDragAndDrop()}
          active={dragAndDropEnabled}
        >
          <FontAwesomeIcon icon={['far', 'arrows']} />
        </DragAndDropIcon>
      )}
    </StyledNav>
  );
};

export default HorizontalMenu;
