import * as React from 'react';
import getOptions from './services/getChartOption';
import ReactEcharts from 'echarts-for-react';
import { withTheme } from 'styled-components';

class RadarChart extends React.Component<any, any> {
  render() {
    const { data, theme } = this.props;

    let options = getOptions(data, theme);

    return (
      //@ts-ignore
      <ReactEcharts
        option={options}
        style={{ height: '400px', width: '100%' }}
      />
    );
  }
}

export default withTheme(RadarChart);
