import ApiClient from '../ApiClient';
import Solutions from '../Solutions';
import { Incident } from '../../../dataSources/sources/csServices/types';

export default class Incidents extends ApiClient {
  static async list() {
    try {
      return await this.get('incidents');
    } catch (e) {
      throw new Error('Störfälle konnten nicht geladen werden.');
    }
  }

  static async create(incident: Incident) {
    try {
      const {
        solutionTitle,
        solutionDescription,
        servicecaseId,
        description
      } = incident;

      const newIncident = await this.post('incidents', {
        description,
        servicecaseId,
        assetId: '123'
      });

      if (solutionTitle !== '' || solutionDescription !== '') {
        await Solutions.create({
          title: incident.solutionTitle,
          description: incident.solutionDescription,
          servicecaseId,
          incidentId: newIncident.id
        });
      }

      //@ts-ignore
      return newIncident;
    } catch (e) {
      throw new Error('Vorfall konnte nicht erstellt werden.');
    }
  }

  static async update(incident: Incident) {
    try {
      const {
        id,
        description,
        solutionId,
        solutionTitle,
        solutionDescription,
        servicecaseId
      } = incident;

      const updatedIncident = await this.put('incidents', id, {
        servicecaseId,
        assetId: '123',
        description
      });

      await Solutions.update({
        id: solutionId,
        title: solutionTitle,
        description: solutionDescription
      });

      //@ts-ignore
      return updatedIncident;
    } catch (e) {
      throw new Error('Vorfall konnte nicht aktualisiert werden.');
    }
  }

  static async delete(id) {
    try {
      return await this.del('incidents', id);
    } catch (e) {
      throw new Error('Vorfall konnte nicht gelöscht werden.');
    }
  }
}
