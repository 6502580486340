import { handleActions } from 'redux-actions';

const initialState = {
  id: '', //cycle id to prevent parallel running cycles,
  scene: '', //scene name
  tab: '', // tab id
  active: false // deactive cycle at invalid url (redirect, not found, etc..)
};

export default handleActions(
  {
    '@@router/LOCATION_CHANGE': (state, { payload }) => {
      const { pathname } = payload.location;

      // remove first "/" of string to prevent empty entry after split
      const [scene = '', tab = ''] = pathname.substr(1).split('/');

      // cycle shoud be active if scene and tab ist defined
      const active = scene !== '' && tab !== '';

      const id = active ? `${new Date().toISOString()}.${scene}.${tab}` : '';

      return { id, scene, tab, active };
    }
  },
  initialState
);
