import styled from 'styled-components';

export const ButtonRowForLog = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  top: -54px;
  width: 100%;

  @media (max-width: 991.92px) {
    top: 0px;
    margin-bottom: 30px;
    padding: 0px 20px;
  }
`;

export const ButtonRowForAdmin = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  top: -82px;
  width: 100%;

  @media (max-width: 991.92px) {
    top: -40px;
    margin-bottom: 30px;
    padding: 0px 20px;
  }
`;
