import * as React from 'react';
import styled from 'styled-components';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const StyledNavItem = styled(NavItem)`
  cursor: pointer;
  height: 80px;
  min-width: 60px;
  margin: 0px 20px;
  font-family: Montserrat Bold;
  font-size: 16px;
  s &:hover {
    .nav-link {
      color: ${props => props.theme.main};
    }
  }

  .nav-link {
    color: #b1b9ab;
    line-height: 80px;
    text-align: center;
    padding: 0px;
  }

  ${props =>
    props.active &&
    `      
      border-bottom-style: solid;
      border-bottom-color: ${props.theme.main};
      border-bottom-width: 2px;

      .nav-link {
        color: ${props.theme.main};
      }
  `}
`;

const HorizontalNavItem = props => {
  return (
    <StyledNavItem active={props.active}>
      <NavLink tag={Link} to={props.to}>
        {props.name}
      </NavLink>
    </StyledNavItem>
  );
};

export default HorizontalNavItem;
