import { toast } from 'react-toastify';

import Source from '../source';

//global types
import { FormattedData, GeneralRequestParameter } from '../sourceTypes';
import { SourceState } from '../../../globalTypes/state';
import { DashboardProperties, Action } from '../../../globalTypes/dashboard';

import csServicesApi from '../../../../core/api/cs-services';
import { isUndefined } from 'lodash';

class CsService extends Source {
  getId(dashboardProps) {
    const { itemName } = dashboardProps;
    return itemName;
  }

  getRequests(
    sourceState: SourceState,
    dashboardProps: Array<DashboardProperties>
  ): Array<GeneralRequestParameter> {
    const FAKE_DASHBOARD_PROPS = ['Incidents', 'Servicecases', 'Solutions'];
    return FAKE_DASHBOARD_PROPS.map((name /* props */) => ({
      itemName: name, //props.itemName
      reqRunning: false,
      firstLoad: true
    }));
  }

  async callMethodeForSaga(
    requestParams: GeneralRequestParameter
  ): Promise<FormattedData> {
    const { itemName } = requestParams;
    const items = await csServicesApi[itemName].list();

    return { itemName, data: items };
  }

  getRequestedReducer(state: SourceState, action: Action): SourceState {
    const requests: Array<GeneralRequestParameter> = action.payload;
    let newState = state;

    requests.forEach((request: GeneralRequestParameter) => {
      const item = newState[request.itemName];
      // If item is empty/undefined -> fill in initial structure
      if (isUndefined(item)) {
        newState = {
          ...newState,
          [request.itemName]: {
            data: [],
            firstLoad: true,
            reqRunning: true
          }
        };
        return {
          ...newState
        };
      }

      newState = {
        ...newState,
        [request.itemName]: {
          ...item,
          reqRunning: true
        }
      };
    });

    return {
      ...newState
    };
  }

  getSucceededReducer(state: SourceState, action: Action) {
    const { itemName, data } = action.payload;
    const item = state[itemName];
    let newState = state;

    const sortedData = data.sort((itemA, itemB) => {
      if (itemA.createdAt > itemB.createdAt) return -1;
      if (itemA.createdAt > itemB.createdAt) return 1;

      return 0;
    });

    newState = {
      ...newState,
      [itemName]: {
        ...item,
        reqRunning: false,
        data: sortedData
      }
    };

    return {
      ...newState
    };
  }

  async createMethodeForSaga(action: GeneralRequestParameter) {
    const { payload } = action;
    const { data, itemName } = payload;
    await csServicesApi[itemName].create(data);
    toast.success('Vorfall wurde erfolgreich erstellt');
    return;
  }

  createSucceededReducer(state: SourceState, action: Action): SourceState {
    return state;
  }

  createFailedReducer(state: SourceState, action: Action): SourceState {
    return state;
  }

  async deleteMethodeForSaga(action: GeneralRequestParameter) {
    const { payload } = action;
    const { data, itemName } = payload;
    await csServicesApi[itemName].delete(data.id);
    return;
  }

  deleteSucceededReducer(state: SourceState, action: Action): SourceState {
    return state;
  }

  deleteFailedReducer(state: SourceState, action: Action): SourceState {
    return state;
  }

  async updateMethodeForSaga(action: GeneralRequestParameter) {
    const { payload } = action;
    const { data, itemName } = payload;
    await csServicesApi[itemName].update(data);
    return;
  }

  updateSucceededReducer(state: SourceState, action: Action): SourceState {
    return state;
  }

  updateFailedReducer(state: SourceState, action: Action): SourceState {
    return state;
  }
}

const csServices = new CsService();
export default csServices;
