import * as React from 'react';
import styled from 'styled-components';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input
} from 'reactstrap';

const NewServicecaseModal = styled(Modal)`
  max-width: 700px;
`;

const Header = styled(ModalHeader)`
  background-color: ${props => props.theme.background};
  font-family: Montserrat Bold;
  border: none;
`;

const Body = styled(ModalBody)`
  padding: 0px 30px;
`;

const Footer = styled(ModalFooter)`
  padding: 20px 30px;
`;

const Group = styled.div`
  padding: 20px 0px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e9e9ec;

  &&:last-child {
    border-bottom: none;
  }
`;

const GroupRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  width: 50%;
  margin-right: 20px;
`;

const Heading = styled.p`
  font-family: Montserrat Bold;
`;

const AddButton = styled(Button)`
  height: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.main};
  color: ${props => props.theme.mainLight};
  background-color: ${props => props.theme.main};

  &:hover {
    background-color: ${props => props.theme.mainLight};
    color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }
`;

const CancelButton = styled(Button)`
  height: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.main};
  background-color: ${props => props.theme.mainLight};
  color: ${props => props.theme.main};
  margin-left: auto;
  margin-right: 20px;

  &:hover {
    color: ${props => props.theme.mainLight};
    background-color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }
`;

export default ({
  isOpen,
  close,
  edit,
  id,
  title,
  servicecaseType,
  servicecaseNumber,
  save
}) => {
  return (
    <NewServicecaseModal
      isOpen={isOpen}
      toggle={close}
      className={'detail-modal'}
    >
      <Header toggle={close}>Vorfallstyp</Header>
      <Body>
        <Group>
          <Heading>Title</Heading>
          <Input
            onChange={e => {
              edit('title', e.target.value);
            }}
            value={title}
          />
        </Group>
        <Group>
          <GroupRow>
            <Col>
              <Heading>Typ</Heading>
              <Input
                type="select"
                onChange={e => {
                  edit('servicecaseType', e.target.value);
                }}
                value={servicecaseType}
              >
                <option>Elektrisch</option>
                <option>Mechanisch</option>
                <option>Pneumatisch</option>
              </Input>
            </Col>
            <Col>
              <Heading>Nummer</Heading>
              <Input
                onChange={e => {
                  edit('servicecaseNumber', e.target.value);
                }}
                value={servicecaseNumber}
              ></Input>
            </Col>
          </GroupRow>
        </Group>
      </Body>
      <Footer>
        <CancelButton onClick={close}>Abbrechen</CancelButton>
        <AddButton
          onClick={() =>
            save({ id, title, servicecaseType, servicecaseNumber })
          }
        >
          Speichern
        </AddButton>
      </Footer>
    </NewServicecaseModal>
  );
};
