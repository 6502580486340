import * as React from 'react';
import styled from 'styled-components';
import {
  faCube,
  faClock,
  faMapMarkerAlt,
  faSync
} from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import Tile from '../../../Tile';
import TableElement from '../../../Tile/components/TableElement';
import eventLevel from '../../../../services/eventTypeEnum';
import moment from 'moment';
import { isUndefined } from 'lodash';

library.add(faCube, faClock, faMapMarkerAlt, faSync);

const Content = styled.div`
  display: flex;
  flex-direction: row;

  height: 100%;
`;

/** Page Content */
class Table extends React.Component<any, any> {
  formatTableElement(events) {
    if (events.length === 0) return {};
    const event = events[0];
    const elm = {
      header: event.description.split('//')[0],
      subHeader: eventLevel[event.severity].label,
      dotColor: eventLevel[event.severity].color,
      time: moment(event.timestamp).format('hh:mm DD.MM.YYYY'),
      text: event.description.split('//')[1] || '-'
    };

    return elm;
  }

  render() {
    const { config, data } = this.props;

    return (
      <Tile header={config.title} {...this.props}>
        <Content>
          {isUndefined(data[0]) ? (
            <div></div>
          ) : (
            <TableElement
              event={this.formatTableElement(data[0])}
            ></TableElement>
          )}
        </Content>
      </Tile>
    );
  }
}

export default Table;
