//@ts-ignore-file
import * as React from 'react';

import ReactEcharts from 'echarts-for-react';

// const handleChartDataZoom = (_ref, onDataZoom) => {
//   // return id component did not mount already
//   if (_ref === null) {
//     return;
//   }

//   //get echart object
//   const echart = _ref.getEchartsInstance().getModel();

//   //get index of selected start and end date
//   const { startValue, endValue } = echart.getOption().dataZoom[0];

//   onDataZoom(startValue, endValue);
// };

export default class Chart extends React.Component<any, any> {
  _ref = null;
  constructor(props) {
    super(props);
    this._ref = null;
  }

  /** Block react rerender after inital render and updates the echart directly */
  shouldComponentUpdate(nextProps) {
    if (this._ref === null) return true;

    const echartInstance = this._ref.getEchartsInstance();
    echartInstance.setOption(nextProps.option);

    return false;
  }

  render() {
    const { option, style } = this.props;

    return (
      //@ts-ignore
      <ReactEcharts
        option={option}
        style={style}
        // onEvents={{
        //   dataZoom: () => handleChartDataZoom(this._ref, onDataZoom)
        // }}
        ref={e => {
          this._ref = e;
        }}
      />
    );
  }
}
