
import * as React from 'react';
import styled from 'styled-components';

const Loading = styled.li`
  height: 42px;
  color: #b1b9ab
  padding-left: 26px;

  line-height: 42px;
  font-size: 12px;
  font-family: Montserrat Bold;
  vertical-align: middle;

  border-top-color: #e3e6e1;
  border-top-style: solid;
  border-top-width: 1px;
`;

/**
 * The menu item that renders if the assets are currently loading
 */
export default () => <Loading>Loading...</Loading>;
