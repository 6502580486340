import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Pagination from '../index';
import { selectPage } from '../actions';
import { getEventsPagination } from '../../../../../selectors/eventsMSP';

const mapStateToProps = (state, ownProps) => ({
  pagination: getEventsPagination(state)
});

const mapDispatchToProps = dispatch => ({
  selectPage: page =>
    dispatch(
      selectPage([
        {
          reqRunning: false,
          from: null,
          to: null,
          firstLoad: false,
          page
        }
      ])
    )
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Pagination)
);
