import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'reactstrap';

const GreenButton = styled<any>(Button)`
  width: 96px;
  height: 34px;
  color: ${props => props.theme.conterLight};
  border-color: #e3e6e1;
  border-style: solid;
  border-width: 1px;
  border-radius: 17px;
  background-color: #ffffff;
  font-size: 12px;
  font-family: Montserrat Bold;
  margin-right: 10px;

  &:hover {
    color: #ffffff;
    background-color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }

  ${props =>
    props.active &&
    `
    color: #ffffff;
    background-color: ${props.theme.main} !important;
    border-color: ${props.theme.main} !important;
  `}
`;

export default ({ children, active, onClick }) => (
  //@ts-ignore
  <GreenButton active={active} onClick={onClick}>
    {children}
  </GreenButton>
);
