import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ActionBar from '../index';
import { selectFilter, ackEventsRequested } from '../actions';
import { getEventsFilter } from '../../../../../selectors/eventsMSP';

const mapStateToProps = (state, ownProps) => ({
  selectedEvents: state.scenes.events.selectedEvents,
  filter: getEventsFilter(state),
  assetId: ownProps.match.params.assetId
});

const mapDispatchToProps = dispatch => ({
  selectFilter: (scope, value, filter) =>
    dispatch(
      selectFilter([
        {
          reqRunning: false,
          from: null,
          to: null,
          firstLoad: false,
          filter: { ...filter, [scope]: value }
        }
      ])
    ),
  ackEvents: selectedEvents =>
    dispatch(ackEventsRequested({ data: selectedEvents }))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ActionBar)
);
