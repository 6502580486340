import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LineChart from '../index';

import { selectData } from '../../../../../selectors/TileDataSelector';

const mapStateToProps = (state, ownProps) => {
  const data = selectData(state, ownProps);

  return {
    data
  };
};

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LineChart)
);
