import * as React from 'react';
import tiles from '../../../components/Tiles/core';
import styled from 'styled-components';
import { isUndefined } from 'lodash';

const Tile = styled.div`
  //background-color: #f0932b;
  background-color: #ffffff
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px ${props => props.theme.shadow};
  touch-action: auto;
`;
export default class TileBuilder {
  static build(tileConfigurations, dashboardSize, theme) {
    if (isUndefined(tileConfigurations)) return <div></div>;

    let elements = tileConfigurations.map(tile => {
      const Element = tiles[tile.type];
      if (isUndefined(Element)) {
        console.error(`Tile with type "${tile.type}" was not found.`);
        return null;
      }
      return (
        <Tile key={tile.id}>
          <Element
            gridHeight={tile.size.h}
            gridWidth={tile.size.w}
            config={tile}
            size={dashboardSize}
            // theme={theme}
          />
        </Tile>
      );
    });

    return elements;
  }
}
