import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  severity: 'all',
  acknowledged: false
};

export default handleActions(
  {
    [types.SELECT_FILTER]: (state, { payload }) => ({
      ...state,
      [payload.scope]: payload.value
    })
  },
  initialState
);
