import * as React from 'react';
import styled from 'styled-components';
import { NavItem, NavLink } from 'reactstrap';

const StyledNavItem = styled(NavItem)`
  cursor: pointer;
  height: 80px;

  border-bottom-color: #e3e6e1;
  border-bottom-style: solid;    
  border-bottom-width: 1px;

  &:hover {
    background-color: ${props => props.theme.mainLight};
  }

  border-left-style: solid;
  border-left-color: #ffffff;
  border-left-width: 4px;

  .nav-link {
    color: #b1b9ab;
    height: 100%
    font-size: 14px;
    font-family: Montserrat Bold;
    text-align: center;
  }

  
  ${props =>
    props.active &&
    `
    border-left-style: solid;
    border-left-color: ${props.theme.main};
    border-left-width: 4px;
    background-color: ${props => props.theme.mainLight};

    .nav-link {
      color: ${props.theme.main};
    }
  `}
`;

const IconContainer = styled.div`
  margin-top: 4px;
  font-size: 22px;
  //@ts-ignore
  color: ${props =>
    //@ts-ignore
    props.active ? props.theme.main : props.theme.conterLight};
`;

const NavText = styled.p`
  //@ts-ignore
  color: ${props =>
    //@ts-ignore
    props.active ? props.theme.main : props.theme.conterLight};
`;

/**
 * The nav items for the vertical navbar
 * @param  {string} props.name name of scene
 * @param  {string} props.icon fa icon name
 * @param  {boolean} props.path path to link to
 * @param  {void} prop.currentPath current path
 */
const ExoNavItem = props => {
  return (
    <StyledNavItem active={props.currentPath === props.path}>
      <NavLink onClick={() => props.onClick()}>
        {true && (
          //@ts-ignore
          <IconContainer active={props.currentPath === props.path}>
            {props.icon}
          </IconContainer>
        )}

        {true && (
          //@ts-ignore
          <NavText active={props.currentPath === props.path}>
            {props.name}
          </NavText>
        )}
      </NavLink>
    </StyledNavItem>
  );
};

export default ExoNavItem;
