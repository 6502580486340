import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LabeledDot from '../../../Tile/components/LabeledDot';
import MACHINE_STATE from '../../../../services/core/enums/machineState';
import Tile from '../../../Tile';
import IconWithText from '../../../Tile/components/IconWithText';
import { isUndefined } from 'lodash';

const Content = styled.div`
  display: flex;
  flex-direction: row;

  height: 100%;
`;

const Info = styled.div`
  height: 100%;
  width: 50%;
`;
// const Image = styled.div`
//   height: 100%;
//   width: 50%;

//   pointer-events: none;
// `;

/** Page Content */
class InfoIcons extends React.Component<any, any> {
  render() {
    const { config, data } = this.props;

    return (
      <Tile
        header={config.title}
        subTitle={
          typeof data[0] !== 'undefined' && (
            <LabeledDot
              color={MACHINE_STATE[data[0]].color}
              label={MACHINE_STATE[data[0]].label}
            />
          )
        }
        {...this.props}
      >
        <Content>
          <Info>
            <IconWithText
              //@ts-ignore
              icon={
                <FontAwesomeIcon
                  icon={[
                    config.data[1].properties.icon.split(' ')[0],
                    config.data[1].properties.icon.split(' ')[1]
                  ]}
                />
              }
              upperText={config.data[1].properties.displayName}
              lowerText={`${data[1] || '-'}${config.data[1].properties.unit ||
                ''}`}
            />
            <IconWithText
              icon={
                <FontAwesomeIcon
                  icon={[
                    config.data[3].properties.icon.split(' ')[0],
                    config.data[3].properties.icon.split(' ')[1]
                  ]}
                />
              }
              upperText={config.data[3].properties.displayName}
              lowerText={`${isUndefined(data[3]) ? '-' : data[3]}${config
                .data[3].properties.unit || ''}`}
            />
          </Info>
          <Info>
            <IconWithText
              icon={
                <FontAwesomeIcon
                  icon={[
                    config.data[2].properties.icon.split(' ')[0],
                    config.data[2].properties.icon.split(' ')[1]
                  ]}
                />
              }
              upperText={config.data[2].properties.displayName}
              lowerText={`${data[2] || '-'}${config.data[2].properties.unit ||
                ''}`}
            />
            <IconWithText
              icon={
                <FontAwesomeIcon
                  icon={[
                    config.data[4].properties.icon.split(' ')[0],
                    config.data[4].properties.icon.split(' ')[1]
                  ]}
                />
              }
              upperText={config.data[4].properties.displayName}
              lowerText={`${isUndefined(data[4]) ? '-' : data[4]}${config
                .data[4].properties.unit || ''}`}
            />
          </Info>
        </Content>
      </Tile>
    );
  }
}

export default InfoIcons;
