import * as React from 'react';
import styled from 'styled-components';

import Layout from '../../components/Layout/containers/LayoutContainer';
import NewIncidentButton from '../../components/Service/NewIncidentButton';
import NewServicecaseButton from '../../components/Service/NewServicecaseButton';
import AdminButton from '../../components/Service/AdminButton';
import {
  ButtonRowForLog,
  ButtonRowForAdmin
} from '../../components/Service/ButtonRow';

import IncidentList from '../../components/Service/IncidentList/container';
import IncidentDetailModal from '../../components/Service/IncidentDetailModal/container';
import IncidentModal from '../../components/Service/IncidentModal/container';
import ServicecaseList from '../../components/Service/ServicecaseList/container';
import ServicecaseModal from '../../components/Service/ServicecaseModal/container';
import DeleteModal from '../../components/Service/DeleteModal/container';

const ListDiv = styled.div`
  width: 100%;

  @media (max-width: 991.92px) {
    margin-top: 30px;
    padding: 0px 20px;
  }
`;

const AdminListDiv = styled.div`
  width: 100%;

  @media (max-width: 991.92px) {
    padding: 0px 20px;
  }
`;

/** Page Content */
const ServiceLogScene = props => {
  const {
    displayScene,
    changeDisplayScene,
    openIncidentModal,
    openServicecaseModal
  } = props;

  if (displayScene === 'log') {
    return (
      <Layout
        name={'Service Logs'}
        showHorizontalMenu
        layout={this.props.layout}
        {...props}
      >
        <ButtonRowForLog>
          <AdminButton onClick={() => changeDisplayScene('admin')} />
          <NewIncidentButton onClick={() => openIncidentModal()} />
        </ButtonRowForLog>
        <ListDiv>
          <IncidentList />
        </ListDiv>
        <IncidentDetailModal />
        <IncidentModal />
        <ServicecaseModal />
        <DeleteModal />
      </Layout>
    );
  }

  if (displayScene === 'admin') {
    return (
      <Layout
        name={'Service Administration'}
        //@ts-ignore
        breadcrums={[
          {
            text: '< Zurück',
            onClick: () => changeDisplayScene('log')
          }
        ]}
        showHorizontalMenu
        {...props}
      >
        <ButtonRowForAdmin>
          <NewServicecaseButton onClick={() => openServicecaseModal()} />
        </ButtonRowForAdmin>
        <AdminListDiv>
          <ServicecaseList />
        </AdminListDiv>
        <ServicecaseModal />
        <DeleteModal />
      </Layout>
    );
  }

  return <Layout />;
};

export default ServiceLogScene;
