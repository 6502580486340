import { createAction } from 'redux-actions';
import types from '../types';

export const openIncidentModal = createAction(types.OPEN_MODAL);

export const closeIncidentModal = createAction(types.CLOSE_MODAL);

export const saveIncident = createAction(types.CREATE_REQUESTED);

export const editIncident = createAction(types.EDIT_INCIDENT);
