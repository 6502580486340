import { isUndefined, uniq } from 'lodash';
import Source from '../source';
import mindConnector from '../../../api/mindsphere';
import DATA_VALUES from '../../../enums/dataValues';

//global types
import { SourceState } from '../../../globalTypes/state';
import { DashboardProperties, Action } from '../../../globalTypes/dashboard';
import { TimeseriesResponse } from '../../../api/mindsphere/mindesphere';

//source types
import {
  TimeseriesRequestParameter,
  FormattedData,
  FormattedDataParameter
} from '../sourceTypes';
// Use require for unit tests
let moment = require('moment');

class TimeseriesMSP extends Source {
  /**
   * Collect all request which are required for the data
   *
   * @param {SourceState} sourceState
   * @param {Array<DashboardProperties>} dashboardProps
   * @returns {Array<TimeseriesRequestParameter>}
   * @memberof TimeseriesMSP
   */
  getRequests(
    sourceState: SourceState,
    dashboardProps: Array<DashboardProperties>
  ): Array<TimeseriesRequestParameter> {
    let requests: Array<TimeseriesRequestParameter> = [];
    let combinedRequests: Array<TimeseriesRequestParameter> = [];

    if (Object.keys(sourceState).length === 0) {
      requests = this._buildRequests(dashboardProps, undefined);
    } else {
      requests = this._buildRequests(dashboardProps, sourceState);
    }

    combinedRequests = this._combineRequests(requests);

    return combinedRequests;
  }

  /**
   * Build an id to access from the global state to the data
   *
   * @param {DashboardProperties} dashboardProps
   * @returns {string}
   * @memberof TimeseriesMSP
   */
  getId(dashboardProps: DashboardProperties): string {
    const { asset, aspect, property } = dashboardProps;
    const id: string = `${asset}.${aspect}.${property}`;
    return id;
  }

  /**
   *
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns
   * @memberof TimeseriesMSP
   */
  getRequestedReducer(state: SourceState, action: Action) {
    let requests: Array<TimeseriesRequestParameter> = action.payload;
    let newState = state;

    // If state is empty -> fill in initial structure
    if (Object.keys(newState).length === 0) {
      requests.forEach((request: TimeseriesRequestParameter) => {
        //@ts-ignore
        newState = {
          ...newState,
          ...this._formatData(request, [])
        };
      });
    }

    requests.forEach((request: TimeseriesRequestParameter) => {
      request.ids.forEach(id => {
        newState[id] = {
          ...newState[id],
          reqRunning: true
        };
      });
    });

    return {
      ...newState
    };
  }

  /**
   * Reducer for get TimeseriesMSP succeeded action
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns
   * @memberof TimeseriesMSP
   */
  getSucceededReducer(state: SourceState, action: Action) {
    let { formattedData, requestParams } = action.payload;

    const mergedState: SourceState = this._merge(
      state,
      formattedData,
      requestParams
    );
    return {
      ...mergedState
    };
  }

  /**
   * Gets called in the "get requested" saga.
   * Requests data and format it to aggregate source state format
   *
   * @param {TimeseriesRequestParameter} requestParams
   * @returns {Promise<FormattedData>}
   * @memberof TimeseriesMSP
   */
  async callMethodeForSaga(
    requestParams: TimeseriesRequestParameter
  ): Promise<{
    formattedData: FormattedData;
    requestParams: TimeseriesRequestParameter;
  }> {
    const validParams: TimeseriesRequestParameter = this._validateTimespan(
      requestParams
    );
    const data: Array<TimeseriesResponse> = await mindConnector.getTimeseries(
      validParams
    );
    const updatedParams: TimeseriesRequestParameter = this._getNewFromDate(
      data,
      validParams
    );
    const formattedData: FormattedData = this._formatData(updatedParams, data);

    return { formattedData, requestParams };
  }

  _getNewFromDate(
    data: Array<TimeseriesResponse>,
    params: TimeseriesRequestParameter
  ) {
    if (data.length === 0) return params;

    const lastEntry: TimeseriesResponse = data[data.length - 1];

    const updatedParams: TimeseriesRequestParameter = {
      ...params,
      from: lastEntry._time
    };
    return updatedParams;
  }

  /**
   * Merge old and new state together
   *
   * @param {SourceState} state
   * @param {SourceState} newState
   * @returns {SourceState}
   * @memberof TimeseriesMSP
   */
  _merge(
    state: SourceState,
    newState: SourceState,
    req: TimeseriesRequestParameter
  ): SourceState {
    let merged: SourceState = { ...state };
    if (Object.keys(state).length === 0) {
      return newState;
    }

    Object.keys(newState).forEach(entry => {
      if (entry in merged) {
        merged[entry].from = newState[entry].from;
        merged[entry].to = newState[entry].to;
        merged[entry].reqRunning = false;
        merged[entry].firstLoad = false;
        merged[entry].data =
          // Difference between last and series values
          req.valueType === 0
            ? // If new last value is empty then keep old one
              newState[entry].data.length === 0
              ? merged[entry].data
              : newState[entry].data
            : [...merged[entry].data, ...newState[entry].data];
      } else {
        merged[entry] = newState[entry];
      }
    });

    return merged;
  }

  /**
   * Format MSP response data to state format
   *
   * @param {TimeseriesRequestParameter} requestParams
   * @param {Array<TimeseriesResponse>} data
   * @returns {FormattedData}
   * @memberof TimeseriesMSP
   */
  _formatData(
    requestParams: TimeseriesRequestParameter,
    data: Array<TimeseriesResponse>
  ): FormattedData {
    const { asset, aspect } = requestParams;

    let formattedData: FormattedData = {};
    // recipename: "TRF-102"
    // recipename_qc: 0
    // _time: "2019-09-17T22:08:56.612Z"
    data.forEach(entry => {
      Object.keys(entry).forEach(key => {
        if (
          !key.includes('_time') &&
          !key.includes('_qc')
          // entry[`${key}_qc`] === 1
        ) {
          const id: string = `${asset}.${aspect}.${key}`;

          //Entry already exists
          if (id in formattedData) {
            if (requestParams.valueType === DATA_VALUES.last) {
              formattedData[id]['data'] = [
                {
                  value: entry[key],
                  time: entry._time
                }
              ];
            } else {
              formattedData[id]['data'].push({
                value: entry[key],
                time: entry._time
              });
            }

            //Create new formatted data entry
          } else if (Object.keys(entry).length !== 0) {
            formattedData[id] = this._getStateStructure(requestParams, [
              {
                value: entry[key],
                time: entry._time
              }
            ]);
          }
        }
      });
    });

    formattedData = this._formatEmptyRequestParameter(
      requestParams,
      formattedData
    );

    return formattedData;
  }

  /**
   * Update from & to parameter of an request which was
   * returned from MSP with no data
   *
   * @param {TimeseriesRequestParameter} requestParams
   * @param {FormattedData} formattedData
   * @returns {FormattedData}
   * @memberof TimeseriesMSP
   */
  _formatEmptyRequestParameter(
    requestParams: TimeseriesRequestParameter,
    formattedData: FormattedData
  ): FormattedData {
    const { asset, aspect } = requestParams;
    // Update datapoint request parameter(from, to,...) with no return values
    requestParams.dataPoints.forEach(dp => {
      const id = `${asset}.${aspect}.${dp}`;
      if (!(id in formattedData)) {
        const data = [];
        formattedData[id] = this._getStateStructure(requestParams, data);
      }
    });
    return formattedData;
  }

  /**
   * Adjusts the date range so that first the last hour and
   * then every last minute of data is fetched
   *
   * @param {TimeseriesRequestParameter} params
   * @returns {TimeseriesRequestParameter}
   * @memberof TimeseriesMSP
   */
  _validateTimespan(
    params: TimeseriesRequestParameter
  ): TimeseriesRequestParameter {
    const firstLoad: boolean = params.firstLoad;

    if (firstLoad) {
      params.to = moment()
        .utc()
        .format();
      params.from = moment()
        .subtract(1, 'hour')
        .utc()
        .format();
      params.firstLoad = false;
    } else {
      params.from = moment(params.from)
        .subtract(10, 'second')
        .utc()
        .format();
      params.to = moment()
        .subtract(10, 'second')
        .utc()
        .format();
    }

    return params;
  }

  /**
   * Build request
   *
   * @param {Array<DashboardProperties>} dashboardProps
   * @param {SourceState} sourceState
   * @returns {Array<TimeseriesRequestParameter>}
   * @memberof TimeseriesMSP
   */
  _buildRequests(
    dashboardProps: Array<DashboardProperties>,
    sourceState: SourceState
  ): Array<TimeseriesRequestParameter> {
    let requests: Array<TimeseriesRequestParameter> = [];

    dashboardProps.forEach(prop => {
      const id: string = `${prop.asset}.${prop.aspect}.${prop.property}`;

      const params: TimeseriesRequestParameter = this._getRequestStructure(
        id,
        prop,
        sourceState
      );

      // If type series and lastvalue for the same id are requested then
      // only request the series
      let foundRequest = requests.find(
        request => request.ids[0] === params.ids[0]
      );
      if (!isUndefined(foundRequest)) {
        let index = requests.indexOf(foundRequest);
        if (params.valueType === DATA_VALUES.series) {
          requests[index].valueType = 1;
        }
      } else {
        requests.push({
          ...params
        });
      }
    });

    return requests;
  }

  /**
   * Template for request format
   *
   * @param {string} id
   * @param {DashboardProperties} meta
   * @param {SourceState} sourceState
   * @returns {TimeseriesRequestParameter}
   * @memberof TimeseriesMSP
   */
  _getRequestStructure(
    id: string,
    meta: DashboardProperties,
    sourceState: SourceState
  ): TimeseriesRequestParameter {
    return {
      ids: [id],
      asset: meta.asset,
      aspect: meta.aspect,
      dataPoints: [meta.property],
      from: sourceState ? sourceState[id].from : null,
      to: sourceState ? sourceState[id].to : null,
      valueType: meta.valueType,
      reqRunning: sourceState ? sourceState[id].reqRunning : false,
      firstLoad: sourceState ? sourceState[id].firstLoad : true
    };
  }

  /**
   * Template for state format
   *
   * @param {TimeseriesRequestParameter} meta
   * @param {Array<any>} [data=[]]
   * @returns {FormattedDataParameter}
   * @memberof TimeseriesMSP
   */
  _getStateStructure(
    meta: TimeseriesRequestParameter,
    data: Array<any> = []
  ): FormattedDataParameter {
    return {
      from: meta.from,
      to: meta.to,
      firstLoad: meta.firstLoad,
      reqRunning: meta.reqRunning,
      data
    };
  }

  /**
   * Combine requests which have the same asset,aspect
   *
   * @param {Array<TimeseriesRequestParameter>} requests
   * @returns {Array<TimeseriesRequestParameter>}
   * @memberof TimeseriesMSP
   */
  _combineRequests(
    requests: Array<TimeseriesRequestParameter>
  ): Array<TimeseriesRequestParameter> {
    let combinedRequests: Array<TimeseriesRequestParameter> = [];

    requests.forEach(dpRequest => {
      const existingDpRequestIndex: number = this._isDataPointRequestInCombinedRequests(
        dpRequest,
        combinedRequests
      );

      if (existingDpRequestIndex === -1) {
        combinedRequests.push(dpRequest);
      } else {
        combinedRequests = this._updateRequest(
          combinedRequests,
          existingDpRequestIndex,
          dpRequest
        );
      }
    });

    return combinedRequests;
  }

  /**
   * Check if requests have the same asset,aspect
   *
   * @param {TimeseriesRequestParameter} dpRequest
   * @param {Array<TimeseriesRequestParameter>} combinedRequests
   * @returns {TimeseriesRequestParameter}
   * @memberof TimeseriesMSP
   */
  _isDataPointRequestInCombinedRequests(
    dpRequest: TimeseriesRequestParameter,
    combinedRequests: Array<TimeseriesRequestParameter>
  ): number {
    return combinedRequests.findIndex(
      request =>
        request.asset === dpRequest.asset &&
        request.aspect === dpRequest.aspect &&
        request.valueType === dpRequest.valueType
    );
  }

  /**
   * Update an request if it can be combined with another one
   *
   * @param {Array<TimeseriesRequestParameter>} combinedRequests
   * @param {TimeseriesRequestParameter} existingDpRequest
   * @param {TimeseriesRequestParameter} dpRequest
   * @returns {Array<TimeseriesRequestParameter>}
   * @memberof TimeseriesMSP
   */
  _updateRequest(
    combinedRequests: Array<TimeseriesRequestParameter>,
    existingDpRequestIndex: number,
    dpRequest: TimeseriesRequestParameter
  ): Array<TimeseriesRequestParameter> {
    let updatedRequests: Array<TimeseriesRequestParameter> = [
      ...combinedRequests
    ];

    const i = existingDpRequestIndex;
    // Merge datapoits together an deduplicate
    updatedRequests[i].dataPoints = uniq([
      ...updatedRequests[i].dataPoints,
      ...dpRequest.dataPoints
    ]);
    // Merge ids together
    updatedRequests[i].ids = uniq([
      ...updatedRequests[i].ids,
      ...dpRequest.ids
    ]);
    return updatedRequests;
  }
  /**
   * Update running state of an request
   *
   * @param {SourceState} state
   * @param {Array<TimeseriesRequestParameter>} requests
   * @param {boolean} reqRunningState
   * @returns {SourceState}
   * @memberof DataSource
   */
}

const timeseriesMSP = new TimeseriesMSP();
export default timeseriesMSP;
