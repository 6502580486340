import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { List, ListItem, Row, Cell } from '../../List';
import { formatToDispalyDate } from '../../../services/utils';

library.add(faUser);

const getStatusColor = incident => {
  if (typeof incident.solution === 'undefined') {
    return '#ff4318';
  } else {
    return '#4cd137';
  }
};

// to compine to cells in one row on mobile
const CellRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default class ServiceList extends React.Component<any, any> {
  render() {
    const { showDetailView, incidents } = this.props;

    return (
      <List>
        {incidents.map(incident => (
          <ListItem key={incident.id}>
            <Row>
              <CellRow>
                <Cell type={'dot'} color={getStatusColor(incident)} />{' '}
                <Cell
                  type={'text'}
                  textStyle={{
                    bold: true
                  }}
                >
                  {incident.servicecase && incident.servicecase.title}
                </Cell>
              </CellRow>
              <Cell type={'text'}>
                {incident.servicecase && incident.servicecase.servicecaseType}
              </Cell>
              <Cell type={'dot'} color={'#212529'} size={'4px'} hideOnMobile />
              <Cell type={'text'}>
                {formatToDispalyDate(incident.createdAt)}
              </Cell>
              <Cell type={'text'} pushToRight>
                <FontAwesomeIcon icon={['far', 'user']} />
                <span
                  style={{
                    paddingRight: '10px'
                  }}
                ></span>
                {incident.reporter}
              </Cell>
            </Row>
            <Row>
              <Cell type={'text'}>{incident.description}</Cell>
              <Cell
                type={'button'}
                onClick={() => {
                  showDetailView(incident.id);
                }}
                pushToRight
              >
                Mehr Anzeigen
              </Cell>
            </Row>
          </ListItem>
        ))}
      </List>
    );
  }
}
