import * as React from 'react';
import styled from 'styled-components';
import DonutChartElement from '../../../Tile/components/DonutChartElement';
import Tile from '../../../Tile';

const Content = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 20px;
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    pointer-events: none;
  }
`;

class DonutChart extends React.Component<any, any> {
  render() {
    const { config, data } = this.props;
    // const data = [
    //   { name: 'Motorschutz ”-F12” ausgelöst', value: 100 },
    //   { name: 'Motorschutz ausgelöst “-F25”', value: 50 },
    //   { name: 'Sammelstörung “-FU5”', value: 20 },
    //   { name: 'Öltemperatur Warngrenze', value: 30 },
    //   { name: 'Systemfehler', value: 20 },
    //   { name: 'Schutztür "-S1" geöffnet', value: 15 }
    // ];
    return (
      <Tile header={config.title} {...this.props}>
        <Content>
          {/* Mouse Events are disabled by the CSS on the Content div  */}
          <DonutChartElement {...this.props} data={data} />
        </Content>
      </Tile>
    );
  }
}
export default DonutChart;
