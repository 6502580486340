interface EventAnalyse {
  sum: number;
  events: Array<{ name: string; value: number }>;
}

/**
 * API for event analytics
 *
 * @export
 * @class EventAnalyticsAPI
 */
export default class EventAnalyticsAPI {
  static async getEventAnalytics(asset: string, eventType: string) {
    const url: string = `/app/v1/eventAnalytics/${asset}/${eventType}`;

    let response = await fetch(url, {
      method: 'GET',
      headers:
        process.env.NODE_ENV === 'development'
          ? {
              'Content-Type': 'application/json',

              Authorization:
                'Bearer eyJhbGciOiJSUzI1NiIsImprdSI6Imh0dHBzOi8vY3NkZXYubG9jYWxob3N0OjgwODAvdWFhL3Rva2VuX2tleXMiLCJraWQiOiJrZXktaWQtMSIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmMDNmODBjOGQ0YjQ0OGY1YjAzMTA4ZGY0YjJkNTIxNCIsInN1YiI6ImNzZGV2LWRldmtzLTEuMC4wMjUiLCJzY29wZSI6WyJtZHNwOmNvcmU6QWRtaW4zcmRQYXJ0eVRlY2hVc2VyIl0sImNsaWVudF9pZCI6ImNzZGV2LWRldmtzLTEuMC4wMjUiLCJjaWQiOiJjc2Rldi1kZXZrcy0xLjAuMDI1IiwiYXpwIjoiY3NkZXYtZGV2a3MtMS4wLjAyNSIsImdyYW50X3R5cGUiOiJjbGllbnRfY3JlZGVudGlhbHMiLCJyZXZfc2lnIjoiYzdiYzUwOGUiLCJpYXQiOjE1NzA2MjkxMTgsImV4cCI6MTU3MDYzMDkxOCwiaXNzIjoiaHR0cHM6Ly9jc2Rldi5waWFtLmV1MS5taW5kc3BoZXJlLmlvL29hdXRoL3Rva2VuIiwiemlkIjoiY3NkZXYiLCJhdWQiOlsiY3NkZXYtZGV2a3MtMS4wLjAyNSJdLCJ0ZW4iOiJjc2RldiIsInNjaGVtYXMiOlsidXJuOnNpZW1lbnM6bWluZHNwaGVyZTppYW06djEiXSwiY2F0IjoiY2xpZW50LXRva2VuOnYxIn0.JVKyDF3n9iAYna-tob7KqzERQrsqP8LigXk7434RVpkeD-_9ohkUl7RiZFF-oYCiVtm8WL_dO529mI-GSwtPTkwpf4hDb98DOW5-xjVdpWpO0GsTUMb9z_V8J6zB5yE7gPRvYEIpI7c-Url8jRsA6UA05cS3nQerhYieXoUj7p1dtbvsstjmRf4fyvEdLvxk6miY4_KdQGGRhhGFhdedotqEPwgYQ6r1rnRpmkRurV75VLPT8Y8xp0lDA72zc4Vv2WttERghg0M6k1S_rV6UnIUSJSG4-pDh_Z78Zc7ciaibo3KsuKqLP-hOVeLZrwsfAADK4V6b3dq80e6c4dZCvQ'
            }
          : {
              'Content-Type': 'application/json'
            },

      credentials: 'same-origin'
    });

    if (response.status !== 200) {
      throw new Error(`Failed to get event analyses. Error: ${response}`);
    }
    const data: EventAnalyse = await response.json();

    return data;
  }
}
