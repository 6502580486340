import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import InfoImage from '../index';

import { selectData } from '../../../../../selectors/TileDataSelector';

const mapStateToProps = (state, ownProps) => {
  return {
    data: selectData(state, ownProps)
  };
};

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InfoImage)
);
