import * as React from 'react';
import styled from 'styled-components';

const Header = styled.h6`
  color: ${props => props.theme.conterMain};
  font-family: Montserrat Bold;
  font-size: 18px;
  margin-bottom: 0px;
  padding-bottom: 5px;

  display: flex;
  flex-direction: row;
`;

export default props => {
  const { text, action = null } = props;

  return (
    <Header>
      {text}
      {action}
    </Header>
  );
};
