import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import IncididentDetailModal from '../index';
import { showDetailView } from '../actions';
import { requestDeleteItem } from '../../DeleteModal/actions';
import { openIncidentModal } from '../../IncidentModal/actions';
import {
  makeGetSelectedIncidentIdSelector,
  makeGetSelectedIncidentSelector
} from '../../../../selectors/csServices';

const getSelectedIncidentIdSelector = makeGetSelectedIncidentIdSelector(
  'serviceLog'
);
const getSelectedIncidentSelector = makeGetSelectedIncidentSelector(
  'serviceLog'
);

const mapStateToProps = (state, ownProps) => ({
  id: getSelectedIncidentIdSelector(state, ownProps),
  incident: getSelectedIncidentSelector(state, ownProps)
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(showDetailView(null)),
  updateIncident: servicecase => {
    dispatch(openIncidentModal(servicecase));
  },
  deleteIncident: id =>
    dispatch(
      requestDeleteItem({
        itemName: 'Incidents',
        displayItemName: 'Vorfall',
        id
      })
    )
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IncididentDetailModal)
);
