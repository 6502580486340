import * as React from 'react';
import styled from 'styled-components';
import Select from '../../../../../../components/Select';

import FilterButton from '../FilterButton';

const FilterDiv = styled.div`
  display: flex;
  flex-direction: row;

  &:first-child {
    margin-left: auto;
  }
`;

const DesktopView = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const MobileView = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px;

  @media (min-width: 1000px) {
    display: none;
  }
`;

const SelectDiv = styled.div`
  margin-right: 20px;
  width: 120px;
`;

const Description = styled.p`
  line-height: 34px;
  color: #2e3940;
  font-family: Montserrat Bold;
  font-size: 18px;
  margin-right: 20px;

  @media (min-width: 1000px) {
    display: none;
  }
`;

const getSelectedOption = (options, selectedEventType) => {
  return options.find(({ value }) => value === selectedEventType);
};

export default ({ description, options, onChange, filter, scope }) => (
  <FilterDiv className={`${scope}-filter`}>
    <Description>{description}</Description>
    <MobileView>
      <SelectDiv>
        <Select
          placeholder={''}
          options={options}
          value={getSelectedOption(options, filter[scope])}
          onChange={({ value }) => onChange(scope, value)}
        />
      </SelectDiv>
    </MobileView>
    <DesktopView>
      {options.map(option => (
        <FilterButton
          key={`${option.scope}.${option.value}`}
          onClick={() => onChange(scope, option.value)}
          active={filter[scope] === option.value}
        >
          {option.label}
        </FilterButton>
      ))}
    </DesktopView>
  </FilterDiv>
);
