import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { List, ListItem, Row, Cell, Header } from '../../List';

library.add(faTrash, faPen);

const BetterFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  height: 20px !important;

  cursor: pointer;
`;

const WIDTH_TITLE = '200px';
const WIDTH_TYPE = '200px';
const WIDTH_NUMBER = '200px';
const WIDTH_ACTION = '80px';

export default ({ servicecases, updateServicecase, deleteServicecase }) => (
  <List>
    <Header>
      <Cell width={WIDTH_TITLE}>Title</Cell>
      <Cell width={WIDTH_TYPE}>Typ</Cell>
      <Cell width={WIDTH_NUMBER}>Nummer</Cell>
      <Cell width={WIDTH_ACTION} pushToRight>
        Aktion
      </Cell>
    </Header>
    {servicecases.map(servicecase => {
      const { id, title, servicecaseNumber, servicecaseType } = servicecase;
      return (
        <ListItem key={id}>
          <Row>
            <Cell
              width={WIDTH_TITLE}
              type={'text'}
              textStyle={{
                bold: true
              }}
            >
              {title}
            </Cell>
            <Cell width={WIDTH_TYPE} type={'text'}>
              {servicecaseType}
            </Cell>
            <Cell width={WIDTH_NUMBER} type={'text'}>
              {servicecaseNumber}
            </Cell>
            <Cell width={WIDTH_ACTION} type={'text'} pushToRight>
              {true && (
                //@ts-ignore
                <BetterFontAwesomeIcon
                  icon={['far', 'pen']}
                  size="lg"
                  color="#0076cf"
                  onClick={() =>
                    updateServicecase({
                      id,
                      title,
                      servicecaseNumber,
                      servicecaseType
                    })
                  }
                />
              )}
              <span style={{ paddingRight: '20px' }}></span>
              {true && (
                //@ts-ignore
                <BetterFontAwesomeIcon
                  icon={['far', 'trash']}
                  size="lg"
                  color="#ff4318"
                  onClick={() => deleteServicecase(servicecase.id)}
                />
              )}
            </Cell>
          </Row>
        </ListItem>
      );
    })}
  </List>
);
