import {
  CREATE_SUCCEEDED,
  CREATE_REQUESTED,
  UPDATE_REQUESTED,
  UPDATE_SUCCEEDED
} from '../../../../services/core/reducerFactory/types';

export default {
  OPEN_MODAL: 'OPEN_SERVICECASE_MODAL',
  CLOSE_MODAL: 'CLOSE_SERVICECASE_MODAL',
  EDIT_SERVICECASE: 'EDIT_SERVICECASE',
  CREATE_REQUESTED: CREATE_REQUESTED.replace('*', 'csServices'),
  CREATE_SUCCEEDED: CREATE_SUCCEEDED.replace('*', 'csServices'),
  UPDATE_REQUESTED: UPDATE_REQUESTED.replace('*', 'csServices'),
  UPDATE_SUCCEEDED: UPDATE_SUCCEEDED.replace('*', 'csServices')
};
