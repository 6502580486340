import * as React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 991.92px) {
    flex-direction: column;
  }
`;

export default ({ children }) => <Row>{children}</Row>;
