import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  mobileMenu: {
    isOpen: false
  },
  selectedMenuItem: '',
  dragAndDropEnabled: false
};

const Layout = handleActions(
  {
    [types.MENU_OPEN]: state => ({
      ...state,
      mobileMenu: {
        isOpen: true
      }
    }),
    [types.MENU_CLOSE]: state => ({
      ...state,
      selectedMenuItem: '',
      mobileMenu: {
        isOpen: false
      }
    }),
    [types.MENU_ITEM_SELECT]: (state, action) => ({
      ...state,
      selectedMenuItem: action.payload
    }),
    [types.TOGGLE_DRAG_AND_DROP]: state => ({
      ...state,
      dragAndDropEnabled: !state.dragAndDropEnabled
    }),
    [types.SEARCH_CHANGED]: (state, action) => ({
      ...state,
      jobSearch: action.payload
    })
  },
  initialState
);

export default Layout;
