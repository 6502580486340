
import * as React from 'react';
import styled from 'styled-components';

const EventTyp = styled.div`
  width: 200px;
  padding: 10px;

  @media (max-width: 991.92px) {
    width: 100%;
  }
`;

export default ({ children }) => <EventTyp>{children}</EventTyp>;
