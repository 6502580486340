import { isUndefined, uniq } from 'lodash';
import Source from '../source';
import mindConnector from '../../../api/mindsphere';
import DATA_VALUES from '../../../enums/dataValues';

//global types
import { SourceState } from '../../../globalTypes/state';
import { DashboardProperties, Action } from '../../../globalTypes/dashboard';
import { AggreagteResponse } from '../../../api/mindsphere/mindesphere';

//source types
import {
  AggregateRequestParameter,
  FormattedData,
  FormattedDataParameter
} from '../sourceTypes';
// Use require for unit tests
let moment = require('moment');

class AggregateMSP extends Source {
  /**
   * Collect all request which are required for the data
   *
   * @param {SourceState} sourceState
   * @param {Array<DashboardProperties>} dashboardProps
   * @returns {Array<AggregateRequestParameter>}
   * @memberof AggregateMSP
   */
  getRequests(
    sourceState: SourceState,
    dashboardProps: Array<DashboardProperties>
  ): Array<AggregateRequestParameter> {
    let requests: Array<AggregateRequestParameter> = [];
    let combinedRequests: Array<AggregateRequestParameter> = [];

    if (Object.keys(sourceState).length === 0) {
      requests = this._buildRequests(dashboardProps, undefined);
    } else {
      requests = this._buildRequests(dashboardProps, sourceState);
    }

    combinedRequests = this._combineRequests(requests);

    return combinedRequests;
  }

  /**
   * Build an id to access from the global state to the data
   *
   * @param {DashboardProperties} dashboardProps
   * @returns {string}
   * @memberof AggregateMSP
   */
  getId(dashboardProps: DashboardProperties): string {
    const {
      asset,
      aspect,
      property,
      intervalValue,
      intervaleUnit,
      fetchTime
    } = dashboardProps;
    const aggregateId: string = `${intervalValue}${intervaleUnit}${fetchTime}`;
    const id: string = `${asset}.${aspect}.${property}.${aggregateId}`;
    return id;
  }

  /**
   *
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns
   * @memberof AggregateMSP
   */
  getRequestedReducer(state: SourceState, action: Action) {
    let requests: Array<AggregateRequestParameter> = action.payload;
    let newState = state;

    // If state is empty -> fill in initial structure
    if (Object.keys(newState).length === 0) {
      requests.forEach((request: AggregateRequestParameter) => {
        //@ts-ignore
        newState = {
          ...newState,
          ...this._formatData(request, [])
        };
      });

      return {
        ...newState
      };
    }

    requests.forEach((request: AggregateRequestParameter) => {
      request.ids.forEach(id => {
        newState[id] = {
          ...newState[id],
          reqRunning: true
        };
      });
    });

    return {
      ...newState
    };
  }

  /**
   * Reducer for get aggregateMSP succeeded action
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns
   * @memberof AggregateMSP
   */
  getSucceededReducer(state: SourceState, action: Action) {
    let { formattedData, requestParams } = action.payload;

    const mergedState: SourceState = this._merge(
      state,
      formattedData,
      requestParams
    );
    return {
      ...mergedState
    };
  }

  /**
   * Gets called in the "get requested" saga.
   * Requests data and format it to aggregate source state format
   *
   * @param {AggregateRequestParameter} requestParams
   * @returns {Promise<FormattedData>}
   * @memberof AggregateMSP
   */
  async callMethodeForSaga(
    requestParams: AggregateRequestParameter
  ): Promise<{
    formattedData: FormattedData;
    requestParams: AggregateRequestParameter;
  }> {
    const validParams: AggregateRequestParameter = this._validateTimespan(
      requestParams
    );
    const data: Array<AggreagteResponse> = await mindConnector.getAggregates(
      validParams
    );

    const formattedData: FormattedData = this._formatData(validParams, data);

    return { formattedData, requestParams: validParams };
  }

  // _getNewFromDate(
  //   data: Array<AggreagteResponse>,
  //   params: AggregateRequestParameter
  // ) {
  //   // Has two default keys "starttime", "endtime"
  //   if (Object.keys(data[data.length - 1]).length <= 2) return params;

  //   const aspectName: string = Object.keys(data[data.length - 1])[0];

  //   const lastEntry: Aggregate = data[data.length - 1][aspectName];

  //   // const updatedParams: AggregateRequestParameter = {
  //   //   ...params,
  //   //   from: lastEntry.lasttime
  //   // };
  //   // return updatedParams;
  // }

  /**
   * Merge old and new state together
   *
   * @param {SourceState} state
   * @param {SourceState} newState
   * @returns {SourceState}
   * @memberof AggregateMSP
   */
  _merge(
    state: SourceState,
    newState: SourceState,
    req: AggregateRequestParameter
  ): SourceState {
    let merged: SourceState = { ...state };
    if (Object.keys(state).length === 0) {
      return newState;
    }

    Object.keys(newState).forEach(entry => {
      if (entry in merged) {
        merged[entry].from = newState[entry].from;
        merged[entry].to = newState[entry].to;
        merged[entry].reqRunning = false;
        merged[entry].firstLoad = false;
        merged[entry].data =
          // Difference between last and series values
          req.valueType === 0
            ? // If new last value is empty then keep old one
              newState[entry].data.length === 0
              ? merged[entry].data
              : newState[entry].data
            : this._mergeSeries(merged[entry].data, newState[entry].data);
      } else {
        merged[entry] = newState[entry];
      }
    });

    return merged;
  }

  /**
   *  Merge two array to a maximal size of 60 elements
   *
   * @param {Array<[]>} oldSeries
   * @param {Array<[]>} newSeries
   * @returns {Array<[]>}
   * @memberof AggregateMSP
   */
  _mergeSeries(oldSeries: Array<[]>, newSeries: Array<[]>): Array<[]> {
    let merged = [...oldSeries, ...newSeries];
    let shorted = merged.slice(merged.length - 60, merged.length);

    return shorted;
  }

  /**
   * Format MSP response data to state format
   *
   * @param {AggregateRequestParameter} requestParams
   * @param {Array<AggreagteResponse>} data
   * @returns {FormattedData}
   * @memberof AggregateMSP
   */
  _formatData(
    requestParams: AggregateRequestParameter,
    data: Array<AggreagteResponse>
  ): FormattedData {
    const {
      asset,
      aspect,
      intervalValue,
      intervaleUnit,
      fetchTime
    } = requestParams;

    let formattedData: FormattedData = {};

    data.forEach(entry => {
      delete entry.starttime;
      delete entry.endtime;

      Object.keys(entry).forEach(key => {
        const aggregateId: string = `${intervalValue}${intervaleUnit}${fetchTime}`;
        const id: string = `${asset}.${aspect}.${key}.${aggregateId}`;

        //Entry already exists
        if (id in formattedData) {
          if (requestParams.valueType === 0) {
            formattedData[id]['data'] = [
              {
                value: entry[key].lastvalue,
                time: entry[key].lasttime
              }
            ];
          } else {
            formattedData[id]['data'].push({
              value: entry[key].lastvalue,
              time: entry[key].lasttime
            });
          }

          //Create new formatted data entry
        } else if (Object.keys(entry).length !== 0) {
          formattedData[id] = this._getStateStructure(requestParams, [
            {
              value: entry[key].lastvalue,
              time: entry[key].lasttime
            }
          ]);
          if (requestParams.valueType === DATA_VALUES.last) {
            formattedData[id]['data'] = [
              {
                value: entry[key].lastvalue,
                time: entry[key].lasttime
              }
            ];
          } else {
            formattedData[id]['data'] = [
              {
                value: entry[key].lastvalue,
                time: entry[key].lasttime
              }
            ];
          }
        }
      });
    });

    formattedData = this._formatEmptyRequestParameter(
      requestParams,
      formattedData
    );

    return formattedData;
  }

  /**
   * Update from & to parameter of an request which was
   * returned from MSP with no data
   *
   * @param {AggregateRequestParameter} requestParams
   * @param {FormattedData} formattedData
   * @returns {FormattedData}
   * @memberof AggregateMSP
   */
  _formatEmptyRequestParameter(
    requestParams: AggregateRequestParameter,
    formattedData: FormattedData
  ): FormattedData {
    const {
      asset,
      aspect,
      intervalValue,
      intervaleUnit,
      fetchTime
    } = requestParams;
    // Update datapoint request parameter(from, to,...) with no return values
    requestParams.dataPoints.forEach(dp => {
      const aggregateId: string = `${intervalValue}${intervaleUnit}${fetchTime}`;
      const id = `${asset}.${aspect}.${dp}.${aggregateId}`;
      if (!(id in formattedData)) {
        const data = [];
        formattedData[id] = this._getStateStructure(requestParams, data);
      }
    });
    return formattedData;
  }

  /**
   * Adjusts the date range so that first the last hour and
   * then every last minute of data is fetched
   *
   * @param {AggregateRequestParameter} params
   * @returns {AggregateRequestParameter}
   * @memberof AggregateMSP
   */
  _validateTimespan(
    params: AggregateRequestParameter
  ): AggregateRequestParameter {
    const firstLoad: boolean = params.firstLoad;

    // First load is detected by null in "to" or "from"
    if (firstLoad) {
      params.to = moment()
        .startOf(params.intervaleUnit)
        .utc()
        .format();
      params.from = moment()
        .subtract(params.fetchTime, params.intervaleUnit)
        .startOf(params.intervaleUnit)
        .utc()
        .format();
      params.firstLoad = false;
    } else {
      params.from = moment()
        .startOf(params.intervaleUnit)
        .utc()
        .format();
      params.to = moment()
        .add(params.intervalValue, params.intervaleUnit)
        .startOf(params.intervaleUnit)
        .utc()
        .format();
    }

    return params;
  }

  /**
   * Build request
   *
   * @param {Array<DashboardProperties>} dashboardProps
   * @param {SourceState} sourceState
   * @returns {Array<AggregateRequestParameter>}
   * @memberof AggregateMSP
   */
  _buildRequests(
    dashboardProps: Array<DashboardProperties>,
    sourceState: SourceState
  ): Array<AggregateRequestParameter> {
    let requests: Array<AggregateRequestParameter> = [];

    dashboardProps.forEach(prop => {
      const aggregateId: string = `${prop.intervalValue}${prop.intervaleUnit}${prop.fetchTime}`;
      const id: string = `${prop.asset}.${prop.aspect}.${prop.property}.${aggregateId}`;

      // Do only once create a request for historical data
      if (prop.historical && !isUndefined(sourceState)) {
      } else {
        const params: AggregateRequestParameter = this._getRequestStructure(
          id,
          prop,
          sourceState
        );

        // If type series and lastvalue for the same id are requested then
        // only request the series
        let foundRequest = requests.find(
          request => request.ids[0] === params.ids[0]
        );
        if (!isUndefined(foundRequest)) {
          let index = requests.indexOf(foundRequest);
          if (params.valueType === DATA_VALUES.series) {
            requests[index].valueType = 1;
          }
        } else {
          requests.push({
            ...params
          });
        }
      }
    });

    return requests;
  }

  /**
   * Template for request format
   *
   * @param {string} id
   * @param {DashboardProperties} meta
   * @param {SourceState} sourceState
   * @returns {AggregateRequestParameter}
   * @memberof AggregateMSP
   */
  _getRequestStructure(
    id: string,
    meta: DashboardProperties,
    sourceState: SourceState
  ): AggregateRequestParameter {
    return {
      ids: [id],
      asset: meta.asset,
      aspect: meta.aspect,
      dataPoints: [meta.property],
      from: sourceState ? sourceState[id].from : null,
      to: sourceState ? sourceState[id].to : null,
      valueType: meta.valueType,
      reqRunning: sourceState ? sourceState[id].reqRunning : false,
      firstLoad: sourceState ? sourceState[id].firstLoad : true,
      intervaleUnit: meta.intervaleUnit,
      intervalValue: meta.intervalValue,
      fetchTime: meta.fetchTime,
      historical: meta.historical
    };
  }

  /**
   * Template for state format
   *
   * @param {AggregateRequestParameter} meta
   * @param {Array<any>} [data=[]]
   * @returns {FormattedDataParameter}
   * @memberof AggregateMSP
   */
  _getStateStructure(
    meta: AggregateRequestParameter,
    data: Array<any> = []
  ): FormattedDataParameter {
    return {
      from: meta.from,
      to: meta.to,
      firstLoad: meta.firstLoad,
      reqRunning: meta.reqRunning,
      historical: meta.historical,
      data
    };
  }

  /**
   * Combine requests which have the same asset,aspect
   *
   * @param {Array<AggregateRequestParameter>} requests
   * @returns {Array<AggregateRequestParameter>}
   * @memberof AggregateMSP
   */
  _combineRequests(
    requests: Array<AggregateRequestParameter>
  ): Array<AggregateRequestParameter> {
    let combinedRequests: Array<AggregateRequestParameter> = [];

    requests.forEach(dpRequest => {
      const existingDpRequestIndex: number = this._isDataPointRequestInCombinedRequests(
        dpRequest,
        combinedRequests
      );

      if (existingDpRequestIndex === -1) {
        combinedRequests.push(dpRequest);
      } else {
        combinedRequests = this._updateRequest(
          combinedRequests,
          existingDpRequestIndex,
          dpRequest
        );
      }
    });

    return combinedRequests;
  }

  /**
   * Check if requests have the same asset,aspect
   *
   * @param {AggregateRequestParameter} dpRequest
   * @param {Array<AggregateRequestParameter>} combinedRequests
   * @returns {AggregateRequestParameter}
   * @memberof AggregateMSP
   */
  _isDataPointRequestInCombinedRequests(
    dpRequest: AggregateRequestParameter,
    combinedRequests: Array<AggregateRequestParameter>
  ): number {
    return combinedRequests.findIndex(
      request =>
        request.asset === dpRequest.asset &&
        request.aspect === dpRequest.aspect &&
        request.valueType === dpRequest.valueType &&
        request.fetchTime === dpRequest.fetchTime &&
        request.intervaleUnit === dpRequest.intervaleUnit &&
        request.intervalValue === dpRequest.intervalValue
    );
  }

  /**
   * Update an request if it can be combined with another one
   *
   * @param {Array<AggregateRequestParameter>} combinedRequests
   * @param {AggregateRequestParameter} existingDpRequest
   * @param {AggregateRequestParameter} dpRequest
   * @returns {Array<AggregateRequestParameter>}
   * @memberof AggregateMSP
   */
  _updateRequest(
    combinedRequests: Array<AggregateRequestParameter>,
    existingDpRequestIndex: number,
    dpRequest: AggregateRequestParameter
  ): Array<AggregateRequestParameter> {
    let updatedRequests: Array<AggregateRequestParameter> = [
      ...combinedRequests
    ];

    const i = existingDpRequestIndex;
    // Merge datapoits together an deduplicate
    updatedRequests[i].dataPoints = uniq([
      ...updatedRequests[i].dataPoints,
      ...dpRequest.dataPoints
    ]);
    // Merge ids together
    updatedRequests[i].ids = uniq([
      ...updatedRequests[i].ids,
      ...dpRequest.ids
    ]);
    return updatedRequests;
  }
}

const aggregateMSP = new AggregateMSP();
export default aggregateMSP;
