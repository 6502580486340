import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DeleteModal from '../index';
import { cancel, deleteItem } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.scenes.serviceLog.deleteModal.isOpen,
  id: state.scenes.serviceLog.deleteModal.id,
  itemName: state.scenes.serviceLog.deleteModal.itemName,
  displayItemName: state.scenes.serviceLog.deleteModal.displayItemName
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(cancel()),
  deleteItem: (id, itemName) =>
    dispatch(
      deleteItem({
        itemName,
        data: { id }
      })
    )
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeleteModal)
);
