import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';

const StyledButton = styled(Button)`
  border: none;
  background-color: ${props => props.theme.mainLight};
  color: ${props => props.theme.main};
  margin-bottom: -10px;

  &:hover {
    color: ${props => props.theme.mainLight};
    background-color: ${props => props.theme.main};
  }
`;

export default ({ children, onClick }) => (
  <StyledButton onClick={onClick}>{children}</StyledButton>
);
