import * as React from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const DetailModal = styled(Modal)`
  max-width: 700px;
`;

const Header = styled(ModalHeader)`
  background-color: ${props => props.theme.background};
  font-family: Montserrat Bold;
  border: none;
`;

const Footer = styled(ModalFooter)`
  padding: 20px 30px;
`;

const DeleteButton = styled(Button)`
  height: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.main};
  color: ${props => props.theme.mainLight};
  background-color: ${props => props.theme.main};

  &:hover {
    background-color: ${props => props.theme.mainLight};
    color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }
`;

const CancelButton = styled(Button)`
  height: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.main};
  background-color: ${props => props.theme.mainLight};
  color: ${props => props.theme.main};
  margin-left: auto;
  margin-right: 20px;

  &:hover {
    color: ${props => props.theme.mainLight};
    background-color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }
`;

export default ({
  isOpen,
  close,
  deleteItem,
  id,
  itemName,
  displayItemName
}) => {
  return (
    <DetailModal isOpen={isOpen} toggle={close} className={'delete-modal'}>
      <Header toggle={close}>{displayItemName} löschen?</Header>
      <ModalBody>Möchten Sie den {displayItemName} wirklick löschen?</ModalBody>
      <Footer>
        <CancelButton onClick={close}>Abbrechen</CancelButton>
        <DeleteButton onClick={() => deleteItem(id, itemName)}>
          Löschen
        </DeleteButton>
      </Footer>
    </DetailModal>
  );
};
