export default {
  20: {
    label: 'Error',
    color: '#fb785d'
  },
  30: {
    label: 'Warning',
    color: '#fbaf5d'
  },
  40: {
    label: 'Info',
    color: '#5c75f8'
  }
};
