import * as React from 'react';
import styled from 'styled-components';

import Header from './components/Header';
import Event from './components/Event';
import PageLoadingIndicator from '../../../../components/PageLoadingIndicator';

const EventlistDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${props => props.theme.conterLight};
  margin-top: 45px;

  @media (max-width: 991.92px) {
    margin-top: 30px;
    padding: 0px 20px;
  }
`;

const InfoText = styled.p`
  @media (max-width: 991.92px) {
    padding: 0px 20px;
  }
`;

// const filterEventTypes = (events, filter) => {
//   return events.filter(event => {
//     let matchFilter = true;
//     Object.keys(filter).forEach(scope => {
//       if (event[scope] !== filter[scope] && filter[scope] !== 'all') {
//         matchFilter = false;
//       }
//     });
//     return matchFilter;
//   });
// };

export default props => {
  const { events, eventsLoading, toggleSelectedEvent, selectedEvents } = props;

  return (
    <div>
      {eventsLoading && events.length === 0 && <PageLoadingIndicator />}
      {!eventsLoading && events.length === 0 && (
        <InfoText>Aktuell sind keine Events vorhanden.</InfoText>
      )}
      {events.length > 0 && (
        <EventlistDiv>
          <Header />
          {events.map(event => (
            //@ts-ignore
            <Event
              event={event}
              key={event.id}
              isSelected={selectedEvents.some(id => id === event.id)}
              onSelectChange={() => {
                toggleSelectedEvent(event.id);
              }}
            />
          ))}
        </EventlistDiv>
      )}
    </div>
  );
};
