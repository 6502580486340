import { connect } from 'react-redux';

import HorizontalMenu from '../../../../components/HorizontalMenu';
import { toggleDragAndDrop } from '../../../../actions';

const mapStateToProps = (state, ownProps) => ({
  url: ownProps.match.url,
  dragAndDropEnabled: state.layout.dragAndDropEnabled
});

const mapDispatchToProps = dispatch => ({
  toggleDragAndDrop: () => dispatch(toggleDragAndDrop())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HorizontalMenu);
