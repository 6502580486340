import * as React from 'react';
import styled from 'styled-components';
import * as sizeMe from 'react-sizeme';

import getChartContainer from './services/getChartContainer';
import Header from './components/Header';
import CloseButton from './components/CloseButton';

const DetailedChartDiv = styled.div`
  height: 100%;
  width: 100%;
  z-index: 1100;
  padding: 30px;
  padding: 30px;
  background-color: #ffffff;

  @media (max-width: 600px) {
    padding-bottom: 10px;
  }
`;

const ChartDiv = styled.div`
  height: 100%;
  margin-top: -26.6px;

  @media (max-width: 500px) {
    padding-top: 20%;
    padding-bottom: 20%;
  }
`;

const DetailView = ({ tile, close, name, size }) => {
  const toolboxRight = size.width >= 500 ? 50 : 0;
  const showSlider = size.height > 500;

  const ChartContainer = getChartContainer(tile.type);
  if (!ChartContainer) return null;
  const overrideOption = defaultOption => ({
    ...defaultOption,
    legend: {
      ...defaultOption.legend,
      bottom: showSlider ? 45 : 0
    },
    grid: {
      left: 50,
      right: 30,
      top: 50,
      bottom: showSlider ? 100 : 50
    },
    tooltip: {
      ...defaultOption.tooltip,
      confine: true,
      show: true,
      position: function (pt) {
        return [pt[0], '20%'];
      }
    },
    toolbox: {
      itemSize: 17,
      itemGap: 30,
      right: toolboxRight,
      iconStyle: {
        borderColor: '#4c555b'
      },
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
          title: {
            zoom: 'Zoom',
            back: 'Back'
          }
        },
        restore: {
          title: 'Reset'
        }
      }
    },
    dataZoom: [
      {
        show: showSlider,
        type: 'inside',
        // startValue: startValue === null ? indexCount - 49 : startValue,
        // endValue: endValue === null ? indexCount - 1 : endValue,
        moveOnMouseMove: false
      },
      {
        show: showSlider,
        // startValue: startValue === null ? indexCount - 49 : startValue,
        // endValue: endValue === null ? indexCount - 1 : endValue,
        handleIcon:
          'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '80%',
        handleStyle: {
          color: '#fff',
          shadowBlur: 3,
          shadowColor: 'rgba(0, 0, 0, 0.6)',
          shadowOffsetX: 2,
          shadowOffsetY: 2
        },
        showDetail: false
      }
    ]
  });

  return (
    // @ts-ignore
    <DetailedChartDiv
      height={`${window.innerHeight}`}
      className="detail-chart-view"
    >
      <Header text={name} action={<CloseButton onClick={close} />} />
      <ChartDiv className="chart-div">
        <ChartContainer
          config={tile}
          overrideOption={overrideOption}
          height="100%"
          size={size}
          gridHeight={tile.size.h}
          gridWidth={tile.size.w}
          isDetailView
        />
      </ChartDiv>
    </DetailedChartDiv>
  );
};

// @ts-ignore
export default sizeMe({ monitorHeight: true, noPlaceholder: true })(DetailView);
