import { put, takeEvery, call, all, select } from 'redux-saga/effects';
import { dataSources } from '../../dataSources';
import {
  makeGetSucceededAction,
  makeGetFailedAction,
  makeDeleteSucceededAction,
  makeUpdateSucceededAction,
  makeUpdateFailedAction,
  makeGetRequestAction,
  makeDeleteFailedAction,
  makeCreateSucceededAction,
  makeCreateFailedAction
} from '../actions';
import {
  GET_REQUESTED,
  DELETE_REQUESTED,
  UPDATE_REQUESTED,
  CREATE_REQUESTED
} from '../types';

import { toast } from 'react-toastify';

import { prepareGetRequests } from '../../cycles/sagas';

interface RequestDataAction {
  payload?: Array<RequestParameter>;
  type: string;
}
type RequestParameter = object;

/**
 * Saga to get data from source
 *
 * @param {RequestDataAction} action
 * @param {string} source
 */
function* callData(action: RequestDataAction, source: string) {
  const requests: Array<RequestParameter> = action.payload;

  const requestCalls = requests.map(request => {
    return call(callRequest, request, source);
  });

  yield all(requestCalls);
}

/**
 * Get data from data source and trigger action
 *
 * @param {RequestParameter} request
 * @param {string} source
 */
const callRequest = function*(request: RequestParameter, source: string) {
  const toastId = 100;
  try {
    const state = yield select(state => state);
    const data: object = yield call(
      dataSources[source].callMethodeForSaga.bind(dataSources[source]),
      request,
      state
    );
    yield put(makeGetSucceededAction(source)(data));
    toast.dismiss(toastId);
  } catch (error) {
    const toastId = 100;
    if (!toast.isActive(toastId)) {
      toast.error('Data Could not be Retrieved from MindSphere', {
        position: 'top-right',
        autoClose: false,
        toastId
      });
    }
    yield put(makeGetFailedAction(source)(error));
  }
};

/**
 * Saga to delete data from source
 *
 * @param {Action} action
 * @param {string} source
 */
const deleteData = function*(action, source: string) {
  const toastId = 101;
  try {
    const data: object = yield call(
      dataSources[source].deleteMethodeForSaga.bind(dataSources[source]),
      action
    );

    yield put(makeDeleteSucceededAction(source)(data));

    const requests = yield prepareGetRequests(undefined, source);
    yield put(makeGetRequestAction(source)(requests));
    toast.dismiss(toastId);
  } catch (error) {
    if (!toast.isActive(toastId)) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: false,
        toastId
      });
    }
    yield put(makeDeleteFailedAction(source)());
  }
};

/**
 * Saga to create data in source
 *
 * @param {Action} action
 * @param {string} source
 */
const createData = function*(action, source: string) {
  const toastId = 102;
  try {
    const data: object = yield call(
      dataSources[source].createMethodeForSaga.bind(dataSources[source]),
      action
    );

    yield put(makeCreateSucceededAction(source)(data));

    const requests = yield prepareGetRequests(undefined, source);
    yield put(makeGetRequestAction(source)(requests));
    toast.dismiss(toastId);
  } catch (error) {
    console.error('error:', error);

    if (!toast.isActive(toastId)) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: false,
        toastId
      });
    }
    yield put(makeCreateFailedAction(source)());
  }
};

/**
 * Saga to update data from source
 *
 * @param {Action} action
 * @param {string} source
 */
const updateData = function*(action, source: string) {
  const toastId = 103;
  try {
    const data: object = yield call(
      dataSources[source].updateMethodeForSaga.bind(dataSources[source]),
      action
    );

    yield put(makeUpdateSucceededAction(source)(data));

    const requests = yield prepareGetRequests(undefined, source);
    yield put(makeGetRequestAction(source)(requests));
    toast.dismiss(toastId);
  } catch (error) {
    console.error('error:', error);

    if (!toast.isActive(toastId)) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: false,
        toastId
      });
    }
    yield put(makeUpdateFailedAction(source)());
  }
};

let dataSourceSaga = [];

Object.keys(dataSources).forEach(source => {
  dataSourceSaga.push(
    ...[
      takeEvery(GET_REQUESTED.replace('*', source), action =>
        callData(action, source)
      ),
      takeEvery(UPDATE_REQUESTED.replace('*', source), action =>
        updateData(action, source)
      ),
      takeEvery(CREATE_REQUESTED.replace('*', source), action =>
        createData(action, source)
      ),
      takeEvery(DELETE_REQUESTED.replace('*', source), action =>
        deleteData(action, source)
      )
    ]
  );
});

export default dataSourceSaga;
