import types from '../types';

const initialState = {
  lg: [],
  md: [],
  sm: [],
  xs: []
};
const gridLayout = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_STATUS_GRID_LAYOUT_REQUESTED:
      return state;
    case types.SET_STATUS_GRID_LAYOUT_SUCCEEDED:
      return state;
    case types.SET_STATUS_GRID_LAYOUT_FAILED:
      return state;

    case types.GET_STATUS_GRID_LAYOUT_REQUESTED:
      return state;
    case types.GET_STATUS_GRID_LAYOUT_SUCCEEDED:
      if (!action.payload) {
        return initialState;
      } else {
        return action.payload;
      }
    default:
      return state;
  }
};

export default gridLayout;
