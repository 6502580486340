
import * as React from 'react';
import styled from 'styled-components';

const Date = styled.div`
  width: 250px;
  min-width: 110px;
  padding: 10px;

  @media (max-width: 991.92px) {
    width: 100%;
  }
`;

export default ({ children }) => <Date>{children}</Date>;
