import { handleActions } from 'redux-actions';
import types from '../types';
import delteModalTypes from '../../DeleteModal/types';

const initialState = {
  id: null
};

export default handleActions(
  {
    [types.SHOW_DETAIL_VIEW]: (state, { payload }) => {
      return { ...state, id: payload };
    },
    [delteModalTypes.DELETE_REQUESTED]: (state, { payload }) => {
      return { ...state, id: null };
    }
  },
  initialState
);
