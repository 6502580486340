import { put, takeEvery, select } from 'redux-saga/effects';

import {
  start20SecCycles,
  start5SecCycles,
  getConfigSucceeded,
  getConfigFailed
} from '../actions';
import { makeGetRequestAction } from '../../reducerFactory/actions';
import { dataSources } from '../../dataSources';
import DashboardManager from '../../dashboardManager';
import {
  GET_CONFIG_REQUESTED,
  GET_CONFIG_SUCCEEDED,
  CYCLES_5_SEC,
  CYCLES_20_SEC
} from '../types';

//types
import {
  Dashboard,
  TileData,
  Tile,
  DashboardProperties
} from '../../globalTypes/dashboard';
import { SourceState } from '../../globalTypes/state';
import {
  AggregateRequestParameter,
  TimeseriesRequestParameter
} from '../../dataSources/sources/sourceTypes';

const dashboardManager = new DashboardManager();

const cycleSelector = ({ core }) => core.cycle;
export const getSourceState = ({ state, source }): SourceState => state[source];

type SourceCycles = { [sourcName: string]: number };

/**
 * Wait for x seconds
 *
 * @param {number} milliseconds
 * @returns {Promise<undefined>}
 */
function wait(milliseconds: number): Promise<undefined> {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/**
 * Get all dashboards for a tenant
 *
 * @param {Object} action
 */
function* getDashboards(action) {
  try {
    const manager = new DashboardManager();
    const dashboards = yield manager.getDashboards();

    yield put(getConfigSucceeded(dashboards));
  } catch (error) {
    yield put(getConfigFailed(error));
  }
}

/**
 * init cycles config load and scene change
 */
function* initCycles() {
  const cycleState = yield select(cycleSelector);
  const { id, active } = cycleState;

  if (!active) return;

  yield put(start5SecCycles({ interval: 5, id }));
  yield put(start20SecCycles({ interval: 20, id }));
}

/**
 * Cycle recursion
 *
 * @param {{ payload: { interval: number; activeDashboard: Dashboard } }} action
 * @param {*} startcycles Action of cycle
 */
function* runcycles(
  action: {
    payload: { interval: number; id: string };
  },
  startcycles
) {
  const { id, interval } = action.payload;
  const cycleState = yield select(cycleSelector);

  // return if cycle id is expired
  if (cycleState.id !== id) return;

  const { scene, tab } = cycleState;
  const dashboards = yield select(({ config }) => config);
  const activeDashboard = dashboardManager.getDashboard(dashboards, tab, scene);

  if (!activeDashboard) return;

  yield collectDataSources(activeDashboard, interval);
  yield wait(interval * 1000);

  yield put(startcycles(action.payload));
}

export function* prepareGetRequests(
  dashboardProps: Array<DashboardProperties>,
  source: string
) {
  const sourceState: SourceState = yield select(state =>
    getSourceState({ state, source })
  );

  //Get source requests for
  const requests: Array<
    AggregateRequestParameter | TimeseriesRequestParameter
  > = dataSources[source].getRequests(sourceState, dashboardProps);
  const filteredRequests: Array<
    AggregateRequestParameter | TimeseriesRequestParameter
  > = dataSources[source].filterOutAlreadyRunning(requests);

  return filteredRequests;
}

/**
 * Collect all data sources which are belonging
 * to the current cycle and prepare requests for them
 *
 * @param {Dashboard} activeDashboard
 * @param {number} interval fetching cycle
 */
function* collectDataSources(activeDashboard: Dashboard, interval: number) {
  const sourceCycles: SourceCycles = getNeededDataSourceCycles(
    activeDashboard.tiles
  );

  for (let i = 0; i < Object.keys(sourceCycles).length; i++) {
    const source: string = Object.keys(sourceCycles)[i];
    // Start request only if source compares the current interval

    if (sourceCycles[source] === interval) {
      const dashboardProps: Array<DashboardProperties> = getSourceProps(
        activeDashboard.tiles,
        source
      );

      //Request data from source
      const requests = yield prepareGetRequests(dashboardProps, source);

      yield put(makeGetRequestAction(source)(requests));
    }
  }
}

/**
 * Get associated datapoint entries from tiles
 *
 * @param {Array<Tile>} tiles
 * @param {string} source
 * @returns {Array<Properties>}
 */
export function getSourceProps(
  tiles: Array<Tile>,
  source: string
): Array<DashboardProperties> {
  let dps: Array<DashboardProperties> = [];
  tiles.forEach(tile => {
    tile.data.forEach(dp => {
      if (dp.source.name === source) {
        dps.push(dp.properties);
      }
    });
  });

  return dps;
}

/**
 * Get information about how often
 * a data source has to be fetched
 *
 * @param {Array<Tile>} tiles
 * @returns {SourceCycles}
 */
export function getNeededDataSourceCycles(tiles: Array<Tile>): SourceCycles {
  let sourceCycles = {};

  // Loop through tiles
  tiles.forEach(card => {
    const data: Array<TileData> = card.data;
    // Loop through required data entries
    data.forEach(entry => {
      if (entry.source !== null && !(entry.source.name in sourceCycles)) {
        sourceCycles[entry.source.name] = entry.source.cycles;
      }
    });
  });
  return sourceCycles;
}

const cyclesSaga = [
  takeEvery(GET_CONFIG_REQUESTED, getDashboards),
  takeEvery(GET_CONFIG_SUCCEEDED, initCycles),
  takeEvery('@@router/LOCATION_CHANGE', initCycles),
  //@ts-ignore
  takeEvery(CYCLES_5_SEC, action => runcycles(action, start5SecCycles)),
  //@ts-ignore
  takeEvery(CYCLES_20_SEC, action => runcycles(action, start20SecCycles))
];

export default cyclesSaga;
