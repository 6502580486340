import * as React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import styled from 'styled-components';

import { EventsPagination } from '../../../../services/core/dataSources/sources/eventsMSP/types';

const PaginationDiv = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  felx-direction: row;
  width: 100%;
  margin-top: 20px;
`;

const CenteredPagination = styled(Pagination)`
  margin-left: auto;
  margin-right: auto;
`;

const CsPaginationItem = styled(PaginationItem)`
  &:hover {
    a {
      color: #1da6c9;
    }
  }

  ${({ active }) =>
    active &&
    `
      a { 
        color: #fff !important;
        background-color: #1da6c9 !important;
        border-color: #1da6c9 !important;
      }
    `}
`;

const CsPaginationLink = styled(PaginationLink)`
  color: #7f8f9e;
`;

const renderPreviousLink = (pagination: EventsPagination, selectPage) => {
  const { number } = pagination;

  // disabled:
  // number 0; totalPages 1; === false
  // number 1; totalPages 2; === true
  const enabled = number > 0;

  return (
    <CsPaginationItem disabled={!enabled}>
      <CsPaginationLink
        previous
        href="#"
        onClick={() => selectPage(number - 1)}
      ></CsPaginationLink>
    </CsPaginationItem>
  );
};

const renderNextLink = (pagination: EventsPagination, selectPage) => {
  const { totalPages, number } = pagination;

  // disabled:
  // number 0; totalPages 1; === false
  // number 0; totalPages 2; === true
  const enabled = number + 1 < totalPages;

  return (
    <CsPaginationItem disabled={!enabled}>
      <CsPaginationLink
        next
        href="#"
        onClick={() => selectPage(number + 1)}
      ></CsPaginationLink>
    </CsPaginationItem>
  );
};

const renderFirstLink = (pagination: EventsPagination, selectPage) => {
  const { number } = pagination;

  return (
    <CsPaginationItem active={number === 0}>
      <CsPaginationLink href="#" onClick={() => selectPage(0)}>
        1
      </CsPaginationLink>
    </CsPaginationItem>
  );
};

const renderLastLink = (pagination: EventsPagination, selectPage) => {
  const { totalPages, number } = pagination;

  if (totalPages <= 1) return null;

  return (
    <CsPaginationItem active={number === totalPages - 1}>
      <CsPaginationLink href="#" onClick={() => selectPage(totalPages - 1)}>
        {totalPages}
      </CsPaginationLink>
    </CsPaginationItem>
  );
};

const renderCurrentLink = (pagination: EventsPagination, selectPage) => {
  const { totalPages, number } = pagination;

  if (number === 0 || number === totalPages - 1) return null;

  return (
    <CsPaginationItem active={true}>
      <CsPaginationLink href="#" onClick={() => selectPage(number)}>
        {number + 1}
      </CsPaginationLink>
    </CsPaginationItem>
  );
};

const renderBeforeCurrentLink = (pagination: EventsPagination, selectPage) => {
  const { number } = pagination;

  // totalPages 5; number 0; return null
  // totalPages 5; number 1; return null
  // totalPages 5; number 2; return 2
  // totalPages 5; number 3; return ...
  if (number < 2) return null;

  const enabled = number === 2;
  const text = number === 2 ? '2' : '...';

  return (
    <CsPaginationItem disabled={!enabled}>
      <CsPaginationLink next href="#" onClick={() => selectPage(number - 1)}>
        {text}
      </CsPaginationLink>
    </CsPaginationItem>
  );
};

const renderAfterCurrentLink = (pagination: EventsPagination, selectPage) => {
  const { number, totalPages } = pagination;

  // totalPages 5; number 4; return null
  // totalPages 5; number 3; return null
  // totalPages 5; number 2; return totalpages - 1
  // totalPages 5; number 3; return ...
  if (number > totalPages - 3) return null;

  const enabled = number === totalPages - 3;
  const text = number === totalPages - 3 ? totalPages - 1 : '...';

  return (
    <CsPaginationItem disabled={!enabled}>
      <CsPaginationLink
        next
        href="#"
        onClick={() => selectPage(totalPages - 2)}
      >
        {text}
      </CsPaginationLink>
    </CsPaginationItem>
  );
};

export default props => {
  const pagination: EventsPagination = props.pagination;
  const selectPage: void = props.selectPage;
  if (!pagination) return null;

  return (
    <PaginationDiv>
      <CenteredPagination>
        {renderPreviousLink(pagination, selectPage)}
        {renderFirstLink(pagination, selectPage)}
        {renderBeforeCurrentLink(pagination, selectPage)}
        {renderCurrentLink(pagination, selectPage)}
        {renderAfterCurrentLink(pagination, selectPage)}
        {renderLastLink(pagination, selectPage)}
        {renderNextLink(pagination, selectPage)}
      </CenteredPagination>
    </PaginationDiv>
  );
};
