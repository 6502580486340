import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BarChart from '..';
import { selectData } from '../../../../../selectors/TileDataSelector';

const mapStateToProps = (state, ownProps) => {
  let data = selectData(state, ownProps);

  let dataSeries = data[0] || [];
  // let dataSeries = [...data[0]];
  let dataSetPoint = data[1] || 100;
  return {
    dataSeries,
    dataSetPoint
  };
};

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BarChart)
);
