import { createSelector } from 'reselect';
import {
  Incident,
  Solution,
  Servicecase,
  ItemName
} from '../../services/core/dataSources/sources/csServices/types';

const getItem = (state, ownProps, itemName: ItemName) => {
  try {
    const { data } = state.csServices[itemName];

    if (typeof data !== 'undefined') {
      return data;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

const getIncidents = (state, ownProps): Incident[] => {
  return getItem(state, ownProps, 'Incidents');
};

const getServicecases = (state, ownProps): Servicecase[] => {
  return getItem(state, ownProps, 'Servicecases');
};

const getSolutions = (state, ownProps): Solution[] => {
  return getItem(state, ownProps, 'Solutions');
};

export const getIncidentSelector = createSelector(
  getIncidents,
  incidents => incidents
);

export const getServicecaseSelector = createSelector(
  getServicecases,
  servicecases => servicecases
);

export const getFullIncidentListSelector = createSelector(
  getIncidents,
  getServicecases,
  getSolutions,
  (incidents, servicecases, solutions) => {
    return incidents.map(incident => {
      const { servicecaseId } = incident;
      const servicecase = servicecases.find(({ id }) => id === servicecaseId);
      const filteredSolutions = solutions.filter(
        ({ servicecaseId }) => servicecase && servicecaseId === servicecase.id
      );
      const solution = filteredSolutions.find(
        ({ incidentId }) => incidentId === incident.id
      );
      const lastSolutions = filteredSolutions.filter(
        ({ incidentId }) => incidentId !== incident.id
      );

      return {
        ...incident,
        servicecase: servicecase,
        solution: solution,
        lastSolutions
      };
    });
  }
);

export const makeGetSelectedIncidentIdSelector = (path: string) => {
  return (state, ownProps): string => state.scenes[path].incidentDetailModal.id;
};

export const makeGetSelectedIncidentSelector = (path: string) => {
  return createSelector(
    getFullIncidentListSelector,
    makeGetSelectedIncidentIdSelector(path),
    (incidents, selectedIncidentId): Incident =>
      incidents.find(({ id }) => selectedIncidentId === id)
  );
};
