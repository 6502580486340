import { connect } from 'react-redux';

import DetailedChartView from '../index';
import { showDetailedChartViewFor } from '../../../../Chart/actions';
import { getDetailChartTile } from '../../../../../selectors/Dashboards';

const mapStateToProps = state => ({
  tile: getDetailChartTile(state)
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(showDetailedChartViewFor(null))
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailedChartView);
