type RequestHeaders = {
  'Content-Type': string;
};
type Path = string;
type Id = string;
type Body = {
  [key: string]: any;
};
type Options = {
  method: string;
  headers: RequestHeaders;
  body?: string;
};

const REQUEST_HEADERS: RequestHeaders = {
  'Content-Type': 'application/json'
};
export default class ApiClient {
  static getBaseUrl() {
    if (process.env.NODE_ENV === 'development') {
      return 'http://127.0.0.1:4000/';
    } else {
      return 'https://rwwqy1hwfa.execute-api.eu-central-1.amazonaws.com/dev/';
    }
  }

  static async request(path: Path, options) {
    // const tokenResponse = await fetch('/app/v1/token');
    // const tokens = await tokenResponse.json();

    // const optionsWithAuth = {
    //   ...options,
    //   headers: {
    //     ...options.headers,
    //     Authorization: `Bearer ${tokens.accessToken}`
    //   }
    // };

    const url: string = `${this.getBaseUrl()}${path}`;
    const response = await fetch(url, options);

    const json = await response.json();

    if (json.error) {
      //@ts-ignore
      throw new Error(json.error);
    }

    return json;
  }

  static async get(path: Path) {
    const options: Options = {
      method: 'GET',
      headers: REQUEST_HEADERS
    };
    return await this.request(path, options);
  }

  static async put(path: Path, id: Id, body: Body) {
    const options: Options = {
      method: 'PUT',
      headers: REQUEST_HEADERS,
      body: JSON.stringify(body)
    };
    return await this.request(`${path}/${id}`, options);
  }

  static async post(path: Path, body: Body) {
    const options: Options = {
      method: 'POST',
      headers: REQUEST_HEADERS,
      body: JSON.stringify(body)
    };
    return await this.request(path, options);
  }

  static async del(path: Path, id: Id) {
    const options: Options = {
      method: 'DELETE',
      headers: REQUEST_HEADERS
    };
    return await this.request(`${path}/${id}`, options);
  }
}
