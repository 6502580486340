import { handleActions } from 'redux-actions';
import types from '../types';

import showDetailedChartViewFor from './utils/showDetailedChartViewFor';
import changeChartDatazoom from './utils/changeChartDatazoom';

const initialState = {
  showDetailedChartViewFor: null,
  startValue: null,
  endValue: null
};

const Chart = handleActions(
  {
    [types.SHOW_DETAILED_CHART_VIEW]: showDetailedChartViewFor,
    [types.CHANGE_CHART_DATAZOOM]: changeChartDatazoom
  },
  initialState
);

export default Chart;
