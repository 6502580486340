import { DashboardProperties, Action } from '../../globalTypes/dashboard';
import { SourceState } from '../../globalTypes/state';
import { GeneralRequestParameter } from './sourceTypes';

/**
 * Manages a data source for the Dashboard
 *
 * @export
 * @class DataSource
 */
export default abstract class DataSource {
  /**
   * Prepare all request for the data source to start them
   * seperate and parallel
   *
   * @abstract
   * @param {SourceState} sourceState
   * @param {Array<DashboardProperties>} dashboardProps
   * @returns {Array<RequestParameter>}
   * @memberof DataSource
   */
  abstract getRequests(
    sourceState: SourceState,
    dashboardProps: Array<DashboardProperties>
  ): Array<GeneralRequestParameter>;

  /**
   * Build up a class specific id to access from the global state
   * to the data
   *
   * @abstract
   * @param {DashboardProperties} dashboardProps
   * @returns {string}
   * @memberof DataSource
   */
  abstract getId(dashboardProps: DashboardProperties): string;

  /**
   * Reducer for getRequested
   *
   * @static
   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  getRequestedReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }

  /**
   * Reducer for getSucceeded
   *
   * @static
   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  getSucceededReducer(state: SourceState, action: Action): SourceState {
    return {
      ...action.payload
    };
  }

  /**
   * Reducer for getFailed
   *
   * @static
   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  getFailedReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }

  /**
   * Methode that will be called in the get requested saga
   *
   * @abstract
   * @param {RequestParameter} requestParams
   * @returns
   * @memberof DataSource
   */
  abstract async callMethodeForSaga(
    requestParams: GeneralRequestParameter,
    state?: object
  ): Promise<object>;

  /**
   * Reducer for updateRequested
   *

   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  updateRequestedReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }
  /**
   * Reducer for updateSucceeded
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  updateSucceededReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }
  /**
   * Reducer for updateFailed
   *

   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  updateFailedReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }

  /**
   * Methode that will be called in the update requested saga
   *

   * @param {RequestParameter} requestParams
   * @returns
   * @memberof DataSource
   */
  updateMethodeForSaga(requestParams: GeneralRequestParameter) {
    return {};
  }

  /**
   * Reducer for deleteRequested
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  deleteRequestedReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }
  /**
   * Reducer for deleteSucceeded
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  deleteSucceededReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }
  /**
   * Reducer for deleteFailed
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  deleteFailedReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }

  /**
   * Methode that will be called in the delete requested saga
   *
   * @param {RequestParameter} requestParams
   * @returns
   * @memberof DataSource
   */
  deleteMethodeForSaga(requestParams: GeneralRequestParameter) {
    return {};
  }

  /**
   * Reducer for createRequested
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  createRequestedReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }
  /**
   * Reducer for createSucceeded
   *

   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  createSucceededReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }
  /**
   * Reducer for createFailed
   *
   * @param {SourceState} state
   * @param {Action} action
   * @returns {SourceState}
   * @memberof DataSource
   */
  createFailedReducer(state: SourceState, action: Action): SourceState {
    return {
      ...state
    };
  }

  /**
   * Methode that will be called in the create requested saga
   *
   * @param {RequestParameter} requestParams
   * @returns
   * @memberof DataSource
   */
  createMethodeForSaga(requestParams: GeneralRequestParameter) {
    return {};
  }

  /**
   * Filter out already running requests
   *
   * @param {Array<RequestParameter>} requests
   * @returns {Array<RequestParameter>}
   * @memberof DataSource
   */
  filterOutAlreadyRunning(
    requests: Array<GeneralRequestParameter>
  ): Array<GeneralRequestParameter> {
    let filteredRequests: Array<GeneralRequestParameter> = [];
    requests.forEach(request => {
      if (request.reqRunning === false) {
        filteredRequests.push(request);
      }
    });
    return filteredRequests;
  }
}
