import Cookies from 'universal-cookie';
import {
  Aggregate,
  Timeseries,
  AggreagteResponse,
  TimeseriesResponse
} from './mindesphere';
import {
  TimeseriesRequestParameter,
  AggregateRequestParameter,
  GeneralRequestParameter
} from '../../dataSources/sources/sourceTypes';
import {
  EventsFilter,
  EventsResponse
} from '../../dataSources/sources/eventsMSP/types';

const mindConnector = {
  /**
   * Return all assets
   *
   * @returns an array with all assets
   */
  async getAssets(assetId) {
    const url = `/api/assetmanagement/v3/assets/${assetId}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    });
    //@ts-ignore
    const json: Array<any> = await response.json();

    //@ts-ignore
    if (!json.assetId) return {};

    return json;
  },

  async getAggregates(
    params: AggregateRequestParameter
  ): Promise<Array<AggreagteResponse>> {
    let response: Array<Promise<Aggregate>>;

    const basicUrl: string = `/api/iottimeseries/v3/aggregates/`;
    const url: string = mindConnector.getUrl(params, basicUrl);
    //@ts-ignore
    response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    });

    const data: Array<AggreagteResponse> = await mindConnector.validateResponse(
      response
    );

    return data;
  },

  async getTimeseries(params: TimeseriesRequestParameter, fetchedEntries = []) {
    const basicUrl: string = `/api/iottimeseries/v3/timeseries/`;
    const url: string = mindConnector.getUrl(params, basicUrl);

    //@ts-ignore
    let response: Array<Promise<Timeseries>> = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    });

    const data: Array<
      TimeseriesResponse
    > = await mindConnector.validateResponse(response);

    //@ts-ignore
    let ts = [...fetchedEntries, ...data];
    //@ts-ignore

    if (data.length >= 2000) {
      // sets new start date to date of the last datapoint
      const newFrom = ts[ts.length - 1]._time;
      const updatedParams = { ...params, from: newFrom };
      return mindConnector.getTimeseries(updatedParams, ts);
    }

    return ts;
  },

  getUrl(params: GeneralRequestParameter, basicUrl: string): string {
    const { asset, aspect, from, to, dataPoints } = params;

    let dps: string = '';
    dataPoints.forEach(dp => (dps += `${dp},`));
    // Delete last comma from string
    dps = dps.slice(0, -1);

    // Build specifc msp url
    // FYI: Only the aggregate api needs interval unit and values
    const url: string = `${basicUrl}${asset}/${aspect}?from=${from}&to=${to}&select=${dps}${
      basicUrl.includes('aggregates')
        ? `&intervalUnit=${params.intervaleUnit}&intervalValue=${params.intervalValue}`
        : ''
    }`;

    return url;
  },

  format(ts, assetId, aspect, from) {
    let data = {};
    ts.forEach(entry => {
      entry.forEach(key => {
        if (!key.includes('_qc') && key !== '_time') {
          const id = `${assetId}.${aspect}.${key}`;
          if (id in data) {
            //@ts-ignore
            data.id.series.push(entry.key);
            //@ts-ignore
            data.to = entry._time;
            //@ts-ignore
            data.from = from;
          } else {
          }
        }
      });
    });
  },

  /**
   * Get filtered time series data from mindsphere (no limits, but five
   * times slower than iottimeseries)
   *
   * @param {string} assetId asset identifier
   * @param {Array} datapoints all needed aspect variables
   * @param {string} aspectName name of aspect
   * @param {string} from start date -> format(2018-10-05T14:06:29.893Z)
   * @param {string} to end date -> format(2018-10-05T14:06:29.893Z)
   * @returns array of requested timeseries
   */
  async getBulkTs(assetId, datapoints, aspectName, from, to) {
    //@ts-ignore
    const urlFilter = mindConnector.buildTsFilter(
      assetId,
      datapoints,
      aspectName,
      from,
      to
    );

    let url = `/api/iottsbulk/v3/timeseries/${urlFilter}`;

    let response;

    response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    });
    response = await mindConnector.validateResponse(response);
    let timeseries = response.records;
    return timeseries;
  },

  /**
   * Get all events from event type: b7f9a843-a530-4159-989e-20645e2b647d
   *
   * @param {string} assetId asset identifier
   * @returns an array of all events
   */
  async getEvents(
    filter: EventsFilter,
    page?: number
  ): Promise<EventsResponse> {
    const urlPage = page ? page : 0;
    let url = `/api/eventmanagement/v3/events?size=1&page=${urlPage}`;

    if (Object.keys(filter).length > 0) {
      url = `${url}&filter=${JSON.stringify(filter)}`;
    }

    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    });

    return await response.json();
  },

  /**
   * Get an event
   *
   * @param {string} eventId id of the event
   * @returns event
   */
  async getEvent(eventId) {
    let url = `/api/eventmanagement/v3/events/${eventId}`;

    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    });
    const json = await response.json();

    return json;
  },

  getCookieValue(cookie) {
    const cookies = new Cookies();

    const value = cookies.get(cookie);
    return value;
  },

  /**
   * Update an event
   *
   * @param {Object} event complete event
   * @returns an object with the state of the request
   */
  async updateEvent(eventId, ackState) {
    const event = await mindConnector.getEvent(eventId);

    const XSRF_TOKEN = mindConnector.getCookieValue('XSRF-TOKEN');
    //@ts-ignore
    const url = `/api/eventmanagement/v3/events/${event.id}`;

    const body = {
      //@ts-ignore
      typeId: event.typeId,
      //@ts-ignore
      correlationId: event.correlationId,
      //@ts-ignore
      timestamp: event.timestamp,
      //@ts-ignore
      entityId: event.entityId,
      //@ts-ignore
      severity: event.severity,
      acknowledged: ackState,
      //@ts-ignore
      property: event.property,
      //@ts-ignore
      description: event.description,
      //@ts-ignore
      source: event.source,
      //@ts-ignore
      code: event.code,
      //@ts-ignore
      ruleId: event.ruleId
    };

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/hal+json, application/vnd.error+json',
        'Content-Type': 'application/json',
        //@ts-ignore
        'If-Match': event.etag.toString(),
        'x-xsrf-token': XSRF_TOKEN
      },
      credentials: 'include',
      body: JSON.stringify(body)
    });

    return response;
  },

  /**
   * Get one or more files (only meta data)
   * To get only a specific file, pass the file name  you
   * want as second parameter to the function
   *
   * @param {string} assetId asset identifier
   * @param {string} filter file name, seee https://developer.mindsphere.io/apis/iot-iotfile/api-iotfile-references-filtering.html
   * @returns a array of the requested file/s
   */
  async getFiles(assetId, filter) {
    let url = `/api/iotfile/v3/files/${assetId}`;
    if (typeof filter !== 'undefined') {
      url = `/api/iotfile/v3/files/${assetId}?filter=${filter}`;
    }

    let response;

    response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    });

    response = await mindConnector.validateResponse(response);
    return response;
  },

  /**
   * Download a file
   *
   * @param {string} assetId asset identifier of gateway
   * @param {Object} fileData file meta data
   * @returns a object with the file data
   */
  async downloadFile(assetId, fileData) {
    let response;
    let fileUrl;

    const url = `/api/iotfile/v3/files/${assetId}/${fileData.name}`;

    response = await fetch(url, {
      method: 'GET',
      //@ts-ignore
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/octet-stream'
      },
      cache: 'no-store',
      credentials: 'same-origin'
    });

    // Create an array buffer of the response
    let buffer = await response.arrayBuffer();

    // Turn that array buffer into a blob
    let blob = new Blob([buffer], { type: fileData.type });
    // Create with the blob a url the display the image
    fileUrl = window.URL.createObjectURL(blob);

    return fileUrl;
  },

  buildTsFilter(assetId, datapoints, aspectName, from, to) {
    try {
      let datapointSelection = '';
      // Add datapoint filter
      datapoints.forEach(datapoint => {
        datapointSelection += `${datapoint},`;
      });
      // Delete last comma from string
      datapointSelection = datapointSelection.slice(0, -1);

      // const url = `${assetId}/${aspectName}?from=${from}&to=${to}`;
      const url = `${assetId}/${aspectName}?select=${datapointSelection}&from=${from}&to=${to}`;

      return url;
    } catch (error) {
      return '';
    }
  },

  async validateResponse(response) {
    try {
      const json: Array<any> = await response.json();
      if (Object.prototype.toString.call(json) !== '[object Array]') {
        throw new Error(
          `Mindsphere response type is ${typeof json} but should array.`
        );
      }
      return json;
    } catch (e) {
      throw e;
    }
  }
};

export default mindConnector;
