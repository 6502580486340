import { createAction } from 'redux-actions';
import types from '../types';

// REQUEST DATA
export const makeGetRequestAction = prefix =>
  createAction(types.GET_REQUESTED.replace('*', prefix));

export const makeGetSucceededAction = prefix =>
  createAction(types.GET_SUCCEEDED.replace('*', prefix));

export const makeGetFailedAction = prefix =>
  createAction(types.GET_FAILED.replace('*', prefix));

// UPDATE ACTIONS
export const makeUpdateRequestAction = prefix =>
  createAction(types.UPDATE_REQUESTED.replace('*', prefix));

export const makeUpdateSucceededAction = prefix =>
  createAction(types.UPDATE_SUCCEEDED.replace('*', prefix));

export const makeUpdateFailedAction = prefix =>
  createAction(types.UPDATE_FAILED.replace('*', prefix));

// CREATE ACTIONS
export const makeCreateRequestAction = prefix =>
  createAction(types.CREATE_REQUESTED.replace('*', prefix));

export const makeCreateSucceededAction = prefix =>
  createAction(types.CREATE_SUCCEEDED.replace('*', prefix));

export const makeCreateFailedAction = prefix =>
  createAction(types.CREATE_FAILED.replace('*', prefix));

// DELETE ACTIONS
export const makeDeleteRequestAction = prefix =>
  createAction(types.DELETE_REQUESTED.replace('*', prefix));

export const makeDeleteSucceededAction = prefix =>
  createAction(types.DELETE_SUCCEEDED.replace('*', prefix));

export const makeDeleteFailedAction = prefix =>
  createAction(types.DELETE_FAILED.replace('*', prefix));
