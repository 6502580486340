import * as React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faTimes);

const IconDiv = styled.div`
  position: absolute;
  height: 28px;
  line-height: 28px;
  top: 33px;
  right: 37px;
  font-size: 28px;
  color: ${props => props.theme.conterMain};
  z-index: 100000;

  svg {
    cursor: pointer;
    font-weight: 200;
  }
`;

export default ({ onClick = () => {} }) => (
  <IconDiv onClick={onClick}>
    <FontAwesomeIcon icon={['fal', 'times']} />
  </IconDiv>
);
