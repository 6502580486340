import * as React from 'react';
import styled from 'styled-components';

const Header = styled.h6`
  color: ${props => props.theme.conterMain};
  font-family: Montserrat Bold;
  font-size: 18px;
  margin-bottom: 0px;
  padding-bottom: 5px;

  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    font-size: 22px;
    padding-bottom: 10px;
  }
`;

const SubTitle = styled.div`
  padding-left: 20px;
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  font-family: Montserrat Bold;
  color: #8a98a5;
  margin: 0px;
  margin-top: 1px;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`;

export default props => {
  const { text, subTitle, action = null } = props;

  return (
    <Header>
      {text}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      {action}
    </Header>
  );
};
