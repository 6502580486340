import * as React from 'react';
import styled from 'styled-components';

const IconWithText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;

  padding-top: 1px;
  padding-left: 1px;

  border-radius: 100%;
  background-color: ${props => props.theme.mainLight};

  font-size: 20px;
  line-height: 40px;
  color: ${props => props.theme.main};
  text-align: center;
  display: inline-block;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  width: calc(100% - 40px);
  max-width: calc(100% - 40px);
`;

const UpperText = styled.p`
  font-family: Montserrat Bold;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.conterLight};
  margin-bottom: 6px;

  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 18px;
  }
`;
const LowerText = styled.p`
  font-family: Montserrat Bold;
  font-size: 20px;
  line-height: 20px;
  color: #2e3940;
  margin-bottom: 0px;

  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 22px;
  }
`;

export default props => (
  <IconWithText>
    <IconWrapper>{props.icon}</IconWrapper>
    <Text>
      <UpperText>{props.upperText}</UpperText>
      <LowerText title={props.lowerText}>{props.lowerText}</LowerText>
    </Text>
  </IconWithText>
);
