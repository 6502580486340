import moment from 'moment';

export default (dataSeries, dataSetPoint, theme) => {
  const data: Array<number> = sortDataSeries(dataSeries);

  const weekdays: Array<string> = getWeekDays();
  const dataSetPoints = data.map(() => dataSetPoint);

  return {
    animationDuration: 0,
    grid: {
      left: 0,
      right: 0,
      top: 0
      // bottom: -20
    },
    xAxis: {
      type: 'category',
      data: weekdays.map(day => ({
        value: day,
        textStyle: {
          fontWeight: 'normal',
          color: theme.conterLight
        }
      })),
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#303a41',
        interval: 0
      }
    },
    tooltip: {
      show: false
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 35,
      show: false
    },

    series: [
      {
        data: dataSetPoints,
        type: 'bar',
        barGap: '-100%',
        barWidth: '70%',
        itemStyle: {
          normal: {
            color: theme.background
          }
        }
      },
      {
        data,
        type: 'bar',
        color: theme.alter,
        barWidth: '70%'
      }
    ]
  };
};

function sortDataSeries(dataSeries): Array<number> {
  dataSeries.push({ time: '2019-10-01T14:11:12.616Z', value: 142 });
  const dataValues = [0, 0, 0, 0, 0, 0, 0];

  const dates: Array<string> = getLast7Days();

  dataSeries.forEach(entry => {
    let date: string = moment(entry.time).format('DD.MM.YYYY');
    if (dates.includes(date)) {
      let index: number = dates.indexOf(date);
      dataValues[index] = entry.value;
    }
  });

  return dataValues;
}

function getLast7Days() {
  let dates: Array<string> = [];
  for (let i = 1; i <= 7; i++) {
    let date: string = moment()
      .subtract(i, 'day')
      .format('DD.MM.YYYY');
    dates.unshift(date);
  }

  return dates;
}

function getWeekDays() {
  let date = new Date();
  let weekday: Array<string> = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag'
  ];
  let sorted = [];

  var n: number = date.getDay();
  let firstPart: Array<string> = weekday.slice(n, 7);
  let lastPart: Array<string> = weekday.slice(0, n);
  sorted = [...firstPart, ...lastPart];
  const shorted: Array<string> = sorted.map(day => day.slice(0, 2));

  return shorted;
}
