import { createAction } from 'redux-actions';
import types from '../types';

/**
 * Redux action to ack all events for the current machines
 * @param {string} payload.assetId assetId
 */
export const ackEventsRequested = createAction(types.ACK_EVENTS_REQUESTED);

/**
 * Redux action to ack all events for the current machines
 * @param {string} payload.assetId assetId
 */
export const ackEventsSucceeded = createAction(types.ACK_EVENTS_SUCCEDED);

/**
 * Redux action to select an filter
 * @param {string} payload.scope
 * @param {string} payload.value
 */
export const selectFilter = createAction(types.SELECT_FILTER);
