import {
  GET_REQUESTED,
  UPDATE_REQUESTED,
  UPDATE_SUCCEEDED
} from '../../../../../services/core/reducerFactory/types';

export default {
  SELECT_FILTER: GET_REQUESTED.replace('*', 'eventsMSP'),
  ACK_EVENTS_REQUESTED: UPDATE_REQUESTED.replace('*', 'eventsMSP'),
  ACK_EVENTS_SUCCEDED: UPDATE_SUCCEEDED.replace('*', 'eventsMSP')
};
