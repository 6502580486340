import * as React from 'react';
import { Component } from 'react';
import styled from 'styled-components';

import Tile from '../../../Tile';
import NumberOfNumber from '../../../Tile/components/NumberOfNumber';
import { isUndefined } from 'lodash';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

/** Layer State - Displays actual and total layer count */
class NumberProcess extends Component<any, any> {
  render() {
    const { config, data } = this.props;

    return (
      <Tile header={config.title} {...this.props}>
        <Content>
          <NumberOfNumber
            firstNumber={
              isUndefined(data[0]) ? '-' : (data[0] / 1000).toFixed(1)
            }
            secondNumber={`${data[1] || '-'}${config.data[1].properties.unit ||
              ''}`}
          ></NumberOfNumber>
        </Content>
      </Tile>
    );
  }
}

export default NumberProcess;
