import * as React from 'react';

import Menu from './components/Menu';
import NavItem from './components/NavItem';
import MindsphereLink from './components/MindsphereLink';

import getPath from '../../../../services/getPath';

/**
 * The side menu for mobile
 * @param  {Array<Assets>} props.assets Array of all assets to render links in dropdown
 * @param  {void} props.closeMenu function to close menu
 * @param  {boolean} props.isOpen show menu or collapse it
 * @param  {string} props.selectedMenuItem the selectedMenuItem (to highlight)
 * @param  {void} props.selectMenuItem function to select a menu item
 * @param  {string} props.url currenr url
 */
export default props => {
  const path = getPath(props.location);
  const {
    closeMenu,
    isOpen,
    layout,
    selectMenuItem,
    selectedMenuItem,
    currentTabId
  } = props;

  return (
    <Menu close={closeMenu} isOpen={isOpen}>
      {layout.map(({ name, displayName, tabs }) => (
        <NavItem
          key={`mobile-nav.nav-item.${name}`}
          currentPath={path}
          path={`/${name}`}
          onClick={selectMenuItem}
          close={closeMenu}
          items={tabs}
          selectMenuItem={selectMenuItem}
          selectedMenuItem={selectedMenuItem}
          text={displayName}
          currentTabId={currentTabId}
        />
      ))}
      <MindsphereLink />
    </Menu>
  );
};
