import * as React from 'react';
import getPath from '../../../../services/getPath';

export default class CsRouter extends React.Component<any, any> {
  UNSAFE_componentWillMount() {
    this.redirectController(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.redirectController(nextProps);
  }

  getFirstTabId(props) {
    const { tabs = [] } = props;
    const firstTab = tabs[0];
    const firstTabId = firstTab ? firstTab.id : '';

    return firstTabId;
  }

  redirectController(props) {
    const {
      initialSceneName = 'status',
      tabs = [],
      location,
      match,
      noRedirect,
      push
    } = props;
    const { pathname } = location;
    const { params } = match;

    if (pathname === '/') {
      push(initialSceneName);
      return;
    }

    if (noRedirect) return;

    let tabIsAvailable = tabs.some(tab => tab.id === params.tabId);

    if (
      // redirect to first tab if the current tab isn't available
      tabs.length > 0 &&
      !tabIsAvailable
    ) {
      this.redirectToFirstTab(props);
      return;
    }

    const firstTabId = this.getFirstTabId(props);
    if (!params.tabId && firstTabId) {
      // redirect to first tab if nothing is selected
      this.redirectToFirstTab(props);
      return;
    }
  }

  redirectToFirstTab(props) {
    const { push, location } = props;
    const path = getPath(location);
    const firstTabId = this.getFirstTabId(props);

    push(`${path}/${firstTabId}`);
  }

  render() {
    return this.props.children;
  }
}
