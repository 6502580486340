import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

//configuration
import config from '../services/core/cycles/reducer/config';
import cycle from '../services/core/cycles/reducer/cycle';

// Scenes

import gridLayout from '../scenes/Status/components/GridLayout/reducers/StatusGridLayoutReducer';
import layout from '../components/Layout/reducer';
import chart from '../components/Chart/reducer';
import reducerFactory from '../services/core/reducerFactory';

import eventScene from '../scenes/Events/reducer';
import serviceLogScene from '../scenes/ServiceLog/reducer';

let sourceReducers = {};
reducerFactory.getReducers().forEach(sourceReducer => {
  sourceReducers[sourceReducer.prefix] = sourceReducer.reducer;
});

export default history =>
  combineReducers({
    router: connectRouter(history),
    core: combineReducers({
      cycle
    }),
    layout,
    chart,
    config,
    scenes: combineReducers({
      gridLayout,
      events: eventScene,
      serviceLog: serviceLogScene
    }),
    ...sourceReducers
  });
