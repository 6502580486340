import Source from '../source';

//global types
import { SourceState } from '../../../globalTypes/state';
import {
  DashboardProperties,
  Action,
  EventAnalyticRequestParameter
} from './types';
import { GeneralRequestParameter } from '../sourceTypes';

import EventAnalytics from '../../../api/eventsAnalytics';
interface EventAnalyse {
  sum: number;
  events: Array<{ name: string; value: number }>;
}

class EventAnalyticsMSP extends Source {
  getId(dashboardProps: DashboardProperties) {
    return 'analytics';
  }
  getRequests(
    sourceState: SourceState,
    dashboardProps: Array<DashboardProperties>
  ) {
    return [
      {
        itemName: 'analytics',
        reqRunning: false,
        firstLoad: true,
        ...dashboardProps[0]
      }
    ];
  }
  async callMethodeForSaga(
    requestParams: EventAnalyticRequestParameter
  ): Promise<{ itemName: string; data: EventAnalyse }> {
    const { asset, typeId } = requestParams;
    const analytics: EventAnalyse = await EventAnalytics.getEventAnalytics(
      asset,
      typeId
    );
    return { itemName: requestParams.itemName, data: analytics };
  }
  getRequestedReducer(state: SourceState, action: Action): SourceState {
    const requests: Array<GeneralRequestParameter> = action.payload;

    let newState = state;
    // If state is empty -> fill in initial structure
    if (Object.keys(newState).length === 0) {
      requests.forEach((request: GeneralRequestParameter) => {
        newState = {
          [request.itemName]: {
            firstLoad: true,
            reqRunning: true,
            data: []
          }
        };
      });
    }
    requests.forEach((request: GeneralRequestParameter) => {
      const item = newState[request.itemName];

      newState = {
        ...newState,
        [request.itemName]: {
          ...item,
          reqRunning: true
        }
      };
    });

    return {
      ...newState
    };
  }

  getSucceededReducer(state: SourceState, action: Action) {
    const { itemName, data } = action.payload;
    const item = state[itemName];
    let newState = state;

    newState = {
      ...newState,
      [itemName]: {
        ...item,
        reqRunning: false,
        firstLoad: false,
        data: [data]
      }
    };

    return {
      ...newState
    };
  }
}

const eventsMSP = new EventAnalyticsMSP();
export default eventsMSP;
