import * as React from 'react';
import styled from 'styled-components';
import RadarChartElement from '../../../Tile/components/RadarChartElement';
import Tile from '../../../Tile';

const Content = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 20px;
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    pointer-events: none;
  }
`;

class RadarChart extends React.Component<any, any> {
  render() {
    const { config } = this.props;

    return (
      <Tile header={config.title} {...this.props}>
        <Content>
          {/* Mouse Events are disabled by the CSS on the Content div  */}
          <RadarChartElement {...this.props} />
        </Content>
      </Tile>
    );
  }
}
export default RadarChart;
