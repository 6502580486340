import * as React from 'react';
import styled from 'styled-components';

const Loading = styled.li`
  height: 80px;
  margin: 0px 20px;
  font-family: Montserrat Bold;
  font-size: 16px;

  color: #b1b9ab;
  line-height: 80px;
  text-align: center;
  padding: 0px;
`;

export default () => <Loading>Loading...</Loading>;
