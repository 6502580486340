import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import IncidentList from '../index';
import { showDetailView } from '../../IncidentDetailModal/actions';
import { getFullIncidentListSelector } from '../../../../selectors/csServices';

const mapStateToProps = (state, ownProps) => ({
  incidents: getFullIncidentListSelector(state, ownProps)
});

const mapDispatchToProps = dispatch => ({
  showDetailView: id => dispatch(showDetailView(id))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IncidentList)
);
