import { Dashboard } from '../../globalTypes/dashboard';

/**
 * API for dashboard configurations
 *
 * @export
 * @class DashboardAPI
 */
export default class DashboardAPI {
  /**
   * Returns all dashboards
   *
   * @param {string} tenant
   * @returns {array} of all dashboards
   * @memberof DashboardAPI
   */
  async getDashboards() {
    const url: string = `/app/v1/dashboards`;

    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Cache-Control": "max-age=1"
      },
      credentials: 'same-origin'
    });
    const data: Array<Dashboard> = await response.json();

    return data;
  }

  /**
   * Returns a specific dashboard by id
   *
   * @param {string} tenant
   * @param {int} dashboardId
   * @returns {object} with specifc dashboard
   * @memberof DashboardAPI
   */
  async getDashboard(dashboardId: number) {
    const url: string = `/app/v1/dashboards/${dashboardId}`;

    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    });
    const data: object = await response.json();

    return data;
  }
}
