import * as React from 'react';
import styled from 'styled-components';

const LoadingBackground = styled.div`
  position: fixed;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  overflow: auto
  align-items: center;
`;

const DotLoader = styled.div`
  @-webkit-keyframes grow {
    0%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes grow {
    0%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: relative;
  background-color: #006fe6;
`;

const DotLoaderDot1 = styled(DotLoader)`
  -webkit-animation: 1.2s grow ease-in-out infinite 0.2s;
  animation: 1.2s grow ease-in-out infinite 0.2s;
`;

const DotLoaderDot2 = styled(DotLoader)`
  -webkit-animation: 1.2s grow ease-in-out infinite 0.35555s;
  animation: 1.2s grow ease-in-out infinite 0.35555s;
  margin: 0 20px 0 20px;
`;

const DotLoaderDot3 = styled(DotLoader)`
  -webkit-animation: 1.2s grow ease-in-out infinite 0.5s;
  animation: 1.2s grow ease-in-out infinite 0.5s;
`;

export default () => (
  <LoadingBackground>
    <DotLoaderDot1 />
    <DotLoaderDot2 />
    <DotLoaderDot3 />
  </LoadingBackground>
);
