import { call, put, takeEvery, select } from 'redux-saga/effects';
import localStorage from '../../../../../services/localStorage';
import {
  setStatusGridLayoutSucceeded,
  setStatusGridLayoutFailed,
  getStatusGridLayoutSucceeded,
  getStatusGridLayoutFailed
} from '../actions';
import types from '../types';
import { selectConfig } from '../selector/';
import { isUndefined } from 'lodash';

const configSelector = state => selectConfig(state);

function* setStatusGridLayout(action) {
  try {
    const { allLayouts } = action.payload;
    const config = yield select(configSelector);
    yield call(localStorage.setStore, config.layoutVersion, allLayouts);
    yield put(setStatusGridLayoutSucceeded());
  } catch (error) {
    yield put(setStatusGridLayoutFailed(error));
  }
}

function* getStatusGridLayout() {
  try {
    const config = yield select(configSelector);

    let layout = yield call(localStorage.getStore, config.layoutVersion);

    if (isUndefined(layout)) {
      layout = config.layout;
      yield call(localStorage.setStore, config.layoutVersion, layout);
    }

    // if(`statusGridV3_${id}`!==config.layoutVersion){
    //   layout = config.layout
    //   yield call(localStorage.setStore, `statusGridV3_${id}`, layout);
    // }

    yield put(getStatusGridLayoutSucceeded(layout));
  } catch (error) {
    yield put(getStatusGridLayoutFailed(error));
  }
}

const statusLayoutGridSaga = [
  takeEvery(types.SET_STATUS_GRID_LAYOUT_REQUESTED, setStatusGridLayout),
  takeEvery(types.GET_STATUS_GRID_LAYOUT_REQUESTED, getStatusGridLayout)
];

export default statusLayoutGridSaga;
