import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tile from '../../../Tile';
import IconWithText from '../../../Tile/components/IconWithText';
import Image from '../../../Tile/components/Image';

import { isUndefined } from 'lodash';

const Content = styled.div`
  display: flex;
  flex-direction: row;

  height: 100%;
`;

const Info = styled.div`
  height: 100%;
  width: 50%;
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 50%;

  pointer-events: none;
`;

/** Page Content */
class InfoImage extends React.Component<any, any> {
  getUrl(config, data) {
    let path: string = config.data[0].properties.path || '';
    let assetId: string = config.data[0].properties.asset;
    if (!isUndefined(data) && !isUndefined(assetId)) {
      let imageName = data.replace(/ /g, '') || '';

      return `/app/v1/images/${assetId}/${path}${imageName}.jpg`;
    }
  }
  render() {
    const { config, data } = this.props;

    return (
      <Tile header={`${config.title}: ${data[0] || '-'}`} {...this.props}>
        <Content>
          <Info>
            <IconWithText
              icon={
                <FontAwesomeIcon
                  icon={[
                    config.data[1].properties.icon.split(' ')[0],
                    config.data[1].properties.icon.split(' ')[1]
                  ]}
                />
              }
              upperText={config.data[1].properties.displayName}
              lowerText={`${isUndefined(data[1]) ? '-' : data[1]}${config
                .data[1].properties.unit || ''}`}
            />
            <IconWithText
              icon={
                <FontAwesomeIcon
                  icon={[
                    config.data[2].properties.icon.split(' ')[0],
                    config.data[2].properties.icon.split(' ')[1]
                  ]}
                />
              }
              upperText={config.data[2].properties.displayName}
              lowerText={`${config.data[2].properties.unit || ''}`}
            />
          </Info>

          <ImageContainer>
            <Image src={this.getUrl(config, data[0])} alt={'Type'} />
          </ImageContainer>
        </Content>
      </Tile>
    );
  }
}

export default InfoImage;
