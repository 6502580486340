import * as React from 'react';
import styled from 'styled-components';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import Select from '../../Select';

library.add(faPlus);

const BetterFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  height: 20px !important;

  cursor: pointer;
`;

const DetailModal = styled(Modal)`
  max-width: 700px;
`;

const Header = styled(ModalHeader)`
  background-color: ${props => props.theme.background};
  font-family: Montserrat Bold;
  border: none;
`;

const Body = styled(ModalBody)`
  padding: 0px 30px;
`;

const Footer = styled(ModalFooter)`
  padding: 20px 30px;
`;

const Group = styled.div`
  padding: 20px 0px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e9e9ec;

  &&:last-child {
    border-bottom: none;
  }
`;

const GroupRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Heading = styled.p`
  font-family: Montserrat Bold;
`;

const ServicecaseSelectDiv = styled.div`
  width: 300px;
  margin-right: 20px;
`;

const NewServicecaseButton = styled(Button)`
  height: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.main};
  color: ${props => props.theme.mainLight};
  background-color: ${props => props.theme.main};
  margin-bottom: -10px;
  margin-left: auto;

  &:hover {
    background-color: ${props => props.theme.mainLight};
    color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }
`;

const AddButton = styled(Button)`
  height: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.main};
  color: ${props => props.theme.mainLight};
  background-color: ${props => props.theme.main};

  &:hover {
    background-color: ${props => props.theme.mainLight};
    color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }
`;

const CancelButton = styled(Button)`
  height: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.main};
  background-color: ${props => props.theme.mainLight};
  color: ${props => props.theme.main};
  margin-left: auto;
  margin-right: 20px;

  &:hover {
    color: ${props => props.theme.mainLight};
    background-color: ${props => props.theme.main};
    border-color: ${props => props.theme.main};
  }
`;

const makeServicecaseSelectOptions = (servicecases = []) => {
  return servicecases.map(({ title, id }) => {
    return { value: id, label: title };
  });
};

const getSelectedOption = (options, currentValue) => {
  return options.find(({ value }) => value === currentValue);
};

export default ({
  isOpen,
  close,
  edit,
  save,
  openServicecaseModal,
  id,
  description,
  servicecases,
  servicecaseId,
  solutionId,
  solutionTitle,
  solutionDescription
}) => {
  const options = makeServicecaseSelectOptions(servicecases);
  return (
    <DetailModal isOpen={isOpen} toggle={close} className={'detail-modal'}>
      <Header toggle={close}>Vorfall</Header>
      <Body>
        <Group>
          <Heading>Typ</Heading>
          <GroupRow>
            <ServicecaseSelectDiv>
              {true && (
                //@ts-ignore
                <Select
                  options={options}
                  placeholder={'Wähle ein Typen'}
                  onChange={option => edit('servicecaseId', option.value)}
                  value={getSelectedOption(options, servicecaseId)}
                />
              )}
            </ServicecaseSelectDiv>
            <NewServicecaseButton onClick={openServicecaseModal}>
              <BetterFontAwesomeIcon icon={['far', 'plus']} size="lg" />
            </NewServicecaseButton>
          </GroupRow>
        </Group>
        <Group>
          <Heading>Beschreibung</Heading>
          <Input
            type="textarea"
            value={description}
            onChange={e => edit('description', e.target.value)}
          ></Input>
        </Group>
        <Group>
          <Heading>Lösungstitel</Heading>
          <Input
            value={solutionTitle}
            onChange={e => edit('solutionTitle', e.target.value)}
          ></Input>
        </Group>
        <Group>
          <Heading>Lösungsbeschreibung</Heading>
          <Input
            type="textarea"
            value={solutionDescription}
            onChange={e => edit('solutionDescription', e.target.value)}
          ></Input>
        </Group>
      </Body>
      <Footer>
        <CancelButton onClick={close}>Abbrechen</CancelButton>
        <AddButton
          onClick={() =>
            save({
              id,
              description,
              servicecaseId,
              solutionId,
              solutionTitle,
              solutionDescription
            })
          }
        >
          Speichern
        </AddButton>
      </Footer>
    </DetailModal>
  );
};
