import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'reactstrap';

const GreenButton = styled(Button)`
  height: 34px;
  color: ${props => props.theme.main};
  border-color: ${props => props.theme.main};
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: ${props => props.theme.mainLight};
  font-size: 12px;
  font-family: Montserrat Bold;
  position: relative;

  &:hover {
    color: #ffffff;
    background-color: ${props => props.theme.main};
  }

  ${({ width }) => `
  width: ${width}px;
  `}
`;

export default ({ onClick, width = 100 }) => {
  return (
    <GreenButton width={width} onClick={onClick}>
      ACK
    </GreenButton>
  );
};
