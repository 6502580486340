
import * as React from 'react';
import styled from 'styled-components';

const LabeledDot = styled.div`
  display: flex;
  flex-direction: row;
`;

const Dot = styled.div`
  height: 18px;
  width: 18px;
  margin: 5px;
  border-radius: 100%;

  ${({ color }) =>
    color &&
    `
    background-color: ${color};
  `}
`;

const Label = styled.p`
  margin-left: 15px;
`;

export default ({ color, label }) => (
  <LabeledDot>
    {color && <Dot color={color} />} <Label>{label}</Label>
  </LabeledDot>
);
