import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';

import Router from '../index';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => ({
  push: link => dispatch(push(link))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Router)
);
