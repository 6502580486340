import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import defaultTheme from '../../services/themes/default';

const mapStateToProps = (state, ownProps) => {
  const configs = state.config;
  const activeDashboard = state.core.cycle.tab;

  let config = configs[0] || { theme: {} };

  if (configs.length !== 0) {
    config =
      configs.find(_config => {
        return _config.id === activeDashboard;
      }) || config;
  }

  return {
    theme: {
      ...defaultTheme,
      ...config.theme
    }
  };
};
const mapDispatchToProps = dispatch => ({});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ThemeProvider)
);
