import * as React from 'react';
import styled from 'styled-components';
// import './style.css';

const CheckboxDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyleDiv = styled.div`
  .hidden-box {
    position: absolute;
    top: -9999px;
    left: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    opacity: 0;
  }
  .check--label {
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .check--label-box {
    display: flex;
    align-self: center;
    position: relative;
    height: 15px;
    width: 15px;
    border: 2px solid ${props => props.theme.main};
    border-radius: 2px;
    cursor: pointer;
  }
  .check--label-text {
    width: calc(100% - 45px);
    display: flex;
    align-self: center;
    position: relative;
    cursor: pointer;
    padding: 8px 20px;
    border-left: 1px solid #ecf0f1;
    white-space: nowrap;
    overflow: hidden;
  }
  .hidden-box:checked + .check--label {
    background-color: #f9f9f9;
  }
  .hidden-box:checked + .check--label .check--label-box {
    background-color: ${props => props.theme.main};
  }
  .hidden-box:checked + .check--label .check--label-box:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 3px;
    width: 5px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .hidden-box:checked + .check--label .check--label-text:after {
    width: 85%;
  }
`;

export default ({ onChange = () => {}, checked, id }) => (
  <StyleDiv>
    <CheckboxDiv className="Action-Checkbox">
      <input
        type="checkbox"
        className="hidden-box"
        id={id}
        checked={checked}
        onChange={() => {}}
      />
      <label
        htmlFor={id}
        className="check--label"
        onClick={e => {
          e.preventDefault();
          onChange();
        }}
      >
        <span className="check--label-box" />
      </label>
    </CheckboxDiv>
  </StyleDiv>
);
