export const GET_REQUESTED = 'GET_*_REQUESTED';
export const GET_SUCCEEDED = 'GET_*_SUCCEEDED';
export const GET_FAILED = 'GET_*_FAILED';

export const UPDATE_REQUESTED = 'UPDATE_*_REQUESTED';
export const UPDATE_SUCCEEDED = 'UPDATE_*_SUCCEEDED';
export const UPDATE_FAILED = 'UPDATE_*_FAILED';

export const CREATE_REQUESTED = 'CREATE_*_REQUESTED';
export const CREATE_SUCCEEDED = 'CREATE_*_SUCCEEDED';
export const CREATE_FAILED = 'CREATE_*_FAILED';

export const DELETE_REQUESTED = 'DELETE_*_REQUESTED';
export const DELETE_SUCCEEDED = 'DELETE_*_SUCCEEDED';
export const DELETE_FAILED = 'DELETE_*_FAILED';

export default {
  GET_REQUESTED,
  GET_SUCCEEDED,
  GET_FAILED,
  UPDATE_REQUESTED,
  UPDATE_SUCCEEDED,
  UPDATE_FAILED,
  CREATE_REQUESTED,
  CREATE_SUCCEEDED,
  CREATE_FAILED,
  DELETE_REQUESTED,
  DELETE_SUCCEEDED,
  DELETE_FAILED
};
