import * as React from 'react';
import styled from 'styled-components';

import Header from './components/Header';

const Tile = styled.div<any>`
  height: 100%;
  padding 30px;

  @media (max-width: 600px) {
    height: ${props => {
      if (
        // @ts-ignore
        typeof props.gridWidth === 'undefined' ||
        // @ts-ignore
        typeof props.gridHeight === 'undefined'
      )
        throw new Error('Tile size not supplied');

      // @ts-ignore
      switch (props.gridHeight) {
        case 1:
          return '240px';
        case 2:
          return '520px';
        case 3:
          return '800px';
        case 4:
          return '1080x';
        case 8:
          return '2200';
        default:
          return '240px';
      }
    }}
    width: ${props => {
      // @ts-ignore
      switch (props.gridWidth) {
        case 1:
          return '250px';
        case 2:
          return '540px';
        case 3:
          return '830px';
        case 4:
          return '1120px';
        default:
          return '250px';
      }
    }};
    -moz-transform: scale(0.555);
    -moz-transform-origin: top left;
    -o-transform: scale(0.555);
    -o-transform-origin: top left;
    -webkit-transform: scale(0.555);
    -webkit-transform-origin: top left;
  }

  ${props =>
    //@ts-ignore
    props.forceHeight &&
    `
      height: ${props.forceHeight};
  `}
`;
// 540px - 250px;

const Body = styled.div`
  height: calc(100% - 36.6px);
`;

export default props => {
  const { header = '', subTitle = '', action, children } = props;
  return (
    <Tile className="Tile" {...props}>
      <Header text={header} subTitle={subTitle} action={action} />
      <Body>{children}</Body>
    </Tile>
  );
};
