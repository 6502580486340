import * as React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrows } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faBars, faArrows);

const MobileNav = styled.div`
  // position: fixed;

  height: 42px;
  width: 100%;
  //bottom: calc(100vh - 42px);

  line-height: 42px;
  text-align: center;

  border-bottom-style: solid;
  border-bottom-color: #e4e8f0;
  border-bottom-width: 1px;

  font-size: 20px;
  font-family: Montserrat Bold;
  background-color: #ffffff;

  z-index: 1050;

  @media (min-width: 991.92px) {
    display: none;
  }
`;

const HamburgerIcon = styled.div`
  position: absolute;
  left: 20px;
  font-size: 18px;
`;

const DragAndDropIcon = styled.div<any>`
  position: absolute;
  right: 20px;
  font-size: 18px;
  color: ${props => props.theme.conterLight};

  ${props =>
    //@ts-ignore
    props.active &&
    `
      color: ${props.theme.main};
  `}
`;

/**
 * The nav bar on mobile
 * @param  {string} props.name name of scene (to display on the mobile nav)
 * @param  {boolean} props.dragAndDropEnabled enables drag and drop (for icon color)
 * @param  {void} prop.toggleDragAndDrop funtion to toggle drag an drop
 * @param  {void} prop.openMenu funtion open the menu
 */
export default props => (
  <MobileNav className="mobile-nav">
    <HamburgerIcon onClick={() => props.openMenu()}>
      <FontAwesomeIcon icon={['far', 'bars']} />
    </HamburgerIcon>
    {props.showDragAndDrop && (
      <DragAndDropIcon
        onClick={() => props.toggleDragAndDrop()}
        active={props.dragAndDropEnabled}
      >
        <FontAwesomeIcon icon={['far', 'arrows']} />
      </DragAndDropIcon>
    )}
    {props.name}
  </MobileNav>
);
