import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Administration from '../index';
import { requestDeleteItem } from '../../DeleteModal/actions';
import { getServicecaseSelector } from '../../../../selectors/csServices';
import { openServicecaseModal } from '../../ServicecaseModal/actions';

const mapStateToProps = (state, ownProps) => ({
  servicecases: getServicecaseSelector(state, ownProps)
});

const mapDispatchToProps = dispatch => ({
  updateServicecase: servicecase => {
    dispatch(openServicecaseModal(servicecase));
  },
  deleteServicecase: id =>
    dispatch(
      requestDeleteItem({
        itemName: 'Servicecases',
        displayItemName: 'Typ',
        id
      })
    )
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Administration)
);
