
import * as React from 'react';
import styled from 'styled-components';

const Description = styled.div`
  width: calc(100% - 800px);
  min-width: 250px;
  display: flex;
  padding: 0px 10px;

  .multiline {
    justify-content: center;

    height: 48px;
    width: 100%;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: Montserrat Bold;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 991.92px) {
    width: 100%;
  }
`;

export default ({ children }) => <Description>{children}</Description>;
