import * as React from 'react';
import styled from 'styled-components';

const LabeledDot = styled.div`
  display: flex;
  flex-direction: row;
`;

const Dot = styled.div`
  height: 24px;
  width: 24px;
  border-width: 6px;
  border-style: solid;
  border-radius: 100%;
  border-color: rgba(255, 255, 255, 0.9);
  margin-right: 10px;

  background-color: ${props => props.theme.main};

  ${({ color }) =>
    color &&
    `
  background-color: ${color};
`}
`;

export default ({ color = '#1da6c9', label }) => (
  <LabeledDot>
    <Dot color={color} /> {label}
  </LabeledDot>
);
