import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NewIncidentModal from '../index';
import { closeIncidentModal, editIncident } from '../actions';
import { openServicecaseModal } from '../../ServicecaseModal/actions';

import { getServicecaseSelector } from '../../../../selectors/csServices';
import { makeCreateRequestAction } from '../../../../services/core/reducerFactory/actions';

const saveAction = makeCreateRequestAction('csServices');

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.scenes.serviceLog.incidentModal.isOpen,
  servicecases: getServicecaseSelector(state, ownProps),
  description: state.scenes.serviceLog.incidentModal.description,
  servicecaseId: state.scenes.serviceLog.incidentModal.servicecaseId,
  solutionTitle: state.scenes.serviceLog.incidentModal.solutionTitle,
  solutionDescription: state.scenes.serviceLog.incidentModal.solutionDescription
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeIncidentModal()),
  openServicecaseModal: () => dispatch(openServicecaseModal()),
  edit: (scope, value) => dispatch(editIncident({ scope, value })),
  save: data => dispatch(saveAction({ itemName: 'Incidents', data }))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewIncidentModal)
);
