import * as React from 'react';

import Layout from '../../components/Layout/containers/LayoutContainer';
import Eventlist from './components/Eventlist/container';
import Actionbar from './components/Actionbar/container';
import Pagination from './components/Pagination/container';

/** Page Content */
const EventScene = props => {
  return (
    <Layout
      name={'Events'}
      showHorizontalMenu
      layout={this.props.layout}
      {...props}
    >
      <Actionbar {...props} />
      <Eventlist {...props} />
      <Pagination {...props} />
    </Layout>
  );
};

// @ts-ignore
export default EventScene;
