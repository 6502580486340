import * as React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  background-color: ${props => props.theme.background}
  width: 100%;
  height: 100%;
  margin-top: 15px;
  padding: 20px
`;

const Column = styled.div<any>`
  padding-right: 10px;

  ${({ pushToRight }) => pushToRight && `margin-left: auto;`}
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Header = styled.p`
  width: 120%;

  font-family: Montserrat Bold;
  font-size: 16px;
  line-height: 20px;
  color: #2e3940;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 18px;
  }

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const SubHeader = styled.p`
  width: 100%;

  font-family: Montserrat;
  font-size: 16px;
  line-height: 20px;
  color: #2e3940;

  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 22px;
  }
`;
const Time = styled.p`
  width: 100%;
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.conterLight};

  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 22px;
  }
`;
const Text = styled.p`

  width: 100%;
  float: right
  font-family: Montserrat;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.conterLight};


  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 22px;
  }
`;

const Dot = styled.div`
  height: 15px;
  width: 15px;
  margin: 4px 5px 6px 5px;
  border-radius: 100%;

  ${({ color }) =>
    color &&
    `
    background-color: ${color};
  `}
`;

export default ({ event }) => {
  return (
    <Container>
      <Row>
        <Column>
          <Header>{event.header}</Header>
        </Column>
        <Column>
          <SubHeader>{event.subHeader}</SubHeader>
        </Column>
        <Column pushToRight>
          <Dot color={event.dotColor} />
        </Column>
        <Column pushToRight>
          <Time>{event.time}</Time>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text>{event.text}</Text>
        </Column>
      </Row>
    </Container>
  );
};
