import * as React from 'react';
import styled from 'styled-components';
import { Nav } from 'reactstrap';
import NavItem from './components/NavItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faCalendarAlt,
  faListAlt
} from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import getPath from '../../../../services/getPath';

library.add(faChartBar, faCalendarAlt, faListAlt);

const StyledNav = styled(Nav)`
  position: fixed;

  order: 1;
  width: 100px;
  height: 100%;
  min-height: 100%;
  background-color: #ffffff;
  box-shadow: 1px 0px 4px ${props => props.theme.shadow};
  z-index: 1030;

  @media (max-width: 991.92px) {
    display: none;
  }
`;

const ICON_ENUM = {
  status: ['far', 'chart-bar'],
  serviceLogs: ['far', 'list-alt'],
  events: ['far', 'calendar-alt']
};

/**
 * Vertical Navbar for desktop view
 * @param  {Object} props.location location from router
 */
const VerticalNav = props => {
  const path = getPath(props.location);
  return (
    <StyledNav vertical className="side-nav">
      {props.layout.map(scene => (
        <NavItem
          key={`vertical-nav.nav-item.${scene.name}`}
          currentPath={path}
          path={`/${scene.name}`}
          name={`${scene.displayName}`}
          icon={<FontAwesomeIcon icon={ICON_ENUM[scene.name]} />}
          onClick={() => props.push('/status')}
        />
      ))}
    </StyledNav>
  );
};

export default VerticalNav;
