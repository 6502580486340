import * as React from 'react';
import styled from 'styled-components';
import LineChartElement from '../../../Tile/components/LineChartElement';
import Tile from '../../../Tile';

const Content = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 20px;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

class LineChart extends React.Component<any, any> {
  render() {
    const { config } = this.props;

    return (
      <Tile header={config.title} {...this.props}>
        <Content>
          {/* Mouse Events are disabled by the CSS on the Content div  */}
          <LineChartElement {...this.props} />
        </Content>
      </Tile>
    );
  }
}
export default LineChart;
