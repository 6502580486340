import ApiClient from '../ApiClient';
import { Servicecase } from '../../../dataSources/sources/csServices/types';

export default class Servicecases extends ApiClient {
  static async list() {
    return await this.get('servicecases');
  }

  static async create(servicecase: Servicecase) {
    //@ts-ignore
    return await this.post('servicecases', servicecase);
  }

  static async update(servicecase: Servicecase) {
    //@ts-ignore
    return await this.put('servicecases', servicecase.id, servicecase);
  }

  static async delete(id) {
    try {
      return await this.del('servicecases', id);
    } catch (e) {
      throw new Error('Typ konnte nicht gelöscht werden.');
    }
  }
}
