import * as React from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatToDispalyDate } from '../../../services/utils';

const DetailModal = styled(Modal)`
  max-width: 700px;
`;

const Header = styled(ModalHeader)`
  background-color: ${props => props.theme.background};
  font-family: Montserrat Bold;
  border: none;
`;

const Body = styled(ModalBody)`
  padding: 0px 30px;
`;

const Group = styled.div`
  padding: 20px 0px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e9e9ec;

  &&:last-child {
    border-bottom: none;
  }
`;

const GroupRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Heading = styled.p`
  font-family: Montserrat Bold;
`;

const InfoField = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  margin-left: 30px;
  padding-right: 30px;

  @media (max-width: 991.92px) {
    margin-left: 10px;
    padding-right: 10px;
  }

  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e9e9ec;

  &&:first-child {
    margin-left: 0px;
  }
  &&:last-child {
    border-right: none;
    padding-left: 0px;
  }
`;

const InfoFieldName = styled.p`
  color: ${props => props.theme.conterLight};
  font-family: Montserrat Medium;
  margin-bottom: 4px;
`;

const InfoFieldText = styled.p`
  color: #2e3940;
  font-family: Montserrat Medium;
  margin-bottom: 0px;
`;

const DescriptionText = styled.p`
  margin-bottom: 0px;
  color: ${props => props.theme.conterLight};
`;

const SolutionDiv = styled.div`
  background-color: ${props => props.theme.background};
  padding: 15px;
  margin-bottom: 20px;
`;

const SolutionTitle = styled.p`
  font-family: Montserrat Medium;
  margin-bottom: 0px;
`;

const SolutionText = styled.p`
  margin-bottom: 0px;
  color: ${props => props.theme.conterLight};
`;

const BetterFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  height: 20px !important;

  cursor: pointer;
`;

const renderInfoField = (name, text) => (
  <InfoField>
    <InfoFieldName>{name}:</InfoFieldName>
    <InfoFieldText>{text}</InfoFieldText>
  </InfoField>
);

const renderSolution = (id, title, text) => (
  <SolutionDiv key={id}>
    <SolutionTitle>{title}</SolutionTitle>
    <SolutionText>{text}</SolutionText>
  </SolutionDiv>
);

export default ({
  close,
  id,
  incident = {},
  deleteIncident,
  updateIncident
}) => {
  const {
    //@ts-ignore
    servicecase = {},
    //@ts-ignore
    lastSolutions = [],
    //@ts-ignore
    solution = {},
    //@ts-ignore
    description,
    //@ts-ignore
    reporter,
    //@ts-ignore
    createdAt
  } = incident;

  const last3Solutions = lastSolutions.slice(0, 3);

  return (
    <DetailModal isOpen={id !== null} toggle={close} className={'detail-modal'}>
      <Header toggle={close}>{servicecase.title}</Header>
      <Body>
        <Group>
          <Heading>
            Übersicht
            <span style={{ paddingRight: '40px' }}></span>
            {true && (
              //@ts-ignore
              <BetterFontAwesomeIcon
                icon={['far', 'pen']}
                size="lg"
                color="#0076cf"
                onClick={() =>
                  updateIncident({
                    //@ts-ignore
                    id: incident.id,
                    //@ts-ignore
                    title: incident.title,
                    //@ts-ignore
                    description: incident.description,
                    solutionTitle: solution.title || '',
                    solutionDescription: solution.description || '',
                    servicecaseId: servicecase.id
                  })
                }
              />
            )}
            <span style={{ paddingRight: '20px' }}></span>
            {true && (
              //@ts-ignore
              <BetterFontAwesomeIcon
                icon={['far', 'trash']}
                size="lg"
                color="#ff4318"
                //@ts-ignore
                onClick={() => deleteIncident(incident.id)}
              />
            )}
          </Heading>
          <GroupRow>
            {renderInfoField('Typ', servicecase.servicecaseType)}
            {renderInfoField('Ersteller', reporter)}
            {renderInfoField('Erstellt am', formatToDispalyDate(createdAt))}
          </GroupRow>
        </Group>
        <Group>
          <Heading>Beschreibung</Heading>
          <DescriptionText>{description}</DescriptionText>
        </Group>
        {solution.title && (
          <Group>
            <Heading>Lösung</Heading>
            {renderSolution(solution.id, solution.title, solution.description)}
          </Group>
        )}
        {last3Solutions.length > 0 && (
          <Group>
            <Heading>Letzte Lösungen</Heading>
            {last3Solutions.map(solution =>
              renderSolution(solution.id, solution.title, solution.description)
            )}
          </Group>
        )}
      </Body>
    </DetailModal>
  );
};
