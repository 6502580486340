import { all } from 'redux-saga/effects';

import cyclesSaga from '../services/core/cycles/sagas';

import dataSourceSaga from '../services/core/reducerFactory/sagas';

import statusLayoutGridSaga from '../scenes/Status/components/GridLayout/sagas';

function* mySaga() {
  yield all([...cyclesSaga, ...dataSourceSaga, ...statusLayoutGridSaga]);
}

export default mySaga;
